import React, { useState } from "react";
import { domain } from "../../constants";
import { useAuth, useLoading } from "../../Contexts";
import { CHANGE_ADMIN_PASSWORD, NEW, OLD, PASSWORD, SAVE } from "../../language";
import Accordion from "../../partials/Accordion";
import Button from "../../partials/Button";
import TextInput from "../../partials/TextInput";
import { patchRequest } from "../../utils/http";

export default function Account() {
  // eslint-disable-next-line
  const [oldPassword, setOldPassword] = useState("");
  // eslint-disable-next-line
  const [newPassowrd, setNewPassowrd] = useState("");

  const { token } = useAuth();
  const { setLoading } = useLoading();

  const handleSubmit = (e) => {
    e.preventDefault();

    patchRequest(
      domain + "users/change_password/",
      { old_password: oldPassword, new_password: newPassowrd },
      setLoading,
      token
    )
  };

  return (
    <div className="account  animate__animated animate__fadeIn">
      <Accordion title={CHANGE_ADMIN_PASSWORD} pinned={true}>
        <form onSubmit={handleSubmit} className="form">
          <TextInput
            name={OLD + ' ' + PASSWORD}
            placeholder={OLD + ' ' + PASSWORD}
            type="password"
            setContextValue={(value) => setOldPassword(value)}
          />
          <TextInput
            name={NEW + ' ' + PASSWORD}
            type="password"
            placeholder={NEW + ' ' + PASSWORD}
            setContextValue={(value) => setNewPassowrd(value)}
          />
          <Button type="submit">{SAVE}</Button>
        </form>
      </Accordion>
    </div>
  );
}
