import {
  DISABLED,
  EMAIL_CONFIG,
  EMAIL_HOST,
  EMAIL_PASSOWRD,
  EMAIL_PORT,
  EMAIL_TLS,
  EMAIL_USERNAME,
  ENABLED,
} from "language";
import Accordion from "partials/Accordion";
import Button from "partials/Button";
import TextInput from "partials/TextInput";
import React from "react";
import { useEmailConfig } from "../context";

export default function EmailConfig({editMode}) {
  const {
    emailUser,
    emailHost,
    emailPassword,
    emailPort,
    emailTls,
    setEmailTls,
    setEmailUser,
    setEmailPort,
    setEmailPassword,
    setEmailHost,
  } = useEmailConfig();
  return (
    <Accordion title={EMAIL_CONFIG} opened={true}>
      <div className="__form">
        <TextInput
          name={EMAIL_HOST}
          setContextValue={setEmailHost}
          initialValue={emailHost}
          disabled={!editMode}
          type="text"
        />
        <TextInput
          name={EMAIL_PORT}
          setContextValue={setEmailPort}
          initialValue={emailPort}
          disabled={!editMode}
          step={1}
          type="number"
        />

        <TextInput
          name={EMAIL_USERNAME}
          setContextValue={setEmailUser}
          initialValue={emailUser}
          disabled={!editMode}
          type="text"
        />
        <TextInput
          name={EMAIL_PASSOWRD}
          setContextValue={setEmailPassword}
          initialValue={emailPassword}
          disabled={!editMode}
          type="text"
        />
        <label>{EMAIL_TLS}</label>
        <Button disabled={!editMode} onClick={() => setEmailTls(!emailTls)}>
          {emailTls ? ENABLED : DISABLED}
        </Button>
      </div>
    </Accordion>
  );
}
