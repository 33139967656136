import React, { useState } from "react";
import {
  ADD,
  NOT_REQUIRED,
  PASSCODE,
  TABLE,
  TABLES,
  TABLE_NAME,
  TABLE_NUMBER,
} from "../../../language";
import Accordion from "../../../partials/Accordion";
import Button from "../../../partials/Button";
import TextInput from "../../../partials/TextInput";
import { useTables } from "../Context";
import DineTable from "./DineTable";

export default function DineTables() {
  const { dineTables, addTable } = useTables();

  const [tableNumber, setTableNumber] = useState("");
  const [tableName, setTableName] = useState("");
  const [passcode, setPasscode] = useState("");

  const onAdd = (e) => {
    e.preventDefault();
    addTable({
      name: tableName,
      number: tableNumber,
      passcode,
    });
    setTableNumber("");
    setTableName("");
  };

  return (
    <div className="mgb-1">
      <Accordion title={TABLES} opened={true}>
        <div className="__tables">
          <form onSubmit={onAdd} className="__add">
            <TextInput
              setContextValue={(value) => setTableNumber(value)}
              initialValue={tableNumber}
              name={TABLE_NUMBER}
              placeholder={TABLE_NUMBER}
            />
            <TextInput
              setContextValue={(value) => setTableName(value)}
              initialValue={tableName}
              name={TABLE_NAME}
              placeholder={TABLE_NAME}
            />
            <TextInput
              required={false}
              setContextValue={(value) => setPasscode(value)}
              initialValue={passcode}
              name={PASSCODE + ' ' +  NOT_REQUIRED}
              placeholder={PASSCODE + ' ' +  NOT_REQUIRED}
            />
            <Button>{ADD + " " + TABLE}</Button>
          </form>

          <ul>
            {dineTables.map((table) => (
              <DineTable key={table.id} table={table} />
            ))}
          </ul>
        </div>
      </Accordion>
    </div>
  );
}
