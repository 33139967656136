import { DatePicker } from "antd";
import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import {
  ADD,
  ALL,
  CANCEL,
  CATEGORY,
  DESCRIPTION,
  FIXED,
  IMAGE,
  MEAL,
  ON,
  OR_CHOOSE_A_RANGE,
  PERCENTAGE,
  SAVE,
  SIZE,
  TITLE,
  TYPE,
  VALUE,
  WEEKDAY,
  WEEKDAYS_EXTENDED,
  WEEKDAYS_EXTENDED_2,
  WEEKDAYS_EXTENDED_NAMES,
} from "../../../../language";
import Button from "../../../../partials/Button";
import ImageInput from "../../../../partials/ImageInput";
import SelectInput from "../../../../partials/SelectInput";
import Textarea from "../../../../partials/Textarea";
import TextInput from "../../../../partials/TextInput";
import { useController } from "../../Controller";
import moment from "moment";
import {
  addDailyOffer,
  updateDailyOffer,
} from "../../store/slices/DailyOfferSlice";

const NewDailyOffer = React.memo(function ({
  setEditing = () => {},
  update = false,
  offer = {},
}) {
  const [weekday, setWeekday] = useState(offer.weekday || 1);
  const [type, setType] = useState(offer.type || 1);
  const [value, setValue] = useState(offer.value || 0);
  const [description, setDescription] = useState(offer.description || "");
  const [title, setTitle] = useState(offer.title || "");
  const [image, setImage] = useState(offer.image || "");
  const [start, setStart] = useState(offer.start ? moment(offer.start) : null);
  const [end, setEnd] = useState(offer.end ? moment(offer.end) : null);

  const { RangePicker } = DatePicker;
  const {
    categories,
    getCategoryMeals,
    getMeal,
    getSize,
    getCategorySizesNames,
    getMealSizes,
    getCategory,
    getCategorySizesIds,
    areSizesForOneMeal,
  } = useController();

  const [category, setCategory] = useState({});

  const [meal, setMeal] = useState({});
  const [sizes, setSizes] = useState(null);
  const [mealOrSize, setMealOrSize] = useState("");
  const [adding, setAdding] = useState(update);
  const dispatch = useDispatch();

  useEffect(() => {
    if (update && offer) {
      setCategory(() => {
        if (offer.meal_category) return getCategory(+offer.meal_category);
        if (offer.meal) {
          return getCategory(+getMeal(+offer.meal).category);
        }
        return getCategory(
          +getMeal(+getSize(+offer.meal_sizes[0])?.meal).category
        );
      });
      setMeal(() => {
        if (offer.meal) {
          return getMeal(+offer.meal);
        } else if (
          offer.meal_sizes &&
          offer.meal_sizes.length &&
          areSizesForOneMeal(offer.meal_sizes)
        ) {
          return getMeal(+getSize(+offer.meal_sizes[0]).meal);
        }
        return {};
      });
      setSizes(() => {
        if (offer.meal_sizes && offer.meal_sizes.length) {
          const _size = getSize(+offer.meal_sizes[0]);
          if (areSizesForOneMeal(offer.meal_sizes || [])) return _size;
          else {
            return _size.name;
          }
        }
        return ALL;
      });
    }

    // eslint-disable-next-line
  }, [getCategory, getMeal, getSize]);

  useEffect(() => {
    if (offer.meal) {
      setMealOrSize(MEAL);
    } else if (offer.meal_sizes && offer.meal_sizes.length) {
      setMealOrSize(SIZE);
    } else {
      setMealOrSize(ALL);
    }
    // eslint-disable-next-line
  }, []);

  const handleAdd = (e) => {
    e.preventDefault();

    if (!meal.id && !category.id && !sizes) {
      return;
    }

    const dailyOfferBody = {
      weekday,
      type,
      value,
      description,
      title,
      image,
      start: start ? start.toISOString() : null,
      end: end ? end.toISOString() : null,
    };
    if (sizes && sizes !== ALL) {
      if (meal.id) {
        dailyOfferBody.meal_sizes = [+sizes];
        dailyOfferBody.meal_category = null;
        dailyOfferBody.meal = null;
      } else {
        dailyOfferBody.meal_sizes = getCategorySizesIds(sizes, category.id);
        dailyOfferBody.meal_category = null;
        dailyOfferBody.meal = null;
      }
    } else if (meal.id) {
      dailyOfferBody.meal_category = null;
      dailyOfferBody.meal = meal.id;
      delete dailyOfferBody["meal_sizes"];
    } else {
      dailyOfferBody.meal_category = category.id;
      delete dailyOfferBody["meal_sizes"];
      dailyOfferBody.meal = null;
    }
    !update
      ? dispatch(
          addDailyOffer({
            dailyOffer: dailyOfferBody,
          })
        )
      : dispatch(
          updateDailyOffer({
            id: offer.id,
            ...dailyOfferBody,
          })
        );

    setAdding(false);
    setEditing(false);
  };
  const handleCancle = () => {
    setEditing(false);
    setAdding(false);
  };

  return (
    <>
      {adding && (
        <form onSubmit={handleAdd} className="__new form">
          <fieldset>
            <SelectInput
              name={WEEKDAY}
              options={WEEKDAYS_EXTENDED_NAMES}
              setContextValue={(value) =>
                setWeekday(WEEKDAYS_EXTENDED_2[value])
              }
              initialValue={WEEKDAYS_EXTENDED[weekday]}
              required={true}
              disabled={start && end}
            />

            <label className="__range-label" htmlFor="range">
              {OR_CHOOSE_A_RANGE}
            </label>

            <RangePicker
              className="__range"
              showTime
              name={"range"}
              value={[start, end]}
              format="YYYY-MM-DD HH:mm"
              onChange={(value) => {
                if (value) {
                  setStart(value[0]);
                  setEnd(value[1]);
                } else {
                  setStart(null);
                  setEnd(null);
                }
              }}
            />
            <SelectInput
              name={TYPE}
              options={[FIXED, PERCENTAGE]}
              setContextValue={(value) => {
                setType(value === FIXED ? 1 : 2);
              }}
              initialValue={type === 1 ? FIXED : PERCENTAGE}
              required={true}
            />
            <SelectInput
              name={CATEGORY}
              options={categories}
              setContextValue={(value) => {
                setCategory(getCategory(+value));
              }}
              initialValue={category}
              required={true}
            />
            <SelectInput
              name={ON}
              options={[ALL, MEAL, SIZE]}
              setContextValue={(value) => {
                setMealOrSize(value);
                setMeal({});
                setSizes("");
              }}
              initialValue={mealOrSize}
              required={true}
            />
            {mealOrSize === MEAL && (
              <SelectInput
                name={MEAL}
                options={getCategoryMeals(category.id)}
                setContextValue={(value) => {
                  setMeal(getMeal(+value));
                }}
                initialValue={meal.id ? meal : null}
                required={true}
              />
            )}
            {mealOrSize !== ALL && (
              <SelectInput
                name={SIZE}
                options={
                  meal.id
                    ? [ALL, ...getMealSizes(meal.id)]
                    : [ALL, ...getCategorySizesNames(category.id)]
                }
                setContextValue={(value) => {
                  setSizes(value);
                }}
                initialValue={sizes ? sizes : null}
                required={true}
              />
            )}

            <TextInput
              name={VALUE}
              type="number"
              placeholder={VALUE}
              setContextValue={(value) => {
                setValue(+value);
              }}
              initialValue={value}
              required={true}
            />
            <Textarea
              name={TITLE}
              initialValue={title}
              setContextValue={(value) => {
                setTitle(value);
              }}
              required={true}
            ></Textarea>
            <Textarea
              name={DESCRIPTION}
              initialValue={description}
              setContextValue={(value) => {
                setDescription(value);
              }}
              required={true}
            ></Textarea>
            <ImageInput
              title={IMAGE}
              setContextValue={(value) => {
                setImage(value);
              }}
              initial={[image]}
              multiple={false}
              required={true}
            />

            <div className="__btns">
              <Button type="submit">{SAVE}</Button>
              <Button onClick={handleCancle}>{CANCEL}</Button>
            </div>
          </fieldset>
        </form>
      )}
      {!update && (
        <Button onClick={() => setAdding(true)} disabled={adding}>
          {ADD}
        </Button>
      )}
    </>
  );
});
export default NewDailyOffer;
