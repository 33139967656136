import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

export const orderTotalOfferSlice = createSlice({
  name: "orderTotalOffers",
  initialState: [],
  reducers: {
    setOrderTotalOffers: (state, { payload }) => {
      return [...payload];
    },
    addOrderTotalOffer: (state, { payload }) => {
      state.push({
        ...payload.orderTotalOffer,
        id: uuidv4(),
      });
    },
    removeOrderTotalOffer: (state, { payload }) => {
      return state.filter(
        (orderTotalOffer) => payload.id !== orderTotalOffer.id
      );
    },
    updateOrderTotalOffer: (state, { payload }) => {
      const orderTotalOffer = state.find(
        (_orderTotalOffer) => _orderTotalOffer.id === payload.id
      );
      orderTotalOffer.value = payload.value;
      orderTotalOffer.min_price = payload.min_price;
      orderTotalOffer.title = payload.title;
      orderTotalOffer.description = payload.description;
      orderTotalOffer.image = payload.image;
    },
  },
});

export default orderTotalOfferSlice.reducer;

export const {
  setOrderTotalOffers,
  addOrderTotalOffer,
  removeOrderTotalOffer,
  updateOrderTotalOffer,
} = orderTotalOfferSlice.actions;
