import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: 0,
  time_zone: "",
  social_links: [],
  logo: "",
  covers: [],
  name: "",
  slogan: "",
  country: "",
  city: "",
  street: "",
  zip_code: "",
  cuisine: "",
  pickup: true,
  delivery: true,
  table_reservation: true,
  dine_in: true,
  subscription: true,
  paypal: true,
  currency: "",
  privacy_policy: "",
  user_agreement: "",
  imprint: "",
  about: "",
  news: "",
  tax_seal: "",
  thank_note: "",
  return_policy: "",
  building_num: "",
  phone1: "",
  phone2: "",
  expanded_time: true,
  seo: "",
  enable_preorder: false,
  first_order_discount: 0,
  paypal_apikey: "",
  allow_anon: "",
  domain: "",
  promo_video : ""
};

export const informationSlice = createSlice({
  name: "information",
  initialState,
  reducers: {
    informationSetAttrib: (state, action) => {
      const { key, value } = action.payload;
      switch (key) {
        case "cuisine":
          state[key] = value.join(",");
          break;
        default:
          state[key] = value;
          break;
      }
    },
    informationSetState: (state, action) => {
      return { ...action.payload };
    },
  },
});

export default informationSlice.reducer;

export const { informationSetAttrib, informationSetState } =
  informationSlice.actions;
