import { ACCEPTED, DONE, PENDING, REJECTED } from "language";

function formatStatus(status) {
  switch (status) {
    case 1:
      return PENDING;
    case 2:
      return ACCEPTED;
    case 3:
      return REJECTED;
    case 4:
      return DONE;

    default:
      break;
  }
}

export const formatData = (data) => {
  let formatted = data.map((user) => ({
    ...user,
    email: user.email,
    username: user.username,
    created_at: new Date(user.created_at).toLocaleString(),
    datetime: new Date(user.datetime).toLocaleString(),
    status: formatStatus(user.status),
  }));
  return formatted;
};

export const formatFitlers = (filterModel) => {
  let filters = "";

  if (filterModel.created_at) {
    const from = filterModel.created_at.dateFrom
      ? `created_at__gte=${filterModel.created_at.dateFrom}`
      : "";
    const to = filterModel.created_at.dateTo
      ? `created_at__lte=${
          filterModel.created_at.dateTo.split(" ")[0] + " 23:59:59"
        }`
      : "";
    filters += from + "&" + to;
  }
  if (filterModel.datetime) {
    const from = filterModel.datetime.dateFrom
      ? `datetime__gte=${filterModel.datetime.dateFrom}`
      : "";
    const to = filterModel.datetime.dateTo
      ? `datetime__lte=${
          filterModel.datetime.dateTo.split(" ")[0] + " 23:59:59"
        }`
      : "";
    filters += from + "&" + to;
  }

  if (filterModel.first_name) {
    const _filter = `first_name__icontains=${filterModel.first_name.filter}`;
    filters += filters === "" ? _filter : "&" + _filter;
  }
  if (filterModel.last_name) {
    const _filter = `last_name__icontains=${filterModel.last_name.filter}`;
    filters += filters === "" ? _filter : "&" + _filter;
  }
  if (filterModel.email) {
    const _filter = `email__icontains=${filterModel.email.filter}`;
    filters += filters === "" ? _filter : "&" + _filter;
  }
  if (filterModel.phone) {
    const _filter = `phone__icontains=${filterModel.phone.filter}`;
    filters += filters === "" ? _filter : "&" + _filter;
  }

  return filters;
};


export function fixedEncodeURIComponent(str) {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return "%" + c.charCodeAt(0).toString(16);
  });
}

export const formatSorting = (sortModel) => {
  let sorting = "";
  sortModel.forEach((element) => {
    sorting = (element.sort === "desc" ? "-" : "") + element.colId + ",";
  });
  sorting = sorting ? "&ordering=" + sorting : "";
  return  sorting ;
};
