import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  ADD,
  ALLOW_MULTIPLE_CHOICES,
  CANCEL,
  FORCE_MAX,
  FORCE_MIN,
  GROUP,
  MANDATORY,
  NAME,
  OPTIONAL,
  SAVE,
} from "../../../../language";
import Button from "../../../../partials/Button";
import TextInput from "../../../../partials/TextInput";
import {
  addAddonGroup,
  updateAddonGroup,
} from "../../store/slices/addonGroupsSlice";
const NewAddonGroup = React.memo(function ({
  group = {},
  update = false,
  setEditing = () => {},
}) {
  const [name, setName] = useState(group.name || "");
  const [min, setMin] = useState(group.min_quantity || 1);
  const [max, setMax] = useState(group.max_quantity || 1);
  const [mandatory, setMandatory] = useState(group.min_quantity > 0);
  const [multiple, setMultiple] = useState(group.multiple || false);
  const [adding, setAdding] = useState(update);

  const dispatch = useDispatch();

  const handleSave = (e) => {
    e.preventDefault();

    const _min = !mandatory ? 0 : min;
    const _max = max;

    update
      ? dispatch(
          updateAddonGroup({
            id: group.id,
            name,
            multiple,
            min_quantity: _min,
            max_quantity: _max,
          })
        )
      : dispatch(
          addAddonGroup({
            group: {
              name,
              multiple,
              min_quantity: _min,
              max_quantity: _max,
              meal: [],
              meal_sizes: [],
              meal_categories: [],
            },
          })
        );

    setAdding(false);
    setEditing(false);
  };

  return (
    <div className="__add">
      {adding && (
        <form className="__form" onSubmit={handleSave}>
          <fieldset>
            <TextInput
              name={NAME}
              placeholder={NAME}
              setContextValue={(value) => setName(value)}
              initialValue={name}
            />

            <div className="__btns --switchers">
              <span
                className={`btn ${!mandatory ? "__active" : ""}`}
                onClick={() => setMandatory(false)}
              >
                {OPTIONAL}
              </span>
              <span
                className={`btn ${mandatory ? "__active" : ""}`}
                onClick={() => setMandatory(true)}
              >
                {MANDATORY}
              </span>
            </div>

            <TextInput
                  name={FORCE_MAX}
                  type="number"
                  initialValue={max === 0 && mandatory ? 1 : max}
                  min={0}
                  placeholder="max"
                  setContextValue={(value) => setMax(+value)}
                />

            {mandatory ? (
              <>
                <TextInput
                  name={FORCE_MIN}
                  placeholder="min"
                  type="number"
                  initialValue={min === 0 && mandatory ? 1 : min}
                  min={1}
                  setContextValue={(value) => setMin(+value)}
                />
               
              </>
            ) : (
              <>
                <label htmlFor="multiple">{ALLOW_MULTIPLE_CHOICES + " "}</label>
                <input
                  checked={multiple}
                  onChange={() => setMultiple(!multiple)}
                  type="checkbox"
                ></input>
              </>
            )}
           

            <div className="__btns">
              <Button type="submit">{SAVE}</Button>
              <Button
                onClick={() => {
                  setAdding(false);
                  setEditing(false);
                }}
              >
                {CANCEL}
              </Button>
            </div>
          </fieldset>
        </form>
      )}
      {!update && (
        <div className="__btn">
          <Button disabled={adding} onClick={() => setAdding(true)}>
            {ADD + " " + GROUP}
          </Button>
        </div>
      )}
    </div>
  );
});

export default NewAddonGroup;
