import React, { useState } from "react";
import {
  ARE_YOU_SURE,
  CURRENCY,
  EDIT,
  FREE_MIN_AMOUNT,
  MIN_ORDER_PRICE,
  PRICE,
  REMOVE,
} from "../../../language";
import { ACTION_REMOVE } from "../constants";
import { useEditMode, useZones } from "../context";
import NewZone from "./NewZone";

export default function ZoneHeader({ zone }) {
  const [editing, setEditing] = useState(false);
  const { editMode, forceRerender } = useEditMode();
  const { setZones } = useZones();

  const remove = () => {
    if (!window.confirm(ARE_YOU_SURE)) return;
    setZones({
      type: ACTION_REMOVE,
      id: zone["id"],
    });
    forceRerender();
  };

  return (
    <div className="__header">
      {editing && <NewZone zone={zone} setEditing={setEditing} update={true} />}

      {!editing && (
        <div className="__view">
          <span className="__title">{zone["name"]}</span>
          <span className="price">
            {PRICE + " : " + zone["price"] + " " + CURRENCY}
          </span>
          <span className="price">
            {FREE_MIN_AMOUNT + " : " + zone["min_free_amount"] + " " + CURRENCY}
          </span>
          <span className="price">
            {MIN_ORDER_PRICE + " : " + zone["min_order_price"] + " " + CURRENCY}
          </span>
        </div>
      )}

      {!editing && editMode && (
        <div className="__controls">
          <img
            onClick={() => setEditing(true)}
            alt="edit"
            title={EDIT}
            src={require("../../../assets/pen.svg").default}
          />
          <img
            onClick={remove}
            alt="remove"
            title={REMOVE}
            src={require("../../../assets/times.svg").default}
          />
        </div>
      )}
    </div>
  );
}
