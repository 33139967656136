import React, { useEffect, useState } from "react";

import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { getRequest } from "../../../utils/http";
import { useAuth } from "../../../Contexts";
import {
  CREATED_AT,
  DAILY_COUNT,
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  LOADING,
  PAYMENT,
  PRICE,
  RELOAD,
  SLUG,
  STATUS,
  TYPE,
} from "../../../language";
import { formatData, formatFitlers, formatSorting } from "../helpers";
import { domain } from "../../../constants";
import { IoIosRefresh } from "react-icons/io";
import PricesSumBox from "./PricesSumBox";
const PER_PAGE = 10;

export default function OrdersTable({
  setClickedOrder,
  refreshData,
  setRefreshData,
  setAutoReload,
  autoReload,
}) {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };
  const [cashSum, setCashSum] = useState(0);
  const [paypalSum, setPaypalSum] = useState(0);

  const { token } = useAuth();
  useEffect(() => {
    if (gridApi) {
      gridApi.showLoadingOverlay();
      const dataSource = {
        getRows: (params) => {
          const { sortModel, endRow, filterModel } = params;
          const page = endRow / PER_PAGE;
          const { sorting, sortByPrice } = formatSorting(sortModel);

          const filters = formatFitlers(filterModel);
          const filtering = filters
            ? `&filters=${fixedEncodeURIComponent(`(${filters})`)}`
            : "";
          getRequest(
            domain + `orders/order/?page=${page}&lite=1${sorting}${filtering}`,
            () => {},
            token
          ).then(([data, code]) => {
            if (code === 200) {
              gridApi.hideOverlay();
              params.successCallback(
                formatData(data.results, sortByPrice),
                data.count
              );
              setCashSum(data.cash_sum ?? 0);
              setPaypalSum(data.paypal_sum ?? 0);
            }
          });
        },
      };

      gridApi.setDatasource(dataSource);
    }
    // eslint-disable-next-line
  }, [gridApi, gridColumnApi, token, refreshData]);
  return (
    <>
      <div className="__auto-reload">
        <fieldset>
          <input
            id="live"
            type="checkbox"
            checked={autoReload}
            onChange={() => setAutoReload(!autoReload)}
          />
          <label htmlFor="live"> {RELOAD} </label>
        </fieldset>
        <PricesSumBox cashSum={cashSum} paypalSum={paypalSum} />

        <IoIosRefresh
          className="__icon"
          onClick={() => setRefreshData((prev) => prev + 1)}
        />
      </div>

      <div className="ag-theme-alpine ag-style __table">
        <AgGridReact
          overlayLoadingTemplate={LOADING}
          pagination={true}
          rowModelType={"infinite"}
          paginationPageSize={PER_PAGE}
          cacheBlockSize={PER_PAGE}
          onGridReady={onGridReady}
          rowHeight={50}
          onRowClicked={({ data }) => {
            setClickedOrder(data);
          }}
        >
          <AgGridColumn
            filter
            filterParams={{
              filterOptions: ["Equals"],
              suppressAndOrCondition: true,
            }}
            sortable
            field="id"
            headerName={"id"}
            resizable={true}
            width={100}
          />
          <AgGridColumn
            field="count"
            headerName={DAILY_COUNT}
            resizable={true}
            width={100}
          />
          <AgGridColumn
            width={100}
            field="slug"
            headerName={SLUG}
            resizable={true}
            filter
            filterParams={{
              filterOptions: ["Contains"],
              suppressAndOrCondition: true,
            }}
          />
          <AgGridColumn
            width={150}
            field="first_name"
            headerName={FIRST_NAME}
            resizable={true}
            sortable
            filter
            filterParams={{
              filterOptions: ["Contains"],
              suppressAndOrCondition: true,
            }}
          />
          <AgGridColumn
            width={150}
            field="last_name"
            headerName={LAST_NAME}
            resizable={true}
            sortable
            filter
            filterParams={{
              filterOptions: ["Contains"],
              suppressAndOrCondition: true,
            }}
          />

          <AgGridColumn
            width={200}
            sortable
            field="email"
            headerName={EMAIL}
            resizable={true}
            filter
            filterParams={{
              filterOptions: ["Contains"],
              suppressAndOrCondition: true,
            }}
          />
          <AgGridColumn
            width={200}
            field="created_at"
            headerName={CREATED_AT}
            resizable={true}
            sort="desc"
            sortable
            filter="agDateColumnFilter"
            filterParams={{
              filterOptions: ["inRange", " Greater Than"],
              suppressAndOrCondition: true,
              inRangeInclusive: true,
              debounceMs: 1000,
            }}
          />
          <AgGridColumn
            width={100}
            sortable
            field="total_price"
            headerName={PRICE}
            resizable={true}
          />
          <AgGridColumn
            sortable
            field="type"
            headerName={TYPE}
            resizable={true}
          />
          <AgGridColumn field="payment" headerName={PAYMENT} resizable={true} />
          <AgGridColumn
            sortable
            field="status"
            headerName={STATUS}
            resizable={true}
          />
        </AgGridReact>
      </div>
    </>
  );
}

function fixedEncodeURIComponent(str) {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return "%" + c.charCodeAt(0).toString(16);
  });
}
