import React, { useState } from "react";
import {
  ADD,
  ARE_YOU_SURE,
  DRIVER,
  DRIVERS,
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  PASSWORD,
  REMOVE,
  USERNAME,
} from "../../../language";
import Accordion from "../../../partials/Accordion";
import Button from "../../../partials/Button";
import TextInput from "../../../partials/TextInput";
import { useDrivers } from "../Context";

export default function Drivers() {
  const { drivers, addDriver, removeDriver } = useDrivers();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const onAdd = (e) => {
    e.preventDefault();
    addDriver({
      username,
      password,
      email,
      first_name: firstName,
      last_name: lastName,
    });
    setUsername("");
    setPassword("");
    setFirstName("");
    setLastName("");
    setEmail("");
  };
  const onRemove = (id) => {
    if (window.confirm(ARE_YOU_SURE)) {
      removeDriver(id);
    }
  };

  return (
    <div className="mgb-1">
      <Accordion title={DRIVERS}>
        <div className="__drivers">
          <form onSubmit={onAdd} className="__add">
            <TextInput
              setContextValue={(value) => setFirstName(value)}
              initialValue={firstName}
              name={FIRST_NAME}
              placeholder={FIRST_NAME}
            />
            <TextInput
              setContextValue={(value) => setLastName(value)}
              initialValue={lastName}
              name={LAST_NAME}
              placeholder={LAST_NAME}
            />
            <TextInput
              setContextValue={(value) => setUsername(value)}
              initialValue={username}
              name={USERNAME}
              placeholder={USERNAME}
            />
            <TextInput
              setContextValue={(value) => setEmail(value)}
              initialValue={email}
              name={EMAIL}
              placeholder={EMAIL}
            />
            <TextInput
              setContextValue={(value) => setPassword(value)}
              initialValue={password}
              name={PASSWORD}
              placeholder={PASSWORD}
              type="password"
            />
            <Button>{ADD + " " + DRIVER}</Button>
          </form>
          {drivers.length > 0 && (
            <table>
              <thead>
                <th>{USERNAME}</th>
                <th>{FIRST_NAME}</th>
                <th>{LAST_NAME}</th>
                <th>{EMAIL}</th>
                <th></th>
              </thead>
              <tbody>
                {drivers.map((driver) => (
                  <tr key={driver.id}>
                    <td>{driver.username}</td>
                    <td>{driver.first_name}</td>
                    <td>{driver.last_name}</td>
                    <td>{driver.email}</td>
                    <td className="__remove">
                      <Button onClick={() => onRemove(driver.id)}>
                        {REMOVE}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </Accordion>
    </div>
  );
}
