import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { domain } from "../../constants";
import { useAuth, useLoading } from "../../Contexts";
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from "../../utils/http";

const TablesProvider = createContext();

export default function Context({ children }) {
  const [dineTables, setDineTables] = useState([]);
  const { token } = useAuth();
  const { setLoading } = useLoading();

  useEffect(() => {
    getRequest(domain + "settings/dine_table/", setLoading, token).then(
      ([data, status]) => {
        if (status === 200) {
          setDineTables(data);
        }
      }
    );
  }, [setLoading, token]);

  const addTable = useCallback(
    (table) => {
      let _table = {};

      postRequest(
        domain + "settings/dine_table/",
        { ...table },
        setLoading,
        token
      ).then(([data, status]) => {
        if (status === 201) {
          _table = { ...data };
          setDineTables((prev) => [...prev, { ..._table }]);
        }
      });
    },
    [token, setLoading]
  );

  const removeTable = useCallback(
    (id) => {
      deleteRequest(
        domain + "settings/dine_table/" + id + "/",
        setLoading,
        token
      ).then(() => {
        setDineTables((prev) => prev.filter((table) => table.id !== id));
      });
    },
    [token, setLoading]
  );

  const updatePasscode = useCallback(
    (id, passcode) => {
      patchRequest(
        domain + "settings/dine_table/" + id + "/",
        { passcode },
        setLoading,
        token
      ).then(() => {
        setDineTables((prev) =>
          prev.map((table) => {
            if (table.id === id) {
              return { ...table, passcode };
            }
            return table;
          })
        );
      });
    },
    [token, setLoading]
  );

  return (
    <TablesProvider.Provider value={{ dineTables, addTable, removeTable , updatePasscode}}>
      {children}
      
    </TablesProvider.Provider>
  );
}

export const useTables = () => useContext(TablesProvider);
