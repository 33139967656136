import {
  ACCEPT,
  DISABLED,
  ENABLED,
  MAX_DAYS_TABLE,
  MAX_PEOPLE_NUM,
  SEE_RESERVATIONS_HERE,
  TABLE_RESERVATIONS,
} from "language";
import Accordion from "partials/Accordion";
import Button from "partials/Button";
import TextInput from "partials/TextInput";
import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useEditMode, useOthers } from "../context";

export default function Reservations() {
  const {
    tableReservations,
    setTableReservations,
    setMaxDaysTable,
    setMaxNumTable,
    maxDaysTable,
    maxNumTable,
  } = useOthers();
  const { editMode } = useEditMode();

  return (
    <div className="mgb-1">
      <Accordion title={TABLE_RESERVATIONS} opened={false}>
        <div className="__pickup">
          <label>{ACCEPT + " " + TABLE_RESERVATIONS + "? "} </label>
          <Button
            disabled={!editMode}
            onClick={() => setTableReservations(!tableReservations)}
          >
            {tableReservations ? ENABLED : DISABLED}
          </Button>
          <Link to="/reservations">{SEE_RESERVATIONS_HERE}</Link>
        </div>
        <div className="__pickup">
          <TextInput
            name={MAX_PEOPLE_NUM}
            setContextValue={setMaxNumTable}
            initialValue={maxNumTable}
            disabled={!editMode}
            type="number"
          />
        </div>
        <div className="__pickup">
          <TextInput
            name={MAX_DAYS_TABLE}
            setContextValue={setMaxDaysTable}
            initialValue={maxDaysTable}
            disabled={!editMode}
            type="number"
          />
        </div>
      </Accordion>
    </div>
  );
}
