import React, { useEffect, useState } from "react";

import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { getRequest } from "../../../utils/http";
import { useAuth } from "../../../Contexts";
import {
  CREATED_AT,
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  LOADING,
  PERSONS,
  PHONE,
  RESERVATION_DATE,
  STATUS,
} from "../../../language";
import { domain } from "../../../constants";
import { IoIosRefresh } from "react-icons/io";
import { fixedEncodeURIComponent, formatData, formatFitlers, formatSorting } from "../helpers";
const PER_PAGE = 10;

export default function ResTable({ refreshData, setRefreshData }) {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const { token } = useAuth();

  useEffect(() => {
    if (gridApi) {
      gridApi.showLoadingOverlay();
      const dataSource = {
        getRows: (params) => {
          const { endRow, filterModel , sortModel} = params;
          const page = endRow / PER_PAGE;

          const sorting = formatSorting(sortModel)
          const filters = formatFitlers(filterModel);
          const filtering = filters
            ? `&filters=${fixedEncodeURIComponent(`(${filters})`)}`
            : "";
          getRequest(
            domain + `orders/table_reservation/?page=${page}${filtering}${sorting}`,
            () => {},
            token
          ).then(([data, code]) => {
            if (code === 200) {
              gridApi.hideOverlay();
              params.successCallback(formatData(data.results), data.count);
            }
          });
        },
      };

      gridApi.setDatasource(dataSource);
    }
    // eslint-disable-next-line
  }, [gridApi, gridColumnApi, token, refreshData]);
  return (
    <>
      <div className="__auto-reload">
        <IoIosRefresh
          className="__icon"
          onClick={() => setRefreshData((prev) => prev + 1)}
        />
      </div>
      <div className="ag-theme-alpine ag-style __table">
        <AgGridReact
          overlayLoadingTemplate={LOADING}
          pagination={true}
          rowModelType={"infinite"}
          paginationPageSize={PER_PAGE}
          cacheBlockSize={PER_PAGE}
          onGridReady={onGridReady}
          rowHeight={50}
        >
          <AgGridColumn
            width={100}
            filter
            filterParams={{
              filterOptions: ["Contains"],
              suppressAndOrCondition: true,
            }}
            sortable
            field="id"
            headerName={"id"}
            resizable={true}
          />
          <AgGridColumn
            width={150}
            filter
            filterParams={{
              filterOptions: ["Contains"],
              suppressAndOrCondition: true,
            }}
            sortable
            field="first_name"
            headerName={FIRST_NAME}
            resizable={true}
          />
          <AgGridColumn
            width={150}
            filter
            sortable
            filterParams={{
              filterOptions: ["Contains"],
              suppressAndOrCondition: true,
            }}
            field="last_name"
            headerName={LAST_NAME}
            resizable={true}
          />

          <AgGridColumn
            width={200}
            filter
            filterParams={{
              filterOptions: ["Contains"],
              suppressAndOrCondition: true,
            }}
            sortable
            field="email"
            headerName={EMAIL}
            resizable={true}
          />

          <AgGridColumn
            width={200}
            filter
            
            filterParams={{
              filterOptions: ["Contains"],
              suppressAndOrCondition: true,
            }}
            sortable
            field="phone"
            headerName={PHONE}
            resizable={true}
          />
          <AgGridColumn
            width={200}
            field="created_at"
            headerName={CREATED_AT}
            resizable={true}
            sort="desc"
            sortable
            filter="agDateColumnFilter"
            filterParams={{
              filterOptions: ["inRange"],
              suppressAndOrCondition: true,
              inRangeInclusive: true,
              debounceMs: 1000,
            }}
          />
          <AgGridColumn
            width={200}
            field="datetime"
            headerName={RESERVATION_DATE}
            resizable={true}
            sortable
            filter="agDateColumnFilter"
            filterParams={{
              filterOptions: ["inRange"],
              suppressAndOrCondition: true,
              inRangeInclusive: true,
              debounceMs: 1000,
            }}
          />
          <AgGridColumn
            field="persons_count"
            headerName={PERSONS}
            resizable={true}
            sortable
            
          />
          <AgGridColumn
            field="status"
            headerName={STATUS}
            resizable={true}
            sortable
          />
        </AgGridReact>
      </div>
    </>
  );
}
