import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

export const couponsSlice = createSlice({
  name: "coupons",
  initialState: [],
  reducers: {
    setCoupons: (state, { payload }) => {
      return [...payload];
    },
    addCoupon: (state, { payload }) => {
      state.push({
        ...payload.coupon,
        id: uuidv4(),
      });
    },
    removeCoupon: (state, { payload }) => {
      return state.filter((coupon) => payload.id !== coupon.id);
    },
  },
});

export default couponsSlice.reducer;

export const {
  setCoupons,
  addCoupon,
  removeCoupon,
} = couponsSlice.actions;
