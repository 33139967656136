import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  ARE_YOU_SURE,
  CATEGORY,
  DESCRIPTION,
  FIXED,
  FROM,
  HIDDEN,
  IMAGE,
  MEAL,
  PERCENTAGE,
  SIZE,
  TITLE,
  TO,
  TYPE,
  VALUE,
  WEEKDAY,
  WEEKDAYS_EXTENDED,
} from "../../../../language";
import Controls from "../../../../partials/Controls";
import { useController } from "../../Controller";
import {
  removeDailyOffer,
  toggleDailyOfferHide,
} from "../../store/slices/DailyOfferSlice";
import NewDailyOffer from "./NewDailyOffer";
export default React.memo(function DailyOffer({ offer }) {
  const [editing, setEditing] = useState(false);
  const dispatch = useDispatch();

  const remove = () => {
    if (!window.confirm(ARE_YOU_SURE)) return;
    dispatch(removeDailyOffer({ id: offer.id }));
  };
  const hide = () => {
    dispatch(toggleDailyOfferHide({ id: offer.id }));
  };

  const [category, setCategory] = useState({ name: "" });
  const [meal, setMeal] = useState({ name: "" });
  const [size, setSize] = useState({ name: "" });
  const {
    getMeal,
    getSize,
    getCategory,
    areSizesForOneMeal,
    meals,
    sizes,
    categories,
  } = useController();

  useEffect(() => {
    setCategory(() => {
      if (offer.meal_category) return getCategory(+offer.meal_category);
      if (offer.meal) {
        return getCategory(+getMeal(+offer.meal).category);
      }
      return getCategory(
        +getMeal(+getSize(+offer.meal_sizes[0]).meal).category
      );
    });
    setMeal(() => {
      if (offer.meal) {
        return getMeal(+offer.meal) ?? { name: "" };
      }
      return { name: "" };
    });
    setSize(() => {
      if (offer.meal_sizes && offer.meal_sizes.length) {
        const _size = getSize(+offer.meal_sizes[0]);
        if (areSizesForOneMeal(offer.meal_sizes || [])) return _size;
        else {
          return _size;
        }
      }
      return { name: "" };
    });
    // eslint-disable-next-line
  }, [offer, meals, categories, sizes]);

  return (
    <>
      {!editing && (
        <Controls remove={remove} hide={hide} edit={() => setEditing(true)} />
      )}

      {editing ? (
        <NewDailyOffer offer={offer} setEditing={setEditing} update={true} />
      ) : (
        <div>
          <span className="__hidden">{offer.hidden ? HIDDEN : ""}</span>
          <table className="__table">
            <tbody>
              {offer.start ? (
                <>
                  <tr>
                    <td>{FROM}</td>
                    <td>{new Date(offer.start).toLocaleString()}</td>
                  </tr>
                  <tr>
                    <td>{TO}</td>
                    <td>{new Date(offer.end).toLocaleString()}</td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td>{WEEKDAY}</td>
                  <td>{WEEKDAYS_EXTENDED[offer.weekday]}</td>
                </tr>
              )}

              <tr>
                <td>{TYPE}</td>
                <td>{+offer.type === 1 ? FIXED : PERCENTAGE}</td>
              </tr>
              <tr>
                <td>{CATEGORY}</td>
                <td>{category.name}</td>
              </tr>
              {meal.name && (
                <tr>
                  <td>{MEAL}</td>
                  <td>{meal.name}</td>
                </tr>
              )}
              {size.name && (
                <tr>
                  <td>{SIZE}</td>
                  <td>{size.name}</td>
                </tr>
              )}
              <tr>
                <td>{VALUE}</td>
                <td>{offer.value}</td>
              </tr>
              <tr>
                <td>{TITLE}</td>
                <td>{offer.title}</td>
              </tr>
              <tr>
                <td>{DESCRIPTION}</td>
                <td>{offer.description}</td>
              </tr>
              <tr>
                <td>{IMAGE}</td>
                <td>
                  <img src={offer.image} alt=""></img>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
});
