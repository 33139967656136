import React, { createContext, useContext, useEffect, useState } from "react";
import { domain } from "../../../constants";
import { useAuth, useLoading } from "../../../Contexts";
import { getRequest, patchRequest } from "../../../utils/http";

const EditModeContext = createContext();
const EmailConfigContext = createContext();

export default function Context({ children }) {
  const [editMode, setEditMode] = useState(false);

  const [statusMessage, setStatusMessage] = useState("");

  const { setLoading } = useLoading();
  const { token } = useAuth();

  const [emailHost, setEmailHost] = useState("");
  const [emailPort, setEmailPort] = useState("");
  const [emailUser, setEmailUser] = useState("");
  const [emailPassword, setEmailPassword] = useState("");
  const [emailTls, setEmailTls] = useState(false);
  const [adminEmail, setAdminEmail] = useState("");
  const [adminEmail2, setAdminEmail2] = useState("");

  useEffect(() => {
    getRequest(domain + "settings/email_config/1/", setLoading, token).then(
      ([data, status]) => {
        if (status === 200) {
          setEmailHost(data["email_host"]);
          setEmailPort(data["email_port"]);
          setEmailPassword(data["email_host_password"]);
          setEmailUser(data["email_host_user"]);
          setEmailTls(data["email_use_tls"]);
        }
      }
    );
    getRequest(domain + "settings/restaurant/1/", setLoading, token).then(
      ([data, status]) => {
        if (status === 200) {
          setAdminEmail(data["admin_email"]);
          setAdminEmail2(data["admin_email2"]);
        }
      }
    );
    // eslint-disable-next-line
  }, [token, setLoading]);

  const saveChanges = () => {
    patchRequest(
      domain + "settings/email_config/1/",
      {
        email_host: emailHost,
        email_port: emailPort,
        email_host_password: emailPassword,
        email_host_user: emailUser,
        email_use_tls: emailTls,
      },
      setLoading,
      token
    );
    patchRequest(
      domain + "settings/restaurant/1/",
      {
        admin_email: adminEmail,
        admin_email2: adminEmail2,
      },
      setLoading,
      token
    );
  };

  return (
    <EditModeContext.Provider
      value={{
        editMode,
        setEditMode,
        saveChanges,
        statusMessage,
        setStatusMessage,
      }}
    >
      <EmailConfigContext.Provider
        value={{
          emailUser,
          emailHost,
          emailPassword,
          emailPort,
          emailTls,
          adminEmail,
          adminEmail2,
          setEmailTls,
          setEmailUser,
          setEmailPort,
          setEmailPassword,
          setEmailHost,
          setAdminEmail,
          setAdminEmail2,
        }}
      >
        {children}
      </EmailConfigContext.Provider>
    </EditModeContext.Provider>
  );
}

export const useEditMode = () => {
  return useContext(EditModeContext);
};
export const useEmailConfig = () => {
  return useContext(EmailConfigContext);
};
