import _ from "lodash";
import React, { useEffect, useState } from "react";

export default React.memo(function SelectInput({
  options,
  name,
  icon,
  setContextValue,
  initialValue,
  ...props
}) {
  const [value, setValue] = useState("");
  useEffect(() => {
    if (initialValue && !_.isEmpty(initialValue)) {
      setValue(initialValue.id || initialValue);
    }
  }, [initialValue]);

  return (
    <div className="input">
      <label className="__label" htmlFor={name}>
        {name}
      </label>
      <div className="__box">
        {icon ? (
          <img
            className="__icon"
            src={require(`../assets/${icon}`).default}
            alt={name}
          ></img>
        ) : (
          ""
        )}
        <select
          className="__field"
          {...props}
          onChange={(e) => {
            setValue(e.target.value);
            setContextValue(e.target.value);
          }}
          value={value}
        >
          <option defaultValue className="__placeholder" value="" disabled>
            {[name]}
          </option>
          {options.map((option) => (
            <option
              key={option.id ? option.id : option}
              className="__option"
              value={option.id ? option.id : option}
            >
              {option.name ? option.name : option}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
});
