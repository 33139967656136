import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

export const categoriesSlice = createSlice({
  name: "categories",
  initialState: [],
  reducers: {
    setCategories: (state, { payload }) => {
      return [...payload];
    },
    addCategory: (state, { payload }) => {
      state.push({
        ...payload.category,
        hidden: false,
        is_offer : false,
        id: uuidv4(),
        priority: state.length,
      });
    },
    removeCategory: (state, { payload }) => {
      return state.filter((category) => payload.id !== category.id);
    },
    updateCategory: (state, { payload }) => {
      const category = state.find((_category) => _category.id === payload.id);
      category.name = payload.name;
      category.description = payload.description;
    },
    reorderCategory: (state, { payload }) => {
      const category = state.splice(payload.source, 1)[0];
      state.splice(payload.destination, 0, category);
      state.forEach((category, index) => {
        category.priority = index;
      });
    },
    toggleCategoryHide: (state, { payload }) => {
      const category = state.find((_category) => _category.id === payload.id);
      category.hidden = !category.hidden;
    },
    toggleCategoryIsOffer: (state, { payload }) => {
      const category = state.find((_category) => _category.id === payload.id);
      category.is_offer = !category.is_offer;
    },
    addCategoryImage: (state, { payload }) => {
      const category = state.find((_category) => _category.id === payload.id);
      category.image = payload.image;
    },
  },
});

export default categoriesSlice.reducer;

export const {
  setCategories,
  addCategory,
  removeCategory,
  updateCategory,
  reorderCategory,
  toggleCategoryHide,
  toggleCategoryIsOffer,
  addCategoryImage,
} = categoriesSlice.actions;
