import { BIRTHDATE, EMAIL, FIRST_NAME, JOB, LAST_NAME, PHONE } from "language";
import React, { useCallback, useState } from "react";
import { useJobs } from "../Context";
import JobApplication from "./JobApplication";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
export default function JobApplications() {
  const { jobs, getAvailableJobById } = useJobs();

  const [clickedJob, setClickedJob] = useState(null);

  const formatJobs = useCallback(() => {
    return jobs.map((job) => ({
      ...job,
      applied: getAvailableJobById(job.job)
        ? getAvailableJobById(job.job).job_title
        : "--------------",
    }));
  }, [jobs, getAvailableJobById]);


  if (clickedJob)
    return (
      <JobApplication job={clickedJob} goBack={() => setClickedJob(null)} />
    );

  return (
    <div className="mgb-1">
      <div className="ag-theme-alpine ag-style __table">
        <AgGridReact
          onRowClicked={({ data }) => {
            setClickedJob(data);
          }}
          rowData={formatJobs()}
        >
          <AgGridColumn
            sortable
            field="applied"
            headerName={JOB}
            resizable={true}
            width={150}
          />
          <AgGridColumn
            sortable
            field="first_name"
            headerName={FIRST_NAME}
            resizable={true}
            width={150}
          />
          <AgGridColumn
            sortable
            field="last_name"
            headerName={LAST_NAME}
            width={150}
            resizable={true}
          />
          <AgGridColumn
            width={150}
            sortable
            field="birthdate"
            headerName={BIRTHDATE}
            resizable={true}
          />
          <AgGridColumn
            sortable
            field="email"
            headerName={EMAIL}
            resizable={true}
          />
          <AgGridColumn
            width={150}
            sortable
            field="phone"
            headerName={PHONE}
            resizable={true}
          />
        </AgGridReact>
      </div>
    </div>
  );
}
