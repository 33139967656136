import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

export const dailyOffersSlice = createSlice({
  name: "dailyOffers",
  initialState: [],
  reducers: {
    setDailyOffers: (state, { payload }) => {
      return [...payload];
    },
    addDailyOffer: (state, { payload }) => {
      state.push({
        ...payload.dailyOffer,
        id: uuidv4(),
      });
    },
    removeDailyOffer: (state, { payload }) => {
      return state.filter((dailyOffer) => payload.id !== dailyOffer.id);
    },
    updateDailyOffer: (state, { payload }) => {
      const dailyOffer = state.find(
        (_dailyOffer) => _dailyOffer.id === payload.id
      );
      dailyOffer.weekday = payload.weekday;
      dailyOffer.meal = payload.meal;
      dailyOffer.meal_category = payload.meal_category;
      dailyOffer.meal_sizes = payload.meal_sizes;
      dailyOffer.value = payload.value;
      dailyOffer.type = payload.type;
      dailyOffer.title = payload.title;
      dailyOffer.description = payload.description;
      dailyOffer.image = payload.image;
      dailyOffer.start = payload.start;
      dailyOffer.end = payload.end;
    },
    toggleDailyOfferHide: (state, { payload }) => {
      const dailyOffer = state.find(
        (_dailyOffer) => _dailyOffer.id === payload.id
      );
      dailyOffer.hidden = !dailyOffer.hidden;
    },
  },
});

export default dailyOffersSlice.reducer;

export const {
  setDailyOffers,
  addDailyOffer,
  removeDailyOffer,
  updateDailyOffer,
  toggleDailyOfferHide,
} = dailyOffersSlice.actions;
