import React, { useEffect, useState } from "react";

import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  getRequest,
} from "../../../utils/http";
import { useAuth } from "../../../Contexts";
import {
  ADDRESS,
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  LOADING,
  PHONE,
  POINTS,
  USERNAME,
} from "../../../language";
import { domain } from "../../../constants";
import { IoIosRefresh } from "react-icons/io";
import {
  fixedEncodeURIComponent,
  formatFitlers,
  formatSorting,
} from "../helpers";
const PER_PAGE = 10;

export default function DeletedUsersTable({ refreshData, setRefreshData }) {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const { token } = useAuth();

  useEffect(() => {
    if (gridApi) {
      gridApi.showLoadingOverlay();
      const dataSource = {
        getRows: (params) => {
          const { endRow, filterModel, sortModel } = params;
          const page = endRow / PER_PAGE;
          const sorting = formatSorting(sortModel);

          const filters = formatFitlers(filterModel);

          const filtering = filters
            ? `&filters=${fixedEncodeURIComponent(`(${filters}&role=1)`)}`
            : "";
          getRequest(
            domain +
              `users/users/?deleted=1&page=${page}${filtering}${sorting}`,
            () => {},
            token
          ).then(([data, code]) => {
            if (code === 200) {
              gridApi.hideOverlay();
              params.successCallback(formatData(data.results), data.count);
            }
          });
        },
      };

      gridApi.setDatasource(dataSource);
    }
    // eslint-disable-next-line
  }, [gridApi, gridColumnApi, token, refreshData]);
  return (
    <>
      <div className="__auto-reload">
        <IoIosRefresh
          className="__icon"
          onClick={() => setRefreshData((prev) => prev + 1)}
        />
      </div>
      <div className="ag-theme-alpine ag-style __table">
        <AgGridReact
          overlayLoadingTemplate={LOADING}
          pagination={true}
          rowModelType={"infinite"}
          paginationPageSize={PER_PAGE}
          cacheBlockSize={PER_PAGE}
          onGridReady={onGridReady}
          rowHeight={50}
        >
          <AgGridColumn
            filter
            filterParams={{
              filterOptions: ["Equals"],
              suppressAndOrCondition: true,
            }}
            width={80}
            sortable
            field="id"
            headerName={"id"}
            resizable={true}
          />
          <AgGridColumn
            width={140}
            filter
            filterParams={{
              filterOptions: ["Equals"],
              suppressAndOrCondition: true,
            }}
            field="first_name"
            headerName={FIRST_NAME}
            resizable={true}
          />
          <AgGridColumn
            width={140}
            filter
            filterParams={{
              filterOptions: ["Equals"],
              suppressAndOrCondition: true,
            }}
            field="last_name"
            headerName={LAST_NAME}
            resizable={true}
          />

          <AgGridColumn
            width={250}
            filter
            filterParams={{
              filterOptions: ["Equals"],
              suppressAndOrCondition: true,
            }}
            sortable
            field="email"
            headerName={EMAIL}
            resizable={true}
          />
          <AgGridColumn
            width={150}
            filter
            filterParams={{
              filterOptions: ["Equals"],
              suppressAndOrCondition: true,
            }}
            sortable
            field="username"
            headerName={USERNAME}
            resizable={true}
          />
          <AgGridColumn
            filter
            filterParams={{
              filterOptions: ["Equals"],
              suppressAndOrCondition: true,
            }}
            sortable
            field="phone"
            headerName={PHONE}
            resizable={true}
          />
          <AgGridColumn
            sortable
            field="address"
            headerName={ADDRESS}
            resizable={true}
          />
          <AgGridColumn
            width={100}
            sortable
            field="points"
            headerName={POINTS}
            resizable={true}
          />
        </AgGridReact>
      </div>
    </>
  );
}

const formatData = (data) => {
  let formatted = data.map((user) => ({
    ...user,

    email: user.email,
    username: user.username,
    phone: user.phone_number,
    address: user.address + " - " + user.building_number,
  }));
  return formatted;
};
