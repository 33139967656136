import React, { useState } from "react";

export default React.memo(function Accordion({
  children,
  title,
  variant = "dark",
  opened = false,
  pinned = false,
}) {
  const [collapsed, setCollapsed] = useState(!opened && !pinned);
  const toggleAccordion = (e) => {
    if (!pinned) setCollapsed((prev) => !prev);
  };
  return (
    <div className="accordion ">
      <div
        className={`__header ${variant !== "dark" ? "--light" : ""}`}
        onClick={toggleAccordion}
      >
        <p className="__text">{title}</p>
        <img
          className={`__icon ${!collapsed ? "--up" : ""}`}
          src={require("../assets/caret.svg").default}
          alt=""
        />
      </div>
      <div className={`__body${collapsed ? "--collapsed" : ""}`}>
        {children}
      </div>
    </div>
  );
});
