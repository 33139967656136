import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { useAuth } from "../Contexts";
export default function ProtectedRoute({ component, ...props }) {
  const { isLoggedIn } = useAuth();
  const location = useLocation()
  return (
    <Route
      {...props}
      render={() => {
        if (isLoggedIn) {
          return component;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { referrer: location.pathname },
              }}
            ></Redirect>
          );
        }
      }}
    ></Route>
  );
}
