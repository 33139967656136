import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../Contexts";
import {
  ACCOUNT,
  BASICS,
  FOOD_MENU,
  LOGOUT,
  ORDERS,
  SERVICES,
  WORK_HOURS,
  OFFERS,
  USERS,
  STAFF,
  TABLES,
  REVIEWS,
  THEME_COLORS,
  TABLE_RESERVATIONS,
  JOBS,
  SEO_PAGES,
  EMAILS_MARKETING,
} from "../../language";
import {
  FaCog,
  FaClock,
  FaUserAlt,
  FaSignOutAlt,
  FaUsers,
  FaCalendarAlt,
} from "react-icons/fa";
import { ImSpoonKnife } from "react-icons/im";
import {
  RiPagesFill,
  RiTakeawayFill,
  RiUserSettingsLine,
} from "react-icons/ri";
import { BsInboxesFill } from "react-icons/bs";
import { AiFillFile, AiOutlineMail } from "react-icons/ai";
import { GiTable } from "react-icons/gi";
import { IoGift } from "react-icons/io5";
import { FiStar } from "react-icons/fi";
import { IoMdColorPalette } from "react-icons/io";

export default React.memo(() => {
  const history = useHistory();
  const [active, setActive] = useState(history.location.pathname);
  const { LogOut } = useAuth();
  return (
    <nav className="side-bar">
      <ul className="side-bar__list">
        <i onClick={() => LogOut()} className="btn --sidebar">
          <FaSignOutAlt className="side-bar__list__item__icon" />
          <span className="side-bar__list__item__text">{LOGOUT}</span>
        </i>
        <Link
          key={BASICS}
          onClick={(e) => setActive("/basics")}
          to={"/basics"}
          className={`side-bar__list__item ${
            active === "/basics" ? "active" : ""
          }`}
        >
          <FaCog className="side-bar__list__item__icon" />
          <span className="side-bar__list__item__text">{BASICS}</span>
        </Link>
        <Link
          key={FOOD_MENU}
          onClick={(e) => setActive("/menu")}
          to={"/menu"}
          className={`side-bar__list__item ${
            active === "/menu" ? "active" : ""
          }`}
        >
          <ImSpoonKnife className="side-bar__list__item__icon" />
          <span className="side-bar__list__item__text">{FOOD_MENU}</span>
        </Link>
        <Link
          key={WORK_HOURS}
          onClick={(e) => setActive("/hours")}
          to={"/hours"}
          className={`side-bar__list__item ${
            active === "/hours" ? "active" : ""
          }`}
        >
          <FaClock className="side-bar__list__item__icon" />
          <span className="side-bar__list__item__text">{WORK_HOURS}</span>
        </Link>
        <Link
          key={SERVICES}
          onClick={(e) => setActive("/services")}
          to={"/services"}
          className={`side-bar__list__item ${
            active === "/services" ? "active" : ""
          }`}
        >
          <RiTakeawayFill className="side-bar__list__item__icon" />
          <span className="side-bar__list__item__text">{SERVICES}</span>
        </Link>

        <Link
          key={ORDERS}
          onClick={(e) => setActive("/orders")}
          to={"/orders"}
          className={`side-bar__list__item ${
            active === "/orders" ? "active" : ""
          }`}
        >
          <BsInboxesFill className="side-bar__list__item__icon" />
          <span className="side-bar__list__item__text">{ORDERS}</span>
        </Link>
        <Link
          key={TABLE_RESERVATIONS}
          onClick={(e) => setActive("/reservations")}
          to={"/reservations"}
          className={`side-bar__list__item ${
            active === "/reservations" ? "active" : ""
          }`}
        >
          <FaCalendarAlt className="side-bar__list__item__icon" />
          <span className="side-bar__list__item__text">
            {TABLE_RESERVATIONS}
          </span>
        </Link>
        <Link
          key={OFFERS}
          onClick={(e) => setActive("/offers")}
          to={"/offers"}
          className={`side-bar__list__item ${
            active === "/offers" ? "active" : ""
          }`}
        >
          <IoGift className="side-bar__list__item__icon" />
          <span className="side-bar__list__item__text">{OFFERS}</span>
        </Link>
        <Link
          key={EMAILS_MARKETING}
          onClick={(e) => setActive("/emails")}
          to={"/emails"}
          className={`side-bar__list__item ${
            active === "/emails" ? "active" : ""
          }`}
        >
          <AiOutlineMail className="side-bar__list__item__icon" />
          <span className="side-bar__list__item__text">{EMAILS_MARKETING}</span>
        </Link>
        <Link
          key={USERS}
          onClick={(e) => setActive("/users")}
          to={"/users"}
          className={`side-bar__list__item ${
            active === "/users" ? "active" : ""
          }`}
        >
          <FaUsers className="side-bar__list__item__icon" />
          <span className="side-bar__list__item__text">{USERS}</span>
        </Link>
        <Link
          key={STAFF}
          onClick={(e) => setActive("/staff")}
          to={"/staff"}
          className={`side-bar__list__item ${
            active === "/staff" ? "active" : ""
          }`}
        >
          <RiUserSettingsLine className="side-bar__list__item__icon" />
          <span className="side-bar__list__item__text">{STAFF}</span>
        </Link>
        <Link
          key={TABLES}
          onClick={(e) => setActive("/tables")}
          to={"/tables"}
          className={`side-bar__list__item ${
            active === "/tables" ? "active" : ""
          }`}
        >
          <GiTable className="side-bar__list__item__icon" />
          <span className="side-bar__list__item__text">{TABLES}</span>
        </Link>
        <Link
          key={REVIEWS}
          onClick={(e) => setActive("/reviews")}
          to={"/reviews"}
          className={`side-bar__list__item ${
            active === "/reviews" ? "active" : ""
          }`}
        >
          <FiStar className="side-bar__list__item__icon" />
          <span className="side-bar__list__item__text">{REVIEWS}</span>
        </Link>
        <Link
          key={THEME_COLORS}
          onClick={(e) => setActive("/theme")}
          to={"/theme"}
          className={`side-bar__list__item ${
            active === "/theme" ? "active" : ""
          }`}
        >
          <IoMdColorPalette className="side-bar__list__item__icon" />
          <span className="side-bar__list__item__text">{THEME_COLORS}</span>
        </Link>
        <Link
          key={JOBS}
          onClick={(e) => setActive("/jobs")}
          to={"/jobs"}
          className={`side-bar__list__item ${
            active === "/jobs" ? "active" : ""
          }`}
        >
          <RiPagesFill className="side-bar__list__item__icon" />
          <span className="side-bar__list__item__text">{JOBS}</span>
        </Link>

        <Link
          key={SEO_PAGES}
          onClick={(e) => setActive("/seo")}
          to={"/seo"}
          className={`side-bar__list__item ${
            active === "/seo" ? "active" : ""
          }`}
        >
          <AiFillFile className="side-bar__list__item__icon" />
          <span className="side-bar__list__item__text">{SEO_PAGES}</span>
        </Link>

        <Link
          key={ACCOUNT}
          onClick={(e) => setActive("/account")}
          to={"/account"}
          className={`side-bar__list__item ${
            active === "/account" ? "active" : ""
          }`}
        >
          <FaUserAlt className="side-bar__list__item__icon" />
          <span className="side-bar__list__item__text">{ACCOUNT}</span>
        </Link>
      </ul>
    </nav>
  );
});
