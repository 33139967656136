import React from "react";
import { REVIEWS } from "../../../language";
import Accordion from "../../../partials/Accordion";
import { useReviews } from "../Context";
import Review from "./Review";
import ReviewStats from "./ReviewStats";

export default function ReviewsTable() {
  const { reviews } = useReviews();
  return (
    <div className="mgb-1">
      <Accordion title={REVIEWS} opened={true}>
        <ReviewStats /> 
        <ul>
          {reviews.map((review) => (
            <Review key={review.id} review={review} />
          ))}
        </ul>
      </Accordion>
    </div>
  );
}
