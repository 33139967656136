import React from "react";
import { Provider } from "react-redux";
import EditMode, { useEditMode } from "../../ContextsComponent/EditMode";
import { OFFERS } from "../../language";
import TopBar from "../../partials/TopBar";
import BannerOffers from "./components/BannerOffers";
import DailyOffers from "./components/DailyOffers/index";
import OtherOffers from "./components/OtherOffers";
import OrderTotalOffers from "./components/OrderTotalOffers";
import Controller, { useController } from "./Controller";
import store from "./store/store";
import OrderGifts from "./components/OrderGifts.js";
import Coupons from "./components/Coupons";

function Index() {
  const { editMode, setEditMode, statusMessage  } = useEditMode();
  const {saveChanges} = useController()

  return (
    <>
      <TopBar
        title={OFFERS}
        editMode={editMode}
        setEditMode={setEditMode}
        saveChanges={saveChanges}
        message={statusMessage}
      ></TopBar>

      <div
        className={`offers animate__animated animate__fadeIn ${
          !editMode ? "view-mode" : ""
        }`}
      >
        <DailyOffers />
        <OrderTotalOffers />
        <OrderGifts />
        <BannerOffers />
        <Coupons />
        <OtherOffers />
      </div>
    </>
  );
}

export default function Offers() {
  return (
    <Provider store={store}>
      <EditMode>
        <Controller>
          <Index />
        </Controller>
      </EditMode>
    </Provider>
  );
}
