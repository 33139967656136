import React from "react";
import { STAFF } from "../../language";
import TopBar from "../../partials/TopBar";
import Drivers from "./components/Drivers";
import Staffs from "./components/Staffs";
import Context from "./Context";

export default function Staff() {
  return (
    <Context>
      <TopBar title={STAFF} />
      <div className="staff">
        <Drivers></Drivers>
        <Staffs ></Staffs>
      </div>
    </Context>
  );
}
