import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { domain } from "../../constants";
import { useAuth, useLoading } from "../../Contexts";
import { getRequest, patchRequest } from "../../utils/http";

const ReviewsContext = createContext();

export default function Context({ children }) {
  const [reviews, setReviews] = useState([]);
  const [reviewsAvg, setReviewsAvg] = useState({});

  const { token } = useAuth();
  const { setLoading } = useLoading();
  const toggleShowReview = useCallback(
    (id, value) => {
      return patchRequest(
        `${domain}users/user_review/${id}/`,
        {
          hidden: value,
        },
        setLoading,
        token
      );
    },
    [setLoading, token]
  );

  useEffect(() => {
    getRequest(domain + "users/user_review/", setLoading, token).then(
      ([data, status]) => {
        if (status === 200) {
          setReviews(data);
        }
      }
    );
    getRequest(domain + "users/user_review/average/", setLoading, token).then(
      ([data, status]) => {
        if (status === 200) {
          setReviewsAvg(data);
        }
      }
    );
  }, [setLoading, token]);

  return (
    <ReviewsContext.Provider value={{ reviews, toggleShowReview , reviewsAvg   }}>
      {children}
    </ReviewsContext.Provider>
  );
}

export const useReviews = () => useContext(ReviewsContext);
