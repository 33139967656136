import React from "react";
import { Provider } from "react-redux";
import EditMode, { useEditMode } from "../../ContextsComponent/EditMode";
import { FOOD, MENU } from "../../language";
import TopBar from "../../partials/TopBar";
import Addons from "./components/Addons";
import Menu from "./components/Menu";
import Controller, { useController } from "./Controller";
import store from "./store/store";

function Index() {
  const { editMode, setEditMode, statusMessage } = useEditMode();
  const { saveChanges } = useController();

  return (
    <>
      <TopBar
        title={FOOD + " " + MENU}
        editMode={editMode}
        setEditMode={setEditMode}
        saveChanges={saveChanges}
        message={statusMessage}
      ></TopBar>
      <div
        className={`food-menu animate__animated animate__fadeIn ${
          !editMode ? "view-mode" : ""
        }`}
      >
        <Addons />
        <Menu />
      </div>
    </>
  );
}

export default function FoodMenu() {
  return (
    <Provider store={store}>
      <EditMode>
        <Controller>
          <Index />
        </Controller>
      </EditMode>
    </Provider>
  );
}
