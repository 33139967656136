import {
  ANNOTAION,
  ARE_YOU_SURE,
  BIRTHDATE,
  CURRENTLY_EMPLOYED,
  DRIVING_LICENSE,
  EMAIL,
  JOB,
  MARITAL_STATUS,
  MR,
  MS,
  NAME,
  NATIONALITY,
  NO,
  OWN_A_CAR,
  PHONE,
  PLACE_OF_BIRTH,
  POSTCODE,
  PROFESSION,
  REMOVE,
  SKILLS,
  STREET,
  YES,
} from "language";
import Button from "partials/Button";
import React from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useJobs } from "../Context";

export default function JobApplication({ job , goBack= () => {} }) {
  const { removeJob } = useJobs();

  const remove = () => {
    if (!window.confirm(ARE_YOU_SURE)) return;

    removeJob(job.id);
  };

  return (
    <table key={job.id} className="__application">
      <IoMdArrowRoundBack onClick={() => goBack()} className="__icon" />

      <tbody>
        <tr>
          <td>{JOB}</td>
          <td>{job.applied}</td>
        </tr>
        <tr>
          <td>{NAME}</td>
          <td>
            {job.salutation === 1 ? MR : MS}
            {" " + job.first_name + " " + job.last_name}
          </td>
        </tr>
        <tr>
          <td>{STREET}</td>
          <td>{job.street}</td>
        </tr>
        <tr>
          <td>{POSTCODE}</td>
          <td>{job.postcode}</td>
        </tr>
        <tr>
          <td>{BIRTHDATE}</td>
          <td>{job.birthdate}</td>
        </tr>

        <tr>
          <td>{PLACE_OF_BIRTH}</td>
          <td>{job.place_of_birth}</td>
        </tr>
        <tr>
          <td>{NATIONALITY}</td>
          <td>{job.nationality}</td>
        </tr>
        <tr>
          <td>{MARITAL_STATUS}</td>
          <td>{job.marital_status}</td>
        </tr>
        <tr>
          <td>{PHONE}</td>
          <td>{job.phone}</td>
        </tr>
        <tr>
          <td>{EMAIL}</td>
          <td>{job.email}</td>
        </tr>
        <tr>
          <td>{OWN_A_CAR}</td>
          <td>{job.own_car ? YES : NO}</td>
        </tr>
        <tr>
          <td>{DRIVING_LICENSE}</td>
          <td>{job.driving_license}</td>
        </tr>
        <tr>
          <td>{CURRENTLY_EMPLOYED}</td>
          <td>{job.is_employed ? YES : NO}</td>
        </tr>
        <tr>
          <td>{PROFESSION}</td>
          <td>{job.profession}</td>
        </tr>
        <tr>
          <td>{SKILLS}</td>
          <td>{job.jobs_learnt}</td>
        </tr>
        <tr>
          <td>{ANNOTAION}</td>
          <td>{job.annotation}</td>
        </tr>
        <Button onClick={remove}> {REMOVE}</Button>
      </tbody>
    </table>
  );
}
