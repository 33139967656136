import React, { useState } from "react";

import {
  addCategory,
  updateCategory,
} from "../../../store/slices/categoriesSlice";
import {
  ADD,
  CANCEL,
  CATEGORY,
  DESCRIPTION,
  NAME,
  SAVE,
} from "../../../../../language";
import TextInput from "../../../../../partials/TextInput";
import Button from "../../../../../partials/Button";
import { useDispatch } from "react-redux";

const NewCategory = React.memo(function ({
  setEditing = () => {},
  update = false,
  category = {},
}) {
  const [name, setName] = useState(category.name || "");
  const [description, setDescription] = useState(category.description || "");
  const [adding, setAdding] = useState(update);

  const dispatch = useDispatch();

  const handleAdd = () => {
    !update
      ? dispatch(addCategory({ category: { name, description } }))
      : dispatch(updateCategory({ id: category.id, name, description }));

    setAdding(false);
    setEditing(false);
  };
  const handleCancle = () => {
    setEditing(false);
    setAdding(false);
  };

  return (
    <>
      {adding && (
        <form onSubmit={handleAdd} className="new-category">
          <fieldset>
            <TextInput
              name={NAME}
              placeholder={NAME}
              setContextValue={(value) => setName(value)}
              initialValue={name}
            />
            <TextInput
              name={DESCRIPTION}
              placeholder={DESCRIPTION}
              setContextValue={(value) => setDescription(value)}
              initialValue={description}
              required={false}
            />
            <div className="__btns">
              <Button type="submit">{SAVE}</Button>
              <Button onClick={handleCancle}>{CANCEL}</Button>
            </div>
          </fieldset>
        </form>
      )}
      {!update && (
        <Button onClick={() => setAdding(true)} disabled={adding}>
          {ADD + " " + CATEGORY}
        </Button>
      )}
    </>
  );
});
export default NewCategory;
