import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { domain } from "../../constants";
import { useAuth, useLoading } from "../../Contexts";
import { deleteRequest, getRequest, postRequest } from "../../utils/http";

const DriversProvider = createContext();
const StaffProvider = createContext();

export default function Context({ children }) {
  const [drivers, setDrivers] = useState([]);
  const [staff, setStaff] = useState([]);

  const { token } = useAuth();
  const { setLoading } = useLoading();
  useEffect(() => {
    getRequest(domain + "users/driver_user/", setLoading, token).then(
      ([data, status]) => {
        if (status === 200) {
          setDrivers(data);
        }
      }
    );
    getRequest(domain + "users/staff_user/", setLoading, token).then(
      ([data, status]) => {
        if (status === 200) {
          setStaff(data);
        }
      }
    );
  }, [token, setLoading]);

  const addDriver = useCallback(
    (driver) => {
      let _driver = {};

      postRequest(
        domain + "users/driver_user/",
        { ...driver },
        setLoading,
        token
      ).then(([data, status]) => {
        if (status === 201) {
          _driver = { ...data };
          setDrivers((prev) => [...prev, { ..._driver }]);
        }
      });
    },
    [token, setLoading]
  );
  const addStaff = useCallback(
    (staff) => {
      let _staff = {};

      postRequest(
        domain + "users/staff_user/",
        { ...staff },
        setLoading,
        token
      ).then(([data, status]) => {
        if (status === 201) {
          _staff = { ...data };
          setStaff((prev) => [...prev, { ..._staff }]);
        }
      });
    },
    [token, setLoading]
  );

  const removeDriver = useCallback(
    (id) => {
      deleteRequest(
        domain + "users/driver_user/" + id + "/",
        setLoading,
        token
      ).then(() => {
        setDrivers((prev) => prev.filter((_driver) => _driver.id !== id));
      });
    },
    [token, setLoading]
  );
  const removeStaff = useCallback(
    (id) => {
      deleteRequest(
        domain + "users/staff_user/" + id + "/",
        setLoading,
        token
      ).then(() => {
        setStaff((prev) => prev.filter((_staff) => _staff.id !== id));
      });
    },
    [token, setLoading]
  );

  return (
    <DriversProvider.Provider value={{ drivers, addDriver, removeDriver }}>
      <StaffProvider.Provider value={{ staff, addStaff, removeStaff }}>
        {children}
      </StaffProvider.Provider>
    </DriversProvider.Provider>
  );
}

export const useDrivers = () => useContext(DriversProvider);
export const useStaff = () => useContext(StaffProvider);
