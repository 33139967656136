import React from "react";
import { useSelector } from "react-redux";
import { MAX_NUMBER_OF_GIFTS, ORDER_GIFTS } from "../../../../language";
import Accordion from "../../../../partials/Accordion";
import TextInput from "../../../../partials/TextInput";
import { useController } from "../../Controller";
import NewOrderGift from "./NewOrderGift";
import OrderGift from "./OrderGift";

export default function OrderGifts() {
  const orderGifts = useSelector((state) => state.orderGifts);
  const { maxNumberOfGifts, setMaxNumberOfGifts } = useController();

  return (
    <div className="__gifts">
      <Accordion title={ORDER_GIFTS}>
        <TextInput
          name={MAX_NUMBER_OF_GIFTS}
          setContextValue={setMaxNumberOfGifts}
          initialValue={maxNumberOfGifts}
          type="number"
          step={1}
        />
        <ul className="__list">
          {orderGifts &&
            orderGifts.map((gift) => (
              <li key={gift.id} className="__item">
                <OrderGift gift={gift} />
              </li>
            ))}
        </ul>
        <NewOrderGift />
      </Accordion>
    </div>
  );
}
