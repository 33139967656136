import { useAuth, useLoading } from "Contexts";
import React, { useCallback, useEffect, useState } from "react";
import { getRequest, patchRequest } from "utils/http";
import {
  BACKGROUND,
  BUTTONS_COLOR,
  DARK,
  LIGHT,
  MENU_BOX_COLOR_1,
  MENU_BOX_COLOR_2,
  OFFERS,
  PRIMARY,
  SECONDARY,
  TEXT,
  THEME_COLORS,
} from "../../language";
import Accordion from "../../partials/Accordion";
import TextInput from "../../partials/TextInput";
import { domain } from "../../constants";
import TopBar from "../../partials/TopBar";

export default function ThemeColors() {
  const [themes, setThemes] = useState([]);

  const { setLoading } = useLoading();

  const { token } = useAuth();

  useEffect(() => {
    getRequest(domain + "menu/theme/", setLoading).then(([data, status]) => {
      if (status === 200) {
        setLoading(false);
        setThemes(data);
      }
    });
    // eslint-disable-next-line
  }, []);

  const getColorValue = useCallback(
    (name) => {
      try {
        return "#" + themes.find((color) => color.name === name).value;
      } catch (err) {
        return "";
      }
    },
    [themes]
  );
  const getColor = useCallback(
    (name) => {
      try {
        return themes.find((color) => color.name === name);
      } catch (err) {
        return "";
      }
    },
    [themes]
  );

  const setColor = useCallback(
    (name, value) => {
      const color = getColor(name);

      patchRequest(
        domain + `menu/theme/${color.id}/`,
        {
          value: value.substring(1),
        },
        setLoading,
        token
      );
    },
    [getColor, token, setLoading]
  );

  return (
    <>
      <TopBar title={THEME_COLORS} />

      <div className="theme-colors">
        <Accordion opened={true} title={THEME_COLORS}>
          <form className="form __body">
            <fieldset>
              <TextInput
                setContextValue={(value) => {
                  setColor("primary", value);
                }}
                initialValue={getColorValue("primary")}
                name={PRIMARY}
                type="color"
              />
              <TextInput
                setContextValue={(value) => {
                  setColor("dark-primary", value);
                }}
                initialValue={getColorValue("dark-primary")}
                name={DARK + " " + PRIMARY}
                type="color"
              />
              <TextInput
                setContextValue={(value) => {
                  setColor("secondary", value);
                }}
                initialValue={getColorValue("secondary")}
                name={SECONDARY}
                type="color"
              />
              <TextInput
                setContextValue={(value) => {
                  setColor("dark-secondary", value);
                }}
                initialValue={getColorValue("dark-secondary")}
                name={DARK + " " + SECONDARY}
                type="color"
              />
              <TextInput
                setContextValue={(value) => {
                  setColor("light-secondary", value);
                }}
                initialValue={getColorValue("light-secondary")}
                name={LIGHT + " " + SECONDARY}
                type="color"
              />
              <TextInput
                setContextValue={(value) => {
                  setColor("offers-background", value);
                }}
                initialValue={getColorValue("offers-background")}
                name={OFFERS + " " + BACKGROUND}
                type="color"
              />

              <TextInput
                setContextValue={(value) => {
                  setColor("light-background", value);
                }}
                initialValue={getColorValue("light-background")}
                name={LIGHT + " " + BACKGROUND}
                type="color"
              />
              <TextInput
                setContextValue={(value) => {
                  setColor("light-text", value);
                }}
                initialValue={getColorValue("light-text")}
                name={LIGHT + " " + TEXT}
                type="color"
              />
              <TextInput
                setContextValue={(value) => {
                  setColor("dark-text", value);
                }}
                initialValue={getColorValue("dark-text")}
                name={DARK + " " + TEXT}
                type="color"
              />
              <TextInput
                setContextValue={(value) => {
                  setColor("buttons-color", value);
                }}
                initialValue={getColorValue("buttons-color")}
                name={BUTTONS_COLOR}
                type="color"
              />
              <TextInput
                setContextValue={(value) => {
                  setColor("category-box-1", value);
                }}
                initialValue={getColorValue("category-box-1")}
                name={MENU_BOX_COLOR_1}
                type="color"
              />
              <TextInput
                setContextValue={(value) => {
                  setColor("category-box-2", value);
                }}
                initialValue={getColorValue("category-box-2")}
                name={MENU_BOX_COLOR_2}
                type="color"
              />
            </fieldset>
          </form>
        </Accordion>
      </div>
    </>
  );
}
