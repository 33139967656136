import React, { useState } from "react";
import { ADD, ZONE } from "../../../language";
import Button from "../../../partials/Button";

import { useEditMode, useFullZones } from "../context";
import NewZone from "./NewZone";
import Sections from "./Sections";
import ZoneHeader from "./ZoneHeader";

export default function Zones() {
  const fullZones = useFullZones();
  const { editMode } = useEditMode();
  const [adding, setAdding] = useState(false);

  return (
    <div className="__zones">
      {fullZones.map((zone) => (
        <div key={zone["id"]} className="__zone">
          <ZoneHeader zone={zone}></ZoneHeader>
          <Sections zone={zone} />
        </div>
      ))}

      {adding && <NewZone setEditing={setAdding}></NewZone>}

      <div className="__add">
        <Button disabled={!editMode || adding} onClick={() => setAdding(true)}>
          {ADD + ' ' + ZONE}
        </Button>
      </div>
    </div>
  );
}
