import React from "react";
import {
  ACCEPT,
  CASH,
  COVID_POLICY,
  DINE_IN,
  DISABLED,
  ENABLED,
  IN,
  PAYPAL,
} from "../../../language";
import Accordion from "../../../partials/Accordion";
import Button from "../../../partials/Button";
import { useDineIn, useEditMode } from "../context";

export default function DineIn() {
  const {
    dineIn,
    setDineIn,
    allowDineInCash,
    setAllowDineInCash,
    allowDineInPaypal,
    setAllowDinePaypal,
    covid_policy,
    setCovid_policy,
  } = useDineIn();
  const { editMode } = useEditMode();

  return (
    <div className="mgb-1">
      <Accordion title={DINE_IN} opened={false}>
        <div className="__dine-in">
          <label>{ACCEPT + " " + DINE_IN + "? "} </label>
          <Button disabled={!editMode} onClick={() => setDineIn(!dineIn)}>
            {dineIn ? ENABLED : DISABLED}
          </Button>
          <label>
            {ACCEPT + " " + CASH + " " + IN + " " + DINE_IN + "? "}{" "}
          </label>
          <Button
            disabled={!editMode}
            onClick={() => setAllowDineInCash(!allowDineInCash)}
          >
            {allowDineInCash ? ENABLED : DISABLED}
          </Button>
          <label>
            {ACCEPT + " " + PAYPAL + " " + IN + " " + DINE_IN + "? "}{" "}
          </label>
          <Button
            disabled={!editMode}
            onClick={() => setAllowDinePaypal(!allowDineInPaypal)}
          >
            {allowDineInPaypal ? ENABLED : DISABLED}
          </Button>
          <label>{COVID_POLICY}</label>
          <Button
            disabled={!editMode}
            onClick={() => setCovid_policy(!covid_policy)}
          >
            {covid_policy ? ENABLED : DISABLED}
          </Button>
        </div>
      </Accordion>
    </div>
  );
}
