import React from "react";
import { useSelector } from "react-redux";
import Accordion from "../../../../partials/Accordion";
import DraggableItem from "../../../../partials/DraggableItem";
import { DND_LINK_ADDON } from "../../constants";
import Addon from "./Addon";
import AddonGroupHeader from "./AddonGroupHeader";

const AddonGroup = React.memo(function ({ group, index }) {
  const { name, id } = group;

  const addons = useSelector((state) =>
    state.addons.filter((addon) => addon.group === id)
  );

  return (
    <DraggableItem type={DND_LINK_ADDON} itemData={group} className="__header">
      <Accordion title={name}>
        <div className="__group chrome-drag-fix">
          <AddonGroupHeader group={group} index={index} />
          {addons.map((addon, index) => (
            <Addon addon={addon} key={index} index={index} />
          ))}
        </div>
      </Accordion>
    </DraggableItem>
  );
});
export default AddonGroup;
