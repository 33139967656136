import { Button } from "antd";
import React, { useState } from "react";
import { SHOW_ACTIVE_USERS, SHOW_DELETED_USERS, USERS } from "../../language";
import TopBar from "../../partials/TopBar";
import DeletedUsersTable from "./components/DeletedUsersTable";
import UsersTable from "./components/UsersTable";

export default function Users() {
  const [refreshData, setRefreshData] = useState(0);
  const [showTable, setShowTable] = useState(1);
  return (
    <div className="users">
      <TopBar title={USERS}></TopBar>
      <Button onClick={() => setShowTable(1)}>{SHOW_ACTIVE_USERS}</Button>
      <Button onClick={() => setShowTable(2)}>{SHOW_DELETED_USERS}</Button>
      {showTable === 1 && (
        <UsersTable refreshData={refreshData} setRefreshData={setRefreshData} />
      )}
      {showTable === 2 && (
        <DeletedUsersTable
          refreshData={refreshData}
          setRefreshData={setRefreshData}
        />
      )}
    </div>
  );
}
