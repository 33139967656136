export const domain =
  process.env.NODE_ENV === "production"
    ? window.location.origin.replace('dashboard.' , '') + "/api/"
    : process.env.REACT_APP_API;


export const host =
  process.env.NODE_ENV === "production"
    ? window.location.origin.replace('dashboard.' , '') 
    : process.env.REACT_APP_HOST;


