import { JOBS } from "language";
import TopBar from "partials/TopBar";
import React from "react";
import AvailableJobs from "./components/AvailableJobs";
import JobApplications from "./components/JobApplications";
import Context, { useJobs } from "./Context";

function Index() {
  const { jobs } = useJobs();
  return (
    <>
      <TopBar title={JOBS}></TopBar>
      <div className="jobs  animate__animated animate__fadeIn">
        <AvailableJobs />

        {jobs && jobs.length && <JobApplications />}
      </div>
    </>
  );
}
export default function Jobs() {
  return (
    <Context>
      <Index />
    </Context>
  );
}
