export const BASIC_INFROMATION = "Grundinformation";
export const BASICS = "Grundlagen";
export const ACCOUNT = "Konto";
export const EDIT = "Bearbeiten";
export const SAVE = "Speichern";
export const CANCEL = "abbrechen";
export const RESTAURANT_NAME = "Restaurantname";
export const NAME = "Name";
export const SLOGAN = "Slogan";
export const PHONE_NUMBER = "Telefonnummer";
export const ADDITIONAL = "Zusätzlich";
export const COUNTRY = "Hauptstadt";
export const CITY = "STADT";
export const TIMEZONE = "Zeitzone";
export const STREET = "Straße";
export const BUILDING_NUMBER = "Hausnummer";
export const ZIP_CODE = "Postleitzahl";
export const RESTAURANT = "Restaurant";
export const RESTAURANTS = "Restaurants";
export const CUISINE = "Küche";
export const CUISINES = "Küchen";
export const SELECTED = "Ausgewählt";
export const CUISINES_MESSAGE =
  "Ziehen Sie Küchen, um sie zu Ihrem Restaurant hinzuzufügen ... ...";
export const WEBSITE = "Webseite";
export const SETTINGS = "Einstellungen";
export const ADD = "Hinzufügen";
export const REMOVE = "Entfernen";
export const LOGO = "Logo";
export const IMAGE = "Bild";
export const IMAGES = "Bilder";
export const COVER = "Cover";
export const GROUP = "Gruppe";
export const GROUPS = "Gruppen";
export const ADDONS = "Extras";
export const ADDON = "Extra";
export const FOOD = "Essen";
export const MENU = "Speisekarte";
export const CATEGORY = "Kategorie";
export const CATEGORIES = "Kategorien";
export const MEAL = "Mahlzeit";
export const MEALS = "Mahlzeit";
export const SIZE = "Größe";
export const SIZES = "Größen";
export const EMPTY_MENU_MESSAGE =
  "Ihr Menü ist leer. Beginnen Sie mit dem Hinzufügen von Elementen ...";
export const DESCRIPTION = "Beschreibung";
export const PRICE = "Preis";
export const FROM = "von";
export const TO = "bis";
export const CURRENCY = "EUR";
export const OPTIONAL = "Optional";
export const MANDATORY = "Verpflichtend";
export const ALLOW_MULTIPLE_CHOICES =
  "Mehrfaches Hinzufügen derselben Auswahl zulassen";
export const FORCE_MIN = "Kraftminimum";
export const FORCE_MAX = "Maximal erzwingen (0 bedeutet keine Begrenzung)";
export const HIDE = "Ausblenden";
export const HIDDEN = "Ausblenden";
export const SERVICES = "Services";
export const ENABLE = "Ermöglichen";
export const ENABLED = "Ermöglichert";
export const DISABLE = "Deaktivieren";
export const DISABLED = "Deaktiviert";
export const ACCEPT = "Annehmen";
export const PICKUP = "Abholen";
export const DELIVERY = "Liefern";
export const FREE_MIN_AMOUNT = "Mindestbetrag für kostenlose Lieferung";
export const ZONE = "Zone";
export const ZONES = "Zones";
export const SECTION = "Gebiet";
export const SECTIONS = "Gebiete";
export const WORK = "Arbeit";
export const HOURS = "Std";
export const WORK_HOURS_TITLE = "Wann haben Sie auf?";
export const DIFFERENET_TIMES_FOR_DELIVERY =
  "Set abweichende Öffnungszeiten für Abholung und Lieferung";
export const WEEKDAYS = {
  1: "Montag",
  2: "Dienstag",
  3: "Mittwoch",
  4: "Donnerstag ",
  5: "Freitag",
  6: "Samstag",
  7: "Sonntag",
};
export const WEEKDAYS_2 = {
  Montag: 1,
  Dienstag: 2,
  Mittwoch: 3,
  Donnerstag: 4,
  Freitag: 5,
  Samstag: 6,
  Sonntag: 7,
};
export const WEEKDAYS_NAMES = [
  "Montag",
  "Dienstag",
  "Mittwoch",
  "Donnerstag",
  "Freitag",
  "Samstag",
  "Sonntag",
];

export const WEEKDAYS_EXTENDED = {
  1: "Montag",
  2: "Dienstag",
  3: "Mittwoch",
  4: "Donnerstag",
  5: "Freitag",
  6: "Samstag",
  7: "Sonntag",
  8: "Alle",
};
export const WEEKDAYS_EXTENDED_2 = {
  Montag: 1,
  Dienstag: 2,
  Mittwoch: 3,
  Donnerstag: 4,
  Freitag: 5,
  Samstag: 6,
  Sonntag: 7,
  Alle: 8,
};
export const WEEKDAYS_EXTENDED_NAMES = [
  "Montag",
  "Dienstag",
  "Mittwoch",
  "Donnerstag",
  "Freitag",
  "Samstag",
  "Sonntag",
  "Alle",
];

export const LOGIN_TITLE = "Restaurant-Dashboard-Anmeldung";
export const LOGIN_ERROR_MESSAGE_1 =
  "Falscher Benutzername oder falsches Passwort";
export const LOGIN_ERROR_MESSAGE_2 = "Etwas ist schief gelaufen";
export const REMEMBER_ME = "Angemeldet bleiben";
export const USERNAME = "Nutzername";
export const PASSWORD = "Passwort";
export const LOGIN = "Einloggen";

export const ERROR_MESSAGE_1 =
  "Konnte nicht speichern, da ist etwas schief gelaufen";
export const SAVED_MESSGAE = "Änderungen gespeichert.";
export const SAVING_MESSGAE = "Saving ... ";
export const PHONE_ERROR_MESSAGE = "Telefonnummern müssen in E.164 sein von";
export const LOADING = "Laden ...";
export const LOGOUT = "Abmelden";
export const ARE_YOU_SURE = "Möchten Sie dies wirklich löschen?";
export const MIN_ORDER_PRICE = "Mindestbestellpreis";
export const PRIVACY_POLICY = "Datenschutz";
export const TAX_MESSAGE = "Steuernachricht";
export const USER_AGREEMENT = " AGB";
export const IMPRINT = "Impressum";
export const RETURN_POLICY = "Widerrufsrecht";

export const WEEKDAY = "Wochentag";
export const OPENING = "Öffnung";
export const OPENED = "Geöffnet";
export const CHANGE_ADMIN_PASSWORD = "Admin-Passwort ändern";
export const OLD = "Alt";
export const NEW = "Neu";
export const FOOD_MENU = "Speisekarte";

export const LIMIT = "Grenze";
export const EMAIL = "Email";
export const PHONE = "Telefon";
export const ADDRESS = "Adresse";
export const AREA = "Liefren nach";
export const BUILDING_NO = "Hausnummer.";

export const ORDERS = "Bestellungen";
export const ORDER = "Bestellung";
export const QUANTITY = "Menge";
export const CASH = "Bar";
export const PAYPAL = "Paypal";
export const PAYPAL_API_KEY = "Paypal Client Key";
export const PAYPAL_SECRET = "Paypal Secret";
export const PAYMENT = "Zahlung";
export const CREATED_AT = "Hergestellt in";
export const ACCEPTED_AT = "Akzeptiert bei";
export const TYPE = "Typ";
export const PENDING = "Ausstehend";
export const REJECTED = "Abgelehnt";
export const ACCEPTED = "Akzeptiert";
export const PENDING_DELIVERING = "Unterwegs";
export const PENDING_PICKUP = "Bereit zum Abholen";
export const DELIVERING = "Mit Fahrer Unterwegs";
export const DELIVERED = "Geliefert";
export const DONE = "Fertig";
export const CANCELLED = "Abgesagt";
export const STATUS = "Status";
export const WORK_HOURS = "Arbeitszeiten";
export const SEO = "SEO";
export const RELOAD = "Live-Nachladen";
export const SLUG = "Code";
export const DAILY_COUNT = "Bestellung ID";

export const OFFERS = "Angebote";
export const OFFER = "Angebot";
export const DAILY_OFFERS = "Tagesangebot";
export const PERCENTAGE = "Prozent";
export const FIXED = "Fest";
export const VALUE = "Wert";
export const ID = "Artikel Identifikationsnummer";
export const ON_A = "gilt für";
export const ON = "gilt für";
export const OFFER_ERROR_1 = "Die ID existiert nicht";
export const TITLE = "Title";
export const ALL = "Alle";
export const PREORDER = "Vorbestellung";
export const MAX_PREORDER_DAYS =
  "Maximale Anzahl der Tage, an denen die Vorbestellung erfolgen kann";
export const OR_CHOOSE_A_RANGE = "Oder wählen Sie einen Bereich";

export const ORDER_TOTAL_OFFERS = "Bestellung Gesamtangebote";
export const BANNER_OFFERS = "Banner-Angebote";
export const THIS_IS_AN_OFFER_CATEGORY =
  "Dies wird als Angebotskategorie gekennzeichnet ";
export const THIS_IS_AN_OFFER_MEAL =
  "Dies wird als Angebotsessen gekennzeichnet ";
export const DOMAIN = "Domain";
export const EMAIL_CONFIG = "E-Mail-Konfiguration";
export const EMAIL_USERNAME = "E-Mail-Benutzername";
export const EMAIL_PASSOWRD = "E-Mail-Kennwort";
export const EMAIL_TLS = "Email tls";
export const EMAIL_HOST = "Email Host";
export const EMAIL_PORT = "Email Port";
export const USERS = "BenutzerInnen";
export const ALLOW_ANON = "Nur registrierten Benutzern erlauben zu bestellen";
export const OTHERS = "Andere";
export const FIRST_ORDER_DISCOUNT = "Rabatt für Erstbestellung";
export const POINTS = "Punkte";
export const POINTS_CALC = "Berechnung der Punkte (diese Zahl pro 100 Euro)";
export const OTHER_OFFERS = "Weitere Angebote";
export const DRIVERS = "Fahrer";
export const DRIVER = "Fahrer";
export const STAFF = "Personal";
export const FIRST_NAME = "Vornam";
export const LAST_NAME = "Nachname";
export const TABLES = "Tische";
export const TABLE = "Tisch";
export const TABLE_NUMBER = "Tischnummer";
export const TABLE_NAME = "Tabelle Bezeichnung";
export const PASSCODE = "Passcode";
export const DINE_IN = "Essen im Restaurant";
export const SHOW_QR_CODE = "QR-Code anzeigen";
export const HIDE_QR_CODE = "QR-Code ausblenden";
export const DOWNLOAD_QR_CODE = "QR-Code herunterladen";
export const IN = "In";
export const NOT_REQUIRED = "(Optional)";
export const BLOCK = "Block";
export const BLOCKED = "Blockiert";
export const UNBLOCK = "freischalten";
export const DEDUCT_POINTS = "Punkte abziehen";
export const HOW_MUCH_POINTS = "Wie viele Punkte möchten Sie abziehen?";
export const USER_POINTS = "Benutzerpunkte";
export const ORDER_GIFTS = "Bestellung-Geschenk";
export const ORDER_GIFT = "Bestellung-Geschenke";
export const GIFT = "Geschenk";
export const MAX_ORDER_PRICE = "Maximaler Bestellwert";
export const MAX_ORDER_PRICE_ERROR =
  "Der maximale Auftragspreis muss höher sein als der Mindestpreis oder Null";
export const ZERO_MEANS_UNLIMITED = "(Null bedeutet unbegrenzt)";
export const MAX_NUMBER_OF_GIFTS = "Maximal zulässige Anzahl von Geschenken";
export const PRICE_BEFORE_DISCOUNTS = "Preis vor Rabatt";
export const USE_TQO_SCHEDULES = "Zwei Öffnungszeiten verwenden";
export const CLOSE_FOR_EMERGENCY =
  "Notfall Schließen (bei Urlaube - bei außergewöhnlichen Schließungen)";
export const CLOSE_FOR_EMERGENCY_MSG = "Notfall-Schließnachricht";
export const SOCIAL_LINKS = "Soziale Links";
export const FACEBOOK = "Facebook";
export const YOUTUBE = "Youtube";
export const INSTAGRAM = "Instagram";
export const TIKTOK = "Tiktok";
export const GOOGLE_PLAY = "Google Play";
export const APP_STORE = "App Store";
export const TOTAL_PRICE = "Gesamtpreis";
export const DISCOUNT_AMOUNT = "Rabatt";
export const REVIEWS = "Bewertungen";
export const REVIEW_BODY = "Die Bewertung";
export const REVIEW_STATS = "Ihr Restautant Bewertungen";
export const REVIEW_FOOD = "Essen";
export const REVIEW_ONLINE = "Online Service";
export const REVIEW_DELIVERY = "Lieferung";
export const ONLINE_STARS = "das Online bestellen";
export const FOOD_STARS = "Bewertung des Essens";
export const DELIVERY_STARS = "Bewertung der Lieferung";
export const SHOW_REVIEW = "Auf der Website anzeigen";
export const HIDE_REVIEW = "Von der Website ausblenden";

export const COUPON_CODE_ERROR = "Gutschein existiert bereits";
export const COUPON_DATE_ERROR = "Gutschein Datum erforderlich";
export const COUPONS = "Gutschein";
export const COUPON = "Gutschein";
export const CODE = "Code";
export const REMAINING_USERS = "Wie viel darf verwendet werden?";
export const REDEEM_ONCE_PER_USER = "Einmal pro Benutzer einlösen";
export const COUPON_ALLOW_ANON = "Keine Nutzer einlösen lassen";
export const YES = "Ja";
export const NO = "Nein";

export const THEME_COLORS = "Thema Farben";
export const PRIMARY = "Primary";
export const SECONDARY = "Secondary";
export const DARK = "Dark";
export const LIGHT = "Light";
export const TEXT = "Text";
export const BACKGROUND = "Background";

export const TABLE_RESERVATIONS = "Reservierungen";
export const SEE_RESERVATIONS_HERE = "Alle Tischreservierungen hier ansehen";
export const RESERVATION_DATE = "Datum der Reservierung";
export const PERSONS = "Personen";
export const PREORDER_DATE = "Datum der Vorbestellung";

export const PAYPAL_AMOUNT = "Paypal Received Amount";
export const PAYPAL_NOT_PAYED = "NICHT BEZAHLT";
export const SHOW_PAYPAL_PENDING_ORDERS =
  "Unvollständige Bestellungen von PayPal";
export const HIDE_PAYPAL_PENDING_ORDERS = "Alle Bestellungen";
export const JOBS = "Stellenangebote";
export const AVAILBLE_JOBS = "Verfügbare Stellen";
export const JOB = "Job";
export const JOB_APPLICATIONS = "Stellenbewerbung";
export const JOB_TITLE = "Titel der Stelle";

export const MR = "Herr";
export const MS = "Frau";
export const POSTCODE = "Postleitzahl";
export const BIRTHDATE = "Geburtsdatum";
export const PLACE_OF_BIRTH = "Geburtsort";
export const MARITAL_STATUS = "Familienstand";
export const NATIONALITY = "Staatsangehörigkeit";
export const OWN_A_CAR = "Eigenes Auto";
export const PROFESSION = "Beruf";
export const ANNOTAION = "Zusätzliche Hinweise";
export const DRIVING_LICENSE = "Die Fahrerlaubnis";
export const CURRENTLY_EMPLOYED = "Derzeit beschäftigt";
export const SKILLS = "Fähigkeiten";

export const SEO_PAGES = "SEO-SEITEN";
export const PARAGRAPH = "Abschnitt";
export const ADDTIONAL_INFO = "Zusätzliche Informationen";
export const PROMO_VIDEO = "Promo-Video";
export const COVID_POLICY = "Covid-Politik";
export const CASH_TOTAL = "Bar-Gesamtpreis";
export const PAYPAL_TOTAL = "Online-Gesamtpreis ";
export const CANCELLED_ORDERS = "Stornierte Bestellungen ";

export const MAX_PEOPLE_NUM = "Maximale Personenzahl";
export const MAX_DAYS_TABLE = "Maximale Anzahl von Tagen, um einen Tisch zu reservieren";
export const SHOW_BANNER_OFFER_MENU = "Angebot im Menü anzeigen";
export const SHOW_BANNER_OFFER_CHECKOUT = "Angebot an der Kasse anzeigen";
export const SHOW_BANNER_OFFER_HOME = "Angebot auf Startbildschirm anzeigen";
export const SHOW_ACTIVE_USERS = "Aktive Benutzer anzeigen";
export const SHOW_DELETED_USERS = "Gelöschte Benutzer anzeigen";
export const ALLOW_CREATING_USERS = "Erstellen von Benutzerkonten zulassen";
export const ALLOW_JOBS = "Stellenbewerbungen zulassen";
export const ALLOW_REVIEWS = "Bewertungen zulassen";
export const MAINTENANCE_MODE = "Wartungsmodus";
export const MAINTENANCE_MODE_MSG = "Meldung Wartungsmodus";
export const GOOGLE_ANALYTICS_SCRIPT = "Google Analytics-Skript";
export const GOOGLE_ANALYTICS_SCRIPT_SRC = "Google Analytics Script src";
export const BUTTONS_COLOR = "Tasten";
export const MENU_BOX_COLOR_1 = "Menüfeld #1";
export const MENU_BOX_COLOR_2 = "Menüfeld #2";
export const IMPORT = "Importieren";
export const EXPORT = "Export";
export const IMPORT_SUCCESS = "Import abgeschlossen! Bitte aktualisieren Sie die Seite";
export const IMPORT_FAILED = "Importieren fehlgeschlagen";
export const IMPORT_INVALID = "Bitte geben Sie gültige Importdaten an";
export const IMPORT_PLACEHOLDER =
  "Kopieren Sie hier den Inhalt aus Ihrer Sicherungsdatei!";
export const IMPORT_WARNING =
  "Sind Sie sicher, dass Sie importieren möchten? das aktuelle Menü geht verloren und Bilder werden nicht importiert";
export const NOTIFICATION_EMAILS = "Benachrichtigungs-E-Mails";
export const NOTIFICATION_EMAIL1 = "Benachrichtigungs-E-Mail 1";
export const NOTIFICATION_EMAIL2 = "Benachrichtigungs-E-Mail 2";
export const NOTIFICATION_EMAIL_MESSAGE = "Geben Sie E-Mail-Adressen ein, an die Sie eine Erinnerung für neue Bestellungen erhalten möchten";
export const APP_NOTIFICATIONS = 'Push Nachricht an die App'
export const APP_NOTIFICATIONS_CODE = 'Push Nachricht mit Gutschein an die App'
export const EMAILS_MARKETING = 'E-Mails & Marketing'
export const SEND = 'Schicken'
export const NOTIFICATION_SUCCESS = 'Benachrichtigung gesendet'
export const NOTIFICATION_FAIL = 'Benachrichtigung nicht gesendet '
