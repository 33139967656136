import { domain } from "../../../constants";
import {
  APP_NOTIFICATIONS,
  APP_NOTIFICATIONS_CODE,
  CODE,
  DESCRIPTION,
  NOTIFICATION_FAIL,
  NOTIFICATION_SUCCESS,
  SEND,
  TITLE,
} from "language";
import Accordion from "partials/Accordion";
import Button from "partials/Button";
import TextInput from "partials/TextInput";
import React, { useState } from "react";
import { postRequest } from "utils/http";
import { useAuth } from "Contexts";

export default function AppNotifications() {
  const [title, setTitle] = useState("");
  const [msg, setMsg] = useState("");
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [withCode, setWithCode] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const { token } = useAuth();
  const sendNotification = () => {
    if (!msg || !title) return;
    if (withCode && !code) return;

    const endpoint = withCode
      ? "settings/send_coupon_notification/"
      : "settings/send_notification/";
    const body = {
      title,
      msg,
      code,
    };
    postRequest(domain + endpoint, body, setLoading, token).then(
      ([data, status]) => {
        if (status === 200) {
          setTitle("");
          setMsg("");
          setCode("");
          setResponseMessage(NOTIFICATION_SUCCESS);
        } else {
          setResponseMessage(NOTIFICATION_FAIL);
        }
      }
    );
  };

  return (
    <Accordion title={APP_NOTIFICATIONS} opened={true}>
      <div className="__form">
        <Button onClick={() => setWithCode(!withCode)}>
          {withCode ? APP_NOTIFICATIONS : APP_NOTIFICATIONS_CODE}
        </Button>
        <br></br>
        <TextInput
          name={TITLE}
          setContextValue={setTitle}
          initialValue={title}
          required
          type="text"
        />
        <TextInput
          name={DESCRIPTION}
          setContextValue={setMsg}
          initialValue={msg}
          required
          type="text"
        />

        {withCode && (
          <TextInput
            name={CODE}
            setContextValue={setCode}
            initialValue={code}
            required
            type="text"
          />
        )}

        <Button disabled={loading} onClick={() => sendNotification()}>
          {SEND}
        </Button>

        <p style={{fontWeight : 'bold' , marginTop : '1rem' , fontSize : '1.3rem'}}>{responseMessage}</p>
      </div>
    </Accordion>
  );
}
