import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

export const bannerOffersSlice = createSlice({
  name: "bannerOffers",
  initialState: [],
  reducers: {
    setBannerOffers: (state, { payload }) => {
      return [...payload];
    },
    addBannerOffer: (state, { payload }) => {
      state.push({
        ...payload.bannerOffer,
        id: uuidv4(),
      });
    },
    removeBannerOffer: (state, { payload }) => {
      return state.filter((bannerOffers) => payload.id !== bannerOffers.id);
    },
    updateBannerOffer: (state, { payload }) => {
      const bannerOffers = state.find(
        (_bannerOffers) => _bannerOffers.id === payload.id
      );
      bannerOffers.title = payload.title;
      bannerOffers.description = payload.description;
      bannerOffers.image = payload.image;
      bannerOffers.show_menu = payload.show_menu;
      bannerOffers.show_home_screen = payload.show_home_screen;
      bannerOffers.show_checkout = payload.show_checkout;
    },
  },
});

export default bannerOffersSlice.reducer;

export const {
  setBannerOffers,
  addBannerOffer,
  removeBannerOffer,
  updateBannerOffer,
} = bannerOffersSlice.actions;
