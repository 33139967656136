import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ARE_YOU_SURE, DESCRIPTION, IMAGE, MIN_ORDER_PRICE, TITLE, VALUE } from "../../../../language";
import Controls from "../../../../partials/Controls";
import { removeOrderTotalOffer } from "../../store/slices/OrderTotalOfferSlice";
import NewOrderTotalOffer from "./NewOrderTotalOffer";

export default function OrderTotalOffer({ offer }) {
  const [editing, setEditing] = useState(false);
  const dispatch = useDispatch();

  const remove = () => {
    if (!window.confirm(ARE_YOU_SURE)) return;
    dispatch(removeOrderTotalOffer({ id: offer.id }));
  };

  return (
    <>
      {!editing && <Controls remove={remove} edit={() => setEditing(true)} />}
      {editing ? (
        <NewOrderTotalOffer offer={offer} setEditing={setEditing} update={true} />
      ) : (
        <div>
          <table className="__table">
            <tbody>
              <tr>
                <td>{MIN_ORDER_PRICE}</td>
                <td>{+offer.min_price}</td>
              </tr>

              <tr>
                <td>{VALUE}</td>
                <td>{offer.value}</td>
              </tr>
              <tr>
                <td>{TITLE}</td>
                <td>{offer.title}</td>
              </tr>
              <tr>
                <td>{DESCRIPTION}</td>
                <td>{offer.description}</td>
              </tr>
              <tr>
                <td>{IMAGE}</td>
                <td>
                  <img src={offer.image} alt=""></img>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}
