import {
  NOTIFICATION_EMAIL1,
  NOTIFICATION_EMAIL2,
  NOTIFICATION_EMAILS,
  NOTIFICATION_EMAIL_MESSAGE,
} from "language";
import Accordion from "partials/Accordion";
import TextInput from "partials/TextInput";
import React from "react";


import { useEmailConfig } from "../context";
export default function EmailNotifications({ editMode }) {
  const { adminEmail, adminEmail2, setAdminEmail, setAdminEmail2 } =
    useEmailConfig();
  return (
    <Accordion title={NOTIFICATION_EMAILS} opened={true}>
      <div className="__form">
        <p className="mb-1"> {NOTIFICATION_EMAIL_MESSAGE} </p>
        <TextInput
          name={NOTIFICATION_EMAIL1}
          setContextValue={setAdminEmail}
          initialValue={adminEmail}
          disabled={!editMode}
          type="text"
        />
        <TextInput
          name={NOTIFICATION_EMAIL2}
          setContextValue={setAdminEmail2}
          initialValue={adminEmail2}
          disabled={!editMode}
          step={1}
          type="text"
        />
      </div>
    </Accordion>
  );
}
