import React from "react";
import Context from "./Context";
import TopBar from "../../partials/TopBar";
import { REVIEWS } from "../../language";
import ReviewsTable from "./components/ReviewsTable";

export default function Reviews() {
  return (
    <Context>
      <TopBar title={REVIEWS} />
      <div className="reviews  animate__animated animate__fadeIn">
          <ReviewsTable />
      </div>
    </Context>
  );
}
