import {
  ARE_YOU_SURE,
  CODE,
  COUPON_ALLOW_ANON,
  FIXED,
  FROM,
  MIN_ORDER_PRICE,
  NO,
  PERCENTAGE,
  REDEEM_ONCE_PER_USER,
  REMAINING_USERS,
  TO,
  TYPE,
  VALUE,
  YES,
} from "language";
import Controls from "partials/Controls";
import React from "react";
import { useDispatch } from "react-redux";
import { removeCoupon } from "routes/Offers/store/slices/CouponsSlice";

export default function Coupon({ coupon }) {
  const dispatch = useDispatch();

  const remove = () => {
    if (!window.confirm(ARE_YOU_SURE)) return;
    dispatch(removeCoupon({ id: coupon.id }));
  };

  return (
    <div>
      <Controls remove={remove} />
      <table className="__table">
        <tbody>
          <tr>
            <td>{CODE}</td>
            <td>{coupon.code}</td>
          </tr>
          <tr>
            <td>{FROM}</td>
            <td>{new Date(coupon.start).toLocaleString()}</td>
          </tr>
          <tr>
            <td>{TO}</td>
            <td>{new Date(coupon.end).toLocaleString()}</td>
          </tr>
          <tr>
            <td>{REMAINING_USERS}</td>
            <td>{coupon.remaining_uses}</td>
          </tr>
          <tr>
            <td>{COUPON_ALLOW_ANON}</td>
            <td>{coupon.allow_anon ? YES : NO}</td>
          </tr>
          <tr>
            <td>{MIN_ORDER_PRICE}</td>
            <td>{coupon.min_order_price}</td>
          </tr>

          <tr>
            <td>{TYPE}</td>
            <td>{+coupon.type === 1 ? FIXED : PERCENTAGE}</td>
          </tr>

          <tr>
            <td>{VALUE}</td>
            <td>{coupon.value}</td>
          </tr>
          <tr>
            <td>{REDEEM_ONCE_PER_USER}</td>
            <td>{coupon.redeem_once_per_user ? YES : NO}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
