import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  ARE_YOU_SURE,
  GIFT,
  IMAGE,
  MAX_ORDER_PRICE,
  MIN_ORDER_PRICE,
} from "../../../../language";
import Controls from "../../../../partials/Controls";
import { removeOrderGift } from "../../store/slices/OrderGiftsSlice";
import NewOrderGift from "./NewOrderGift";

export default function OrderGift({ gift }) {
  const [editing, setEditing] = useState(false);
  const dispatch = useDispatch();

  const remove = () => {
    if (!window.confirm(ARE_YOU_SURE)) return;
    dispatch(removeOrderGift({ id: gift.id }));
  };

  return (
    <>
      {!editing && <Controls remove={remove} edit={() => setEditing(true)} />}
      {editing ? (
        <NewOrderGift setEditing={setEditing} update={true} gift={gift} />
      ) : (
        <div>
          <table className="__table">
            <tbody>
              <tr>
                <td>{GIFT}</td>
                <td>{gift.description}</td>
              </tr>
              <tr>
                <td>{MIN_ORDER_PRICE}</td>
                <td>{gift.min_price}</td>
              </tr>
              <tr>
                <td>{MAX_ORDER_PRICE}</td>
                <td>{gift.max_price}</td>
              </tr>
              <tr>
                <td>{IMAGE}</td>
                <td>
                  <img src={gift.image} alt=""></img>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}
