import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCoupon } from "routes/Offers/store/slices/CouponsSlice";
import {
  ADD,
  CANCEL,
  CODE,
  COUPON_ALLOW_ANON,
  COUPON_CODE_ERROR,
  COUPON_DATE_ERROR,
  FIXED,
  MIN_ORDER_PRICE,
  PERCENTAGE,
  REDEEM_ONCE_PER_USER,
  REMAINING_USERS,
  SAVE,
  TYPE,
  VALUE,
} from "../../../../language";
import Button from "../../../../partials/Button";
import TextInput from "../../../../partials/TextInput";
import SelectInput from "../../../../partials/SelectInput";
import { DatePicker } from "antd";

const { RangePicker } = DatePicker;
export default function NewCoupon() {
  const [adding, setAdding] = useState(false);

  const dispatch = useDispatch();

  const [code, setCode] = useState("");
  const [minOrderPrice, setMinOrderPrice] = useState(0);
  const [value, setValue] = useState(0);
  const [type, setType] = useState(1);
  const [remainingUses, setRemainingUses] = useState(0);
  const [allowAnon, setAllowAnon] = useState(false);
  const [redeemOncePerUser, setRedeemOncePerUser] = useState(false);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [error, setError] = useState("");

  const coupons = useSelector((state) => state.coupons);

  const handleCancle = () => {
    setAdding(false);
  };

  const handleAdd = (e) => {
    e.preventDefault();

    const findByCoupon = coupons.find((coupon) => coupon.code === code);
    if (findByCoupon) {
      setError(COUPON_CODE_ERROR);
      return;
    }
    if (!start || !end) {
      setError(COUPON_DATE_ERROR);
      return;
    }
    setError("");

    const couponBody = {
      code,
      allow_anon: allowAnon,
      min_order_price: minOrderPrice,
      value,
      type,
      start,
      end,
      remaining_uses: remainingUses,
      redeem_once_per_user: redeemOncePerUser,
    };

    dispatch(
      addCoupon({
        coupon: couponBody,
      })
    );

    setAdding(false);
  };

  return (
    <>
      {adding && (
        <form onSubmit={handleAdd} className="__new form">
          <TextInput
            name={CODE}
            initialValue={code}
            setContextValue={setCode}
            required={true}
          />
          <RangePicker
            required={true}
            className="__range"
            showTime
            name={"range"}
            value={[start, end]}
            format="YYYY-MM-DD HH:mm"
            onChange={(value) => {
              if (value) {
                setStart(value[0]);
                setEnd(value[1]);
              } else {
                setStart(null);
                setEnd(null);
              }
            }}
          />
          <SelectInput
            name={TYPE}
            options={[FIXED, PERCENTAGE]}
            setContextValue={(value) => {
              setType(value === FIXED ? 1 : 2);
            }}
            initialValue={type === 1 ? FIXED : PERCENTAGE}
            required={true}
          />
          <TextInput
            name={VALUE}
            initialValue={value}
            setContextValue={setValue}
            required={true}
            type="number"
          />
          <TextInput
            name={MIN_ORDER_PRICE}
            initialValue={minOrderPrice}
            setContextValue={setMinOrderPrice}
            required={true}
            type="number"
          />
          <TextInput
            name={REMAINING_USERS}
            initialValue={remainingUses}
            setContextValue={setRemainingUses}
            required={true}
            type="number"
            step={1}
          />

          <div className="__checkboxes">
            <div>
              <label htmlFor="allowanon">{COUPON_ALLOW_ANON + " "}</label>
              <input
                id="allowanon"
                checked={allowAnon}
                onChange={() => setAllowAnon(!allowAnon)}
                type="checkbox"
              ></input>
            </div>

            <div>
              <label htmlFor="redeemonce">{REDEEM_ONCE_PER_USER + " "}</label>
              <input
                id="redeemonce"
                checked={redeemOncePerUser}
                onChange={() => setRedeemOncePerUser(!redeemOncePerUser)}
                type="checkbox"
              ></input>
            </div>
          </div>

          <div className="error">{error}</div>

          <div className="__btns">
            <Button type="submit">{SAVE}</Button>
            <Button onClick={handleCancle}>{CANCEL}</Button>
          </div>
        </form>
      )}

      <Button onClick={() => setAdding(true)} disabled={adding}>
        {ADD}
      </Button>
    </>
  );
}
