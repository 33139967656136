import { Rate } from "antd";
import {
  REVIEW_DELIVERY,
  REVIEW_FOOD,
  REVIEW_ONLINE,
  REVIEW_STATS,
} from "language";
import React from "react";
import { useReviews } from "../Context";

export default function ReviewStats() {
  const { reviewsAvg } = useReviews();
  return (
    <div className="__stats">
      <h1>{REVIEW_STATS}</h1>
      <div className="__stat">
        <span className="__lable">{REVIEW_FOOD}</span>
        <Rate disabled value={reviewsAvg.food_stars__avg} />
        {parseFloat(reviewsAvg.food_stars__avg).toPrecision(2)}
      </div>
      <div className="__stat">
        <span className="__lable">{REVIEW_DELIVERY}</span>
        <Rate disabled value={reviewsAvg.delivery_stars__avg} />
        {parseFloat(reviewsAvg.delivery_stars__avg).toPrecision(2)}
      </div>
      <div className="__stat">
        <span className="__lable">{REVIEW_ONLINE}</span>
        <Rate disabled value={reviewsAvg.online_stars__avg} />
        {parseFloat(reviewsAvg.online_stars__avg).toPrecision(2)}
      </div>
    </div>
  );
}
