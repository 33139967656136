export const ACTION_ADD = "ADD";
export const ACTION_REMOVE = "REMOVE";
export const ACTION_UPDATE = "UPDATE";
export const ACTION_SET = "SET";



export const ENDPOINTS = {

    ZONE :  "settings/zone/",
    SECTION :  "settings/section/",
}