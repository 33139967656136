import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ARE_YOU_SURE } from "../../../../language";
import Controls from "../../../../partials/Controls";
import { removeAddon } from "../../store/slices/addonsSlice";
import NewAddon from "./NewAddon";

const Addon = React.memo(function ({ addon }) {
  const [editing, setEditing] = useState(false);

  const dispatch = useDispatch();
  const remove = () => {
    if (!window.confirm(ARE_YOU_SURE)) return;
    dispatch(
      removeAddon({
        id: addon.id,
      })
    );
  };

  return (
    <div className="__addon">
      {!editing && (
        <>
          <span className="__name">{addon.name}</span>
          <span className="__price">{addon.price + " EUR"}</span>
        </>
      )}
      {!editing && <Controls remove={remove} edit={() => setEditing(true)} />}
      {editing && (
        <NewAddon update={true} addon={addon} setAdding={setEditing} />
      )}
    </div>
  );
});
export default Addon;
