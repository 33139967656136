import React from "react";
import Accordion from "../../../partials/Accordion";
import TextInput from "../../../partials/TextInput";
import InputGroup from "../../../partials/InputGroup";
import SelectInput from "../../../partials/SelectInput";
import {
  ADDITIONAL,
  BASICS,
  BUILDING_NUMBER,
  CITY,
  COUNTRY,
  DOMAIN,
  RESTAURANT_NAME,
  PHONE_NUMBER,
  RESTAURANT,
  SLOGAN,
  STREET,
  TIMEZONE,
  ZIP_CODE,
} from "../../../language";
import { useDispatch, useSelector } from "react-redux";
import { useEditMode } from "../../../ContextsComponent/EditMode";
import { informationSetAttrib } from "../store/slices/InformationSlice";
import { TIMEZONES } from "../constants";

export default function RestaurantInformation() {
  const information = useSelector((state) => state.information);
  const dispatch = useDispatch();

  const { editMode } = useEditMode();


  return (
    <Accordion title={RESTAURANT + " " + BASICS} opened={true}>
      <form className="form">
        <fieldset disabled={!editMode} className="__group">
          <TextInput
            name={RESTAURANT_NAME}
            placeholder={RESTAURANT_NAME}
            initialValue={information.name}
            setContextValue={(value) => {
              dispatch(informationSetAttrib({ key: "name", value }));
            }}
          />
          <TextInput
            name={SLOGAN}
            placeholder={SLOGAN}
            initialValue={information.slogan}
            setContextValue={(value) => {
              dispatch(informationSetAttrib({ key: "slogan", value }));
            }}
          />
          <TextInput
            name={DOMAIN}
            placeholder={DOMAIN}
            initialValue={information.domain}
            setContextValue={(value) => {
              dispatch(informationSetAttrib({ key: "domain", value }));
            }}
          />
          <TextInput
            icon={"phone.svg"}
            name={PHONE_NUMBER}
            placeholder={PHONE_NUMBER}
            initialValue={information.phone1}
            setContextValue={(value) => {
              dispatch(informationSetAttrib({ key: "phone1", value }));
            }}
          />
          <TextInput
            icon={"phone.svg"}
            name={ADDITIONAL + " " + PHONE_NUMBER}
            placeholder={PHONE_NUMBER}
            initialValue={information.phone2}
            setContextValue={(value) => {
              dispatch(informationSetAttrib({ key: "phone2", value }));
            }}
          />
          <InputGroup>
            <TextInput
              size={5}
              name={COUNTRY}
              placeholder={COUNTRY}
              initialValue={information.country}
              setContextValue={(value) => {
                dispatch(informationSetAttrib({ key: "country", value }));
              }}
            />
            <TextInput
              size={5}
              name={CITY}
              placeholder={CITY}
              initialValue={information.city}
              setContextValue={(value) => {
                dispatch(informationSetAttrib({ key: "city", value }));
              }}
            />
          </InputGroup>
          <SelectInput
            options={TIMEZONES}
            name={TIMEZONE}
            initialValue={information.time_zone}
            setContextValue={(value) => {
              dispatch(informationSetAttrib({ key: "time_zone", value }));
            }}
          />
          <InputGroup>
            <TextInput
              size={4}
              name={STREET}
              placeholder={STREET}
              initialValue={information.street}
              setContextValue={(value) => {
                dispatch(informationSetAttrib({ key: "street", value }));
              }}
            />
            <TextInput
              size={3}
              name={BUILDING_NUMBER}
              placeholder={BUILDING_NUMBER}
              initialValue={"" + information.building_num}
              setContextValue={(value) => {
                dispatch(informationSetAttrib({ key: "building_num", value }));
              }}
            />
            <TextInput
              size={3}
              name={ZIP_CODE}
              placeholder={ZIP_CODE}
              initialValue={information.zip_code}
              setContextValue={(value) => {
                dispatch(informationSetAttrib({ key: "zip_code", value }));
              }}
            />
          </InputGroup>
        </fieldset>
      </form>
    </Accordion>
  );
}
