import { domain } from "../../../constants";
import { getRequest } from "../../../utils/http";
import { ENDPOINTS } from "../constants";
import { setAddonGroups } from "../store/slices/addonGroupsSlice";
import { setAddons } from "../store/slices/addonsSlice";
import { setCategories } from "../store/slices/categoriesSlice";
import { setMeals } from "../store/slices/mealsSlice";
import { setSizes } from "../store/slices/sizesSlice";

export const initilizeResources = ({
    token,
    setLoading,
    dispatch,
    setRemoteCategories,
    setRemoteMeals,
    setRemoteSizes,
    setRemoteAddonGroups,
    setRemoteAddons,
  }) => {
    getRequest(domain + ENDPOINTS.CATEGORY, setLoading, token).then((res) => {
      const [data, status] = res;
      if (status === 200) {
        setRemoteCategories([...data]);
        dispatch(setCategories([...data]));
      }
    });
    getRequest(domain + ENDPOINTS.MEAL, setLoading, token).then((res) => {
      const [data, status] = res;
      if (status === 200) {
        setRemoteMeals([...data]);
        dispatch(setMeals([...data]));
      }
    });
    getRequest(domain + ENDPOINTS.SIZE, setLoading, token).then((res) => {
      const [data, status] = res;
      if (status === 200) {
        setRemoteSizes([...data]);
        dispatch(setSizes([...data]));
      }
    });
    getRequest(domain + ENDPOINTS.ADDONGROUP, setLoading, token).then((res) => {
      const [data, status] = res;
      if (status === 200) {
        setRemoteAddonGroups([...data]);
        dispatch(setAddonGroups([...data]));
      }
    });
    getRequest(domain + ENDPOINTS.ADDON, setLoading, token).then((res) => {
      const [data, status] = res;
      if (status === 200) {
        setRemoteAddons([...data]);
        dispatch(setAddons([...data]));
      }
    });
  };
  