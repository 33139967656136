import Cookies from "js-cookie";
import { domain } from "../constants";
import { LOGIN_ERROR_MESSAGE_1, LOGIN_ERROR_MESSAGE_2 } from "../language";
import { postRequest } from "./http";
import jwt_decode from "jwt-decode";
export const authLogin = (
  username,
  password,
  setLoggedIn,
  setError,
  storeCookies = false,
  setLoading = () => {},
  setToken
) => {
  // check if there is a cookie : if so verify else require credentials
  const token = Cookies.get("access");
  if (token) {
    postRequest(domain + "token/verify/", { token: token }, setLoading).then(
      ([, status]) => {
        if (status === 200) {
          const isAdmin = jwt_decode(token)["role"] === 4;
          if (isAdmin) {
            setLoggedIn(true);
            setToken(token);
            setError("");
          }
        } else {
          Cookies.remove("access");
          Cookies.remove("refresh");
        }
      }
    );
  } else {
    if (!username || !password) {
      return;
    }
    postRequest(
      domain + "token/",
      {
        username,
        password,
      },
      setLoading
    )
      .then(([tokens, status]) => {
        if (status === 200) {
          const isAdmin = jwt_decode(tokens["access"])["role"] === 4;
          if (isAdmin) {
            setLoggedIn(true);
            setToken(tokens["access"]);
            if (storeCookies) {
              Cookies.set("access", tokens["access"]);
              Cookies.set("refresh", tokens["refresh"]);
            }
            setError("");
          } else {
            setError(LOGIN_ERROR_MESSAGE_1);
            setLoggedIn(false);
          }
        } else if (status === 401) {
          setError(LOGIN_ERROR_MESSAGE_1);
          setLoggedIn(false);
        } else {
          setError(LOGIN_ERROR_MESSAGE_2);
          setLoggedIn(false);
        }
      })
      .catch((err) => {
        setError(LOGIN_ERROR_MESSAGE_2);
      });
  }
};

export const authLogout = (setLoggedIn, setToken) => {
  setLoggedIn(false);
  setToken("");
  Cookies.remove("access");
  Cookies.remove("refresh");
};
