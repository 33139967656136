import { domain } from "../../../constants";
import { getChanges } from "../../../utils/helpers";
import { getRequest, patchRequest, postRequest } from "../../../utils/http";
import { setScheduleOne, setScheduleTwo } from "../store/scheduleSlice";

export const getSettings = async (
  setLoading,
  setExtendedTimes,
  setTwoSchedules,
  setSuperClosed,
  setSuperClosedMessage
) => {
  const [information] = await getRequest(
    domain + "settings/restaurant/1/",
    setLoading
  );
  setExtendedTimes(information["expanded_time"]);
  setTwoSchedules(information["two_schedules"]);
  setSuperClosed(information["is_super_closed"]);
  setSuperClosedMessage(information["is_super_closed_message"]);
};

export const getSchedule = async (setLoading, dispatch) => {
  const [scheduleOne] = await getRequest(
    domain + "settings/opening_schedule/",
    setLoading
  );
  dispatch(setScheduleOne(scheduleOne));

  const [scheduleTwo] = await getRequest(
    domain + "settings/opening_schedule2/",
    setLoading
  );
  dispatch(setScheduleTwo(scheduleTwo));
};
export const saveSchedule = async (
  setLoading,
  token,
  schedule,
  extendedTimes,
  twoSchedules,
  superClosed,
  superClosedMessage
) => {
  const scheduleOneChanges = await getChanges(
    schedule.one.raw,
    schedule.one.old
  );
  const scheduleTwoChanges = await getChanges(
    schedule.two.raw,
    schedule.two.old
  );

  patchRequest(
    domain + "settings/restaurant/1/",
    {
      expanded_time: extendedTimes,
      two_schedules: twoSchedules,
      is_super_closed: superClosed,
      is_super_closed_message: superClosedMessage,
    },
    setLoading,
    token
  );

  postRequest(
    domain + "settings/opening_schedule/",
    scheduleOneChanges.added,
    setLoading,
    token
  );
  patchRequest(
    domain + "settings/opening_schedule/",
    scheduleOneChanges.updated,
    setLoading,
    token
  );
  postRequest(
    domain + "settings/opening_schedule2/",
    scheduleTwoChanges.added,
    setLoading,
    token
  );
  patchRequest(
    domain + "settings/opening_schedule2/",
    scheduleTwoChanges.updated,
    setLoading,
    token
  );
};
