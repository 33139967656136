import React, { useEffect, useState } from "react";

export default React.memo(function TextInput({
  name = "",
  placeholder = "",
  icon = "",
  setContextValue = (_) => {},
  type = "text",
  initialValue = "",
  required = true,
  step=0.01,
  ...props
}) {
  const [value, setValue] = useState("");
  useEffect(() => {
    setValue(initialValue ? initialValue : "");
  }, [initialValue]);
  return (
    <div className="input">
      <label className="__label" htmlFor={name}>
        {name}
      </label>
      <div className="__box">
        {icon ? (
          <img
            className="__icon"
            src={require(`../assets/${icon}`).default}
            alt={name}
          ></img>
        ) : (
          ""
        )}
        <input
          {...props}
          step={step}
          className="__field"
          type={type}
          name={name}
          placeholder={placeholder + "..."}
          value={value}
          required={required}
          onChange={(e) => {
            setValue(e.target.value);
            setContextValue(e.target.value);
          }}
        />
      </div>
    </div>
  );
});
