import React from "react";
import { useEditMode } from "../../../ContextsComponent/EditMode";
import {
  CLOSE_FOR_EMERGENCY,
  CLOSE_FOR_EMERGENCY_MSG,
  DIFFERENET_TIMES_FOR_DELIVERY,
  USE_TQO_SCHEDULES,
  WORK_HOURS_TITLE,
} from "../../../language";
import Accordion from "../../../partials/Accordion";
import Textarea from "../../../partials/Textarea";
import { useController } from "../Controller";
import ScheduleTable from "./ScheduleTable";
export default React.memo(function WorkHours() {
  const {
    extendedTimes,
    setExtendedTimes,
    twoSchedules,
    setTwoSchedules,
    superClosed,
    setSuperClosed,
    superClosedMessage,
    setSuperClosedMessage,
  } = useController();

  const { editMode } = useEditMode();
  return (
    <Accordion opened={true} title={WORK_HOURS_TITLE}>
      <div className="__checkbox">
        <input
          id={"extended_times"}
          disabled={!editMode}
          type="checkbox"
          checked={extendedTimes}
          onChange={() => setExtendedTimes(!extendedTimes)}
        />
        <label htmlFor="extended_times">{DIFFERENET_TIMES_FOR_DELIVERY} </label>
      </div>
      <div className="__checkbox">
        <input
          id={"two_schedules"}
          disabled={!editMode}
          type="checkbox"
          checked={twoSchedules}
          onChange={() => setTwoSchedules(!twoSchedules)}
        />
        <label htmlFor="two_schedules">{USE_TQO_SCHEDULES} </label>
      </div>
      <div className="__super-closed">
        <div className="__checkbox">
          <input
            id={"super_closed"}
            disabled={!editMode}
            type="checkbox"
            checked={superClosed}
            onChange={() => setSuperClosed(!superClosed)}
          />
          <label htmlFor="super_closed">{CLOSE_FOR_EMERGENCY} </label>
        </div>
        {superClosed && (
          <Textarea
            name={CLOSE_FOR_EMERGENCY_MSG}
            setContextValue={setSuperClosedMessage}
            initialValue={superClosedMessage}
          />
        )}
      </div>
      <ScheduleTable />
    </Accordion>
  );
});
