import { configureStore } from "@reduxjs/toolkit";
import bannerOffersSlice from "./slices/BannerOffersSlice";
import orderGiftsSlice from "./slices/OrderGiftsSlice";
import dailyOffersSlice from "./slices/DailyOfferSlice";
import orderTotalOfferSlice from "./slices/OrderTotalOfferSlice";
import couponsSlice from "./slices/CouponsSlice";

export default configureStore({
  reducer: {
    dailyOffers: dailyOffersSlice,
    orderTotalOffers: orderTotalOfferSlice,
    bannerOffers: bannerOffersSlice,
    orderGifts: orderGiftsSlice,
    coupons: couponsSlice,
  },
});
