import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import {
  ARE_YOU_SURE,
  FROM,
  HIDDEN,
  MENU,
  THIS_IS_AN_OFFER_CATEGORY,
} from "../../../../../language";
import NewCategory from "./NewCategory";
import {
  removeCategory,
  toggleCategoryHide,
  toggleCategoryIsOffer,
} from "../../../store/slices/categoriesSlice";
import { linkAddonWithGroup } from "../../../store/slices/addonGroupsSlice";
import DropArea from "../../../../../partials/DropArea";
import AddonsTablets from "../Partials/AddonsTablets";
import FoodImage from "../Partials/FoodImage";
import { DND_LINK_ADDON, FIELD_MEALS_CATEGORIES } from "../../../constants";
import Controls from "../../../../../partials/Controls";

const CategoryHeader = React.memo(function ({ category }) {
  const [editing, setEditing] = useState(false);

  const dispatch = useDispatch();

  const remove = () => {
    if (!window.confirm(ARE_YOU_SURE)) return;
    dispatch(removeCategory({ id: category.id }));
  };
  const hide = () => {
    dispatch(toggleCategoryHide({ id: category.id }));
  };
  const isOffer = () => {
    dispatch(toggleCategoryIsOffer({ id: category.id }));
  };

  const linkAddonGroup = ({ itemData }) => {
    dispatch(
      linkAddonWithGroup({
        source: FIELD_MEALS_CATEGORIES,
        sourceId: category.id,
        groupId: itemData.id,
      })
    );
  };

  return (
    <fieldset>
      <DropArea
        onDrop={linkAddonGroup}
        accept={DND_LINK_ADDON}
        effect={true}
        className="__header"
        fixNotRerenderingIssue={uuidv4()}
      >
        {!editing && (
          <Controls
            remove={remove}
            hide={hide}
            edit={() => setEditing(true)}
            isOffer={isOffer}
          />
        )}
        {editing ? (
          <NewCategory
            category={category}
            setEditing={setEditing}
            update={true}
          />
        ) : (
          <div className="__view">
            {category.is_offer && THIS_IS_AN_OFFER_CATEGORY}
            <div className="__title">
              <FoodImage
                itemType={"category"}
                itemId={category.id}
                initialValue={category.image}
              />
              <h2 className="__text">{category.name}</h2>
              {category.hidden && (
                <span>{HIDDEN + " " + FROM + " " + MENU}</span>
              )}
              <span className="__id">
                {  typeof category.id === 'string' ? "" : "#" + category.id}
              </span>
            </div>
            <AddonsTablets item={category} itemType={FIELD_MEALS_CATEGORIES} />
            <p className="__desc">{category.description}</p>
          </div>
        )}
      </DropArea>
    </fieldset>
  );
});
export default CategoryHeader;
