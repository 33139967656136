import React, { createContext, useEffect, useState } from "react";
import { useLoading } from ".";
import { authLogin, authLogout } from "../utils/authHelpers";

export const AuthContext = createContext();

export default function Auth({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [token, setToken] = useState("");
  const { setLoading } = useLoading();

  const LogIn = (username = null, password = null, rememberMe = false) => {
    authLogin(
      username,
      password,
      setIsLoggedIn,
      setLoginError,
      rememberMe,
      setLoading,
      setToken
    );
  };

  const LogOut = () => {
    authLogout(setIsLoggedIn, setToken);
  };

  useEffect(() => {
    LogIn();
    // eslint-disable-next-line
  }, []);

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, LogIn, loginError, token, LogOut }}
    >
      {children}
    </AuthContext.Provider>
  );
}
