import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { REMOVE } from "../../../../../language";
import { unlinkAddonWithGroup } from "../../../store/slices/addonGroupsSlice";

const AddonsTablets = React.memo(function ({ item, itemType }) {
  const dispatch = useDispatch();
  const unlinkAddon = (groupId) => {
    dispatch(
      unlinkAddonWithGroup({
        groupId,
        source: itemType,
        sourceId: item.id,
      })
    );
  };

  const addonGroups = useSelector((state) =>
    state.addonGroups.filter((group) => group[itemType].includes(item.id))
  );

  return (
    <fieldset className="__addons">
      {addonGroups &&
        addonGroups.map((addon) => (
          <div key={addon["id"]} className="__tablet">
            <span>{addon.name}</span>
            <img
              onClick={() => unlinkAddon(addon.id)}
              alt="remove"
              title={REMOVE}
              className="__img"
              src={require("../../../../../assets/times-light.svg").default}
            />
          </div>
        ))}
    </fieldset>
  );
});

export default AddonsTablets;
