import React from "react";
import { useLoading } from "../Contexts";
import { CANCEL, EDIT, SAVE } from "../language";
import Button from "./Button";

// THIS IS AN IMPORTANT COMPONENT IN WHICH THE saveChanges FUNCTION
// GETS INVOKED AND ALL THE REQUESTS TO THE SERVER HAPPENS

export default React.memo(function TopBar({
  editMode,
  setEditMode = null,
  saveChanges,
  title,
  message = "",
  validate = () => true,
}) {
  const { loading } = useLoading();
  return (
    <div className="top-bar">
      <h2 className="__title">{title}</h2>
      {setEditMode && (
        <div className="__btns">
          {message && <p className="error">{message}</p>}
          {!editMode ? (
            <Button
              title="edit"
              onClick={() => (loading ? "" : setEditMode(true))}
            >
              {EDIT}
            </Button>
          ) : (
            <>
              <Button
                title="save"
                onClick={() => {
                  if (validate()) {
                    saveChanges();
                    setEditMode(false);
                  }
                }}
              >
                {SAVE}
              </Button>
              <Button title="cancle" onClick={() => setEditMode(false)}>
                {CANCEL}
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  );
});
