import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { EMPTY_MENU_MESSAGE } from "../../../../language";
import Category from "./Categories/Category";
import NewCategory from "./Categories/NewCategory";
import { useSelector } from "react-redux";
import ImportExport from "../ImportExport";

export default function Menu() {
  const categories = useSelector((state) => state.categories);

  return (
    <div className="__main">
              <ImportExport />

      <Droppable droppableId={"Menu"}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <ul className="__categories">
              {categories.map((category, index) => (
                <Category key={category.id} index={index} category={category} />
              ))}
              {categories.length === 0 && EMPTY_MENU_MESSAGE}
              {provided.placeholder}
            </ul>
          </div>
        )}
      </Droppable>
      <NewCategory />
    </div>
  );
}
