import React from "react";
import { useSelector } from "react-redux";
import { ADDONS } from "../../../../language";
import AddonGroup from "./AddonGroup";
import NewAddonGroup from "./NewAddonGroup";

const Addons = React.memo(function () {
  const addonGroups = useSelector((state) => state.addonGroups);
  return (
    <aside className="addons">
      <h1 className="__title">{ADDONS}</h1>

      <ul className="__groups">
        {addonGroups.map((group, index) => (
          <AddonGroup index={index} key={group.id} group={group} />
        ))}
      </ul>
      <NewAddonGroup />
    </aside>
  );
});

export default Addons;
