import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

export const addonsSlice = createSlice({
  name: "addons",
  initialState: [],
  reducers: {
    setAddons: (state, { payload }) => {
      return [...payload];
    },
    addAddon: (state, { payload }) => {
      state.push({
        ...payload.addon,
        hidden: false,
        id: uuidv4(),
        priority: state.length,
      });
    },
    removeAddon: (state, { payload }) => {
      return state.filter((addon) => payload.id !== addon.id);
    },
    updateAddon: (state, { payload }) => {
      const addon = state.find((_addon) => _addon.id === payload.id);
      addon.name = payload.name;
      addon.price = payload.price;
    },
  },
});

export default addonsSlice.reducer;

export const { setAddons, addAddon, removeAddon, updateAddon } =
  addonsSlice.actions;
