import React from "react";
import {
  ACCEPT,
  CASH,
  DISABLED,
  ENABLED,
  PAYMENT,
  PAYPAL,
  PAYPAL_API_KEY,
  PAYPAL_SECRET,
} from "../../../language";
import Accordion from "../../../partials/Accordion";
import Button from "../../../partials/Button";
import Textarea from "../../../partials/Textarea";
import { useEditMode, usePayment } from "../context";

export default function Payment() {
  const { editMode } = useEditMode();
  const {
    paypal,
    setPaypal,
    paypalApiKey,
    setPaypalApiKey,
    paypalSecret,
    setPaypalSecret,
    cash,
    setCash
  } = usePayment();

  return (
    <div className="mgb-1">
      <Accordion title={PAYMENT} opened={false}>
        <div className="__payment">
          <label>{ACCEPT + " " + CASH + "? "} </label>
          <Button disabled={!editMode} onClick={() => setCash(!cash)}>
            {cash ? ENABLED : DISABLED}
          </Button>
          <label>{ACCEPT + " " + PAYPAL + "? "} </label>
          <Button disabled={!editMode} onClick={() => setPaypal(!paypal)}>
            {paypal ? ENABLED : DISABLED}
          </Button>
          <Textarea
            name={PAYPAL_API_KEY}
            setContextValue={setPaypalApiKey}
            initialValue={paypalApiKey}
            disabled={!editMode}
            type="text"
          />
          <Textarea
            name={PAYPAL_SECRET}
            setContextValue={setPaypalSecret}
            initialValue={paypalSecret}
            disabled={!editMode}
            type="text"
          />
        </div>
      </Accordion>
    </div>
  );
}
