import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { domain } from "../../constants";
import { useAuth, useLoading } from "../../Contexts";
import { deleteRequest, getRequest, postRequest } from "../../utils/http";

const JobsContext = createContext();

export default function Context({ children }) {
  const [jobs, setJobs] = useState([]);

  const [availableJobs, setAvailableJobs] = useState([]);

  const { token } = useAuth();
  const { setLoading } = useLoading();

  const addAvailableJob = (job) => {
    postRequest(domain + "settings/availabe_job/", job, setLoading, token).then(
      ([data, status]) => {
        if (status === 201) {
          setAvailableJobs((prev) => [...prev, data]);
        }
      }
    );
  };
  const removeAvailableJob = (id) => {
    deleteRequest(
      domain + "settings/availabe_job/" + id + "/",
      setLoading,
      token
    ).then(() => {
      setAvailableJobs((prev) => prev.filter((job) => job.id !== id));
    });
  };
  const removeJob = (id) => {
    deleteRequest(domain + "settings/job/" + id + "/", setLoading, token).then(
      () => {
        setJobs((prev) => prev.filter((job) => job.id !== id));
      }
    );
  };

  const getAvailableJobById = useCallback(
    (id) => {
      return availableJobs.find((job) => job.id === id);
    },
    [availableJobs]
  );

  useEffect(() => {
    getRequest(domain + "settings/job/", setLoading, token).then(
      ([data, status]) => {
        if (status === 200) {
          setJobs(data);
        }
      }
    );
    getRequest(domain + "settings/availabe_job/", setLoading, token).then(
      ([data, status]) => {
        if (status === 200) {
          setAvailableJobs(data);
        }
      }
    );
  }, [setLoading, token]);

  return (
    <JobsContext.Provider
      value={{
        jobs,
        availableJobs,
        addAvailableJob,
        getAvailableJobById,
        removeAvailableJob,
        removeJob
      }}
    >
      {children}
    </JobsContext.Provider>
  );
}

export const useJobs = () => useContext(JobsContext);
