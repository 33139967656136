import { Divider } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEditMode } from "../../../ContextsComponent/EditMode";
import {
  DELIVERY,
  OPENED,
  OPENING,
  PICKUP,
  WEEKDAY,
  WEEKDAYS,
} from "../../../language";
import { useController } from "../Controller";
import {
  toggleClose,
  TYPE_DELIVERY,
  TYPE_OPEN,
  TYPE_PICKUP,
  updateSchedule,
} from "../store/scheduleSlice";

import TimeSelector from "./TimeSelector";

export default React.memo(function ScheduleTable() {
  const { editMode } = useEditMode();
  const { extendedTimes, twoSchedules } = useController();

  const schedule = useSelector((state) => state.schedule);

  const dispatch = useDispatch();

  const changeTime = (weekday, from, to, type, scheduleNumber = "one") => {
    if (from > to) return;
    dispatch(
      updateSchedule({
        weekday,
        type,
        scheduleNumber,
        from,
        to,
      })
    );
  };


  return (
    <table className="__schedule">
      <thead>
        <tr>
          <th>{WEEKDAY}</th>
          <th>{OPENING}</th>
          {extendedTimes && <th>{PICKUP}</th>}
          {extendedTimes && <th>{DELIVERY}</th>}
          <th>{OPENED}</th>
        </tr>
      </thead>
      <tbody>
        {schedule.one.formatted.map((day, dayIndex) => (
          <tr className={`${day.closed ? "disabled" : ""}`} key={day.weekday}>
            <td>{WEEKDAYS[day.weekday]}</td>
            <td>
              <TimeSelector
                changeTimeCallback={changeTime}
                day={day}
                type={TYPE_OPEN}
              />
              {twoSchedules && schedule.two.formatted.length > 0 && (
                <>
                  <Divider />
                  <TimeSelector
                    scheduleNumber="two"
                    changeTimeCallback={changeTime}
                    day={schedule.two.formatted[dayIndex]}
                    type={TYPE_OPEN}
                  />
                </>
              )}
            </td>
            {extendedTimes && (
              <td>
                <TimeSelector
                  changeTimeCallback={changeTime}
                  day={day}
                  colName="pickup"
                  type={TYPE_PICKUP}
                />
                {twoSchedules && schedule.two.formatted.length > 0 && (
                  <>
                    <Divider />
                    <TimeSelector
                      scheduleNumber="two"
                      changeTimeCallback={changeTime}
                      colName="pickup"
                      day={schedule.two.formatted[dayIndex]}
                      type={TYPE_PICKUP}
                    />
                  </>
                )}
              </td>
            )}
            {extendedTimes && (
              <td>
                <TimeSelector
                  changeTimeCallback={changeTime}
                  day={day}
                  colName="delivery"
                  type={TYPE_DELIVERY}
                />
                {twoSchedules && schedule.two.formatted.length > 0 && (
                  <>
                    <Divider />
                    <TimeSelector
                      scheduleNumber="two"
                      changeTimeCallback={changeTime}
                      colName="delivery"
                      day={schedule.two.formatted[dayIndex]}
                      type={TYPE_DELIVERY}
                    />
                  </>
                )}
              </td>
            )}
            <td>
              <input
                disabled={!editMode}
                type="checkbox"
                value={!day.closed}
                onChange={() => {
                  dispatch(
                    toggleClose({
                      scheduleNumber: "one",
                      weekday: day.weekday,
                      isClosed: day.closed,
                    })
                  );
                }}
                checked={!day.closed}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
});
