import React, { useState } from "react";
import { ADD, ARE_YOU_SURE, REMOVE, SECTION, SECTIONS, ZIP_CODE } from "../../../language";
import Button from "../../../partials/Button";
import { ACTION_REMOVE } from "../constants";
import { useEditMode, useSections } from "../context";
import NewSection from "./NewSection";

export default function Sections({ zone }) {
  const [adding, setAdding] = useState(false);

  const { editMode, forceRerender } = useEditMode();
  const { setSections } = useSections();

  const remove = (id) => {
    if (!window.confirm(ARE_YOU_SURE)) return;
    setSections({
      type: ACTION_REMOVE,
      id,
    });
    forceRerender();
  };

  return (
    <ul className="__sections">
      <span className="__title">{SECTIONS} : </span>
      {zone["sections"].map((section) => (
        <li key={section["id"]} className="__section">
          <span className="__name">{section["name"]}</span>
          <span className="__zipcode">
            {ZIP_CODE + " : " + section["zip_code"]}
          </span>
          {editMode && (
            <div className="__controls">
              <img
                onClick={() => remove(section["id"])}
                alt="remove"
                title={REMOVE}
                src={require("../../../assets/times.svg").default}
              />
            </div>
          )}
        </li>
      ))}

      {adding && <NewSection setAdding={setAdding} zoneId={zone["id"]} />}

      <Button disabled={adding || !editMode} onClick={() => setAdding(true)}>
        {ADD + " " + SECTION}
      </Button>
    </ul>
  );
}
