import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth, useLoading } from "../../../Contexts";
import {
  getBannerOffers,
  getCoupons,
  getDailyOffers,
  getMenuItems,
  getOrderGifts,
  getOrderTotalOffers,
  getOthers,
  saveResources,
} from "./utils";

const Context = createContext();
export const useController = () => useContext(Context);

export default function Controller({ children }) {
  const { setLoading } = useLoading();
  const { token } = useAuth();
  const dispatch = useDispatch();

  const [meals, setMeals] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [remoteDailyOffers, setRemoteDailyOffers] = useState([]);
  const [remoteOrderTotalOffers, setRemoteOrderTotalOffers] = useState([]);
  const [remoteBannerOffers, setRemoteBannerOffers] = useState([]);
  const [remoteOrderGifts, setRemoteOrderGifts] = useState([]);
  const [remoteCoupons, setRemoteCoupons] = useState([]);
  const [serverIds, setServerIds] = useState([]);
  const dailyOffers = useSelector((state) => state.dailyOffers);
  const orderTotalOffers = useSelector((state) => state.orderTotalOffers);
  const bannerOffers = useSelector((state) => state.bannerOffers);
  const orderGifts = useSelector((state) => state.orderGifts);
  const coupons = useSelector((state) => state.coupons);

  const [firstOrderDiscount, setFirstOrderDiscount] = useState(0);
  const [percentageToPoints, setPercentageToPoints] = useState(0);
  const [maxNumberOfGifts, setMaxNumberOfGifts] = useState(0);

  useEffect(() => {
    getOthers(
      setFirstOrderDiscount,
      setPercentageToPoints,
      setMaxNumberOfGifts,
      setLoading,
      token
    );
    getMenuItems({
      setCategories,
      setMeals,
      setSizes,
      setLoading,
      token,
    });
    getDailyOffers({ dispatch, setRemoteDailyOffers, token, setLoading });
    getOrderTotalOffers({
      dispatch,
      setRemoteOrderTotalOffers,
      token,
      setLoading,
    });
    getBannerOffers({
      dispatch,
      setRemoteBannerOffers,
      token,
      setLoading,
    });
    getOrderGifts({
      dispatch,
      setRemoteOrderGifts,
      token,
      setLoading,
    });
    // eslint-disable-next-line
    getCoupons({
      dispatch,
      setRemoteCoupons,
      token,
      setLoading,
    });
    // eslint-disable-next-line
  }, []);

  const saveChanges = () => {
    saveResources({
      dailyOffers,
      remoteDailyOffers,
      orderTotalOffers,
      remoteOrderTotalOffers,
      bannerOffers,
      orderGifts,
      remoteOrderGifts,
      remoteBannerOffers,
      firstOrderDiscount,
      percentageToPoints,
      maxNumberOfGifts,
      remoteCoupons,
      coupons,
      setLoading,
      token,
      setServerIds,
      serverIds,
    }).then(() => {
      setRemoteDailyOffers([...dailyOffers]);
      setRemoteOrderTotalOffers([...orderTotalOffers]);
      setRemoteBannerOffers([...bannerOffers]);
      setRemoteOrderGifts([...orderGifts]);
      setRemoteCoupons([...coupons]);
    });
  };

  const getMeal = (id) => {
    const _meal = meals.find((meal) => meal.id === id);
    return _meal ?? { name: "" };
  };

  const getSize = useCallback(
    (id) => {
      const _size = sizes.find((size) => size.id === id);
      return _size ?? { name: "" };
    },
    [sizes]
  );

  const getSizeByName = useCallback(
    (name) => {
      const _size = sizes.find((size) => size.name === name);
      return _size ?? { name: "" };
    },
    [sizes]
  );

  const getCategory = (id) => {
    const _category = categories.find((category) => category.id === +id);
    return _category ?? { name: "" };
  };

  const getCategoryMeals = (categoryId) => {
    return meals.filter((meal) => meal.category === +categoryId);
  };
  const getCategorySizesNames = (categoryId) => {
    const categroy_sizes = sizes.filter((size) => {
      let _meal_category = getMeal(size.meal);
      _meal_category = _meal_category ? _meal_category.category : null;
      return _meal_category === +categoryId;
    });
    const sizes_names = [];
    categroy_sizes.forEach((size) => {
      !sizes_names.includes(size.name) && sizes_names.push(size.name);
    });
    return sizes_names;
  };
  const getCategorySizes = (categoryId) => {
    return sizes.filter((size) => {
      let _meal_category = getMeal(size.meal);
      _meal_category = _meal_category ? _meal_category.category : null;
      return _meal_category === +categoryId;
    });
  };

  const getCategorySizesIds = (name, categoryId) => {
    return getCategorySizes(categoryId)
      .filter((size) => size.name === name)
      .map((size) => size.id);
  };

  const getMealSizes = (mealId) => {
    return sizes.filter((size) => size.meal === +mealId);
  };

  const areSizesForOneMeal = (_sizes = []) => {
    _sizes = _sizes || [];
    const _meals = [];
    try {
      _sizes.forEach((size) => _meals.push(getSize(size).meal));
      return _meals.length === 1;
    } catch (e) {
      return false;
    }
  };

  return (
    <Context.Provider
      value={{
        saveChanges,
        meals,
        categories,
        sizes,
        firstOrderDiscount,
        percentageToPoints,
        maxNumberOfGifts,
        setMaxNumberOfGifts,
        setPercentageToPoints,
        getCategory,
        setFirstOrderDiscount,
        getCategoryMeals,
        getCategorySizesNames,
        getCategorySizes,
        getMealSizes,
        getCategorySizesIds,
        getMeal,
        getSizeByName,
        getSize,
        areSizesForOneMeal,
      }}
    >
      {children}
    </Context.Provider>
  );
}
