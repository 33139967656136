import React, { useEffect, useState } from "react";
import { ARE_YOU_SURE } from "../language";

export default function ImageInput({
  initial = [],
  setContextValue = (_) => {},
  title,
  multiple = true,
}) {
  const [images, setImages] = useState([]);
  const [lastImage, setLastImage] = useState(null);

  const [, setWatchDog] = useState(0);

  useEffect(() => {
    if (initial) {
      setImages([...initial]);
    }
  }, [initial]);

  const handleFiles = ({ target }) => {
    let reader = new FileReader();
    reader.readAsDataURL(target.files[0]);
    reader.onload = function () {
      setLastImage(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const deleteImage = (index) => {
    if (!window.confirm(ARE_YOU_SURE)) return;

    const _new = images.filter((img, idx) => idx !== index);
    setImages(_new);
    if (multiple) {
      setContextValue(_new);
    } else {
      setContextValue("");
    }
    setLastImage(null);
    setWatchDog((prev) => prev + 1);
  };

  useEffect(() => {
    if (lastImage) {
      let _images = images || [];
      if (multiple) {
        _images.push(lastImage);
        setContextValue(_images);
      } else {
        _images = [lastImage];
        setContextValue(lastImage);
      }
      setImages(_images);
      setWatchDog((prev) => prev + 1);
      setLastImage(null);
    }
    // eslint-disable-next-line
  }, [lastImage, multiple, setContextValue]);
  return (
    <div className="input">
      <div className="__image">
        <div className="__preview">
          {images &&
            images.length > 0 &&
            images.map((image, key) => (
              <div key={key} className="__img-wrapper">
                <img src={image} alt="" />
                <img
                  src={require("../assets/times.svg").default}
                  alt="close"
                  className="__close-btn"
                  onClick={() => deleteImage(key)}
                />
              </div>
            ))}
        </div>
        <label className="__add">
          <span className="__btn">{title}</span>

          <input
            accept="image/png, image/gif, image/jpeg , image/webp"
            type="file"
            className="__img"
            onChange={handleFiles}
          />
        </label>
      </div>
    </div>
  );
}
