import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  ADDTIONAL_INFO,
  ARE_YOU_SURE,
  CURRENCY,
  FROM,
  HIDDEN,
  MENU,
  THIS_IS_AN_OFFER_MEAL,
} from "../../../../../language";
import DropArea from "../../../../../partials/DropArea";
import { linkAddonWithGroup } from "../../../store/slices/addonGroupsSlice";
import {
  removeMeal,
  toggleMealHide,
  toggleMealIsOffer,
} from "../../../store/slices/mealsSlice";
import AddonsTablets from "../Partials/AddonsTablets";
import FoodImage from "../Partials/FoodImage";
import NewMeal from "./NewMeal";
import { DND_LINK_ADDON, FIELD_MEALS } from "../../../constants";
import Controls from "../../../../../partials/Controls";

const MealHeader = React.memo(function ({ meal }) {
  const dispatch = useDispatch();
  const [editing, setEditing] = useState(false);
  const remove = () => {
    if (!window.confirm(ARE_YOU_SURE)) return;
    dispatch(
      removeMeal({
        id: meal.id,
      })
    );
  };
  const hide = () => {
    dispatch(
      toggleMealHide({
        hidden: !meal.hidden,
        id: meal.id,
      })
    );
  };
  const isOffer = () => {
    dispatch(toggleMealIsOffer({ id: meal.id }));
  };
  const linkAddonGroup = ({ itemData }) => {
    dispatch(
      linkAddonWithGroup({
        source: FIELD_MEALS,
        sourceId: meal.id,
        groupId: itemData.id,
      })
    );
  };

  return (
    <fieldset>
      <DropArea
        accept={DND_LINK_ADDON}
        onDrop={linkAddonGroup}
        effect={true}
        className="__header"
      >
        {!editing && (
          <Controls
            remove={remove}
            hide={hide}
            edit={() => setEditing(true)}
            isOffer={isOffer}
          />
        )}

        {editing ? (
          <NewMeal setEditing={setEditing} meal={meal} update={true} />
        ) : (
          <div className="__view">
            {meal.is_offer && THIS_IS_AN_OFFER_MEAL}

            <div className="__title">
              <FoodImage
                itemType={"meal"}
                itemId={meal.id}
                initialValue={meal.image}
              />
              <h2 className="__text">{meal.name}</h2>

              {meal.hidden && <span>{HIDDEN + " " + FROM + " " + MENU}</span>}
              <span className="__id">
                {typeof meal.id === "string" ? "" : "#" + meal.id}
              </span>

              <span className="price">{`${FROM} ${meal.price} ${CURRENCY}`}</span>
            </div>
            <AddonsTablets item={meal} itemType={FIELD_MEALS} />
            <p className="__desc">{meal.description}</p>
            {meal.additional_info && (
              <>
                <p className="__addtional-info-header"> {ADDTIONAL_INFO} </p>
                <p
                  className="__addtional-info"
                  dangerouslySetInnerHTML={{ __html: meal.additional_info }}
                ></p>
              </>
            )}
          </div>
        )}
      </DropArea>
    </fieldset>
  );
});

export default MealHeader;
