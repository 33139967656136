import React from "react";
import {
  ACCEPT,
  DISABLED,
  ENABLED,
  MAX_PREORDER_DAYS,
  PREORDER,
} from "../../../language";
import Accordion from "../../../partials/Accordion";
import Button from "../../../partials/Button";
import TextInput from "../../../partials/TextInput";
import { useEditMode, usePreorder } from "../context";

export default function Preorder() {
  const { maxPreorderDays, setMaxPreorderDays, preorder, setPreorder } =
    usePreorder();
  const { editMode } = useEditMode();

  return (
    <div className="mgb-1">
      <Accordion title={PREORDER} opened={false}>
        <div className="__preorder">
          <label>{ACCEPT + " " + PREORDER + "? "} </label>
          <Button disabled={!editMode} onClick={() => setPreorder(!preorder)}>
            {preorder ? ENABLED : DISABLED}
          </Button>
          <TextInput
            name={MAX_PREORDER_DAYS}
            setContextValue={setMaxPreorderDays}
            initialValue={maxPreorderDays}
            disabled={!editMode}

            type="number"
          />
        </div>
      </Accordion>
    </div>
  );
}
