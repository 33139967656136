import React, { createContext, useContext, useState } from "react";

const EditModeContext = createContext();
export const useEditMode = () => useContext(EditModeContext);

export default function EditMode({ children }) {
  const [editMode, setEditMode] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");

  return (
    <EditModeContext.Provider
      value={{
        editMode,
        setEditMode,
        statusMessage,
        setStatusMessage,
      }}
    >
      {children}
    </EditModeContext.Provider>
  );
}
