import React from "react";

export default React.memo(function InputGroup({ children }) {
  return (
    <div className="input-group">
      {children.map((child) => (
        <div
        key={child.props.name}
          className="input-group__item"
          style={{ width: `${child.props.size * 10 - 2}%` }}
        >
          {child}
        </div>
      ))}
    </div>
  );
});
