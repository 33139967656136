import React, { createContext, useContext, useEffect, useState } from "react";
import { domain, host } from "../../constants";
import { useAuth, useLoading } from "../../Contexts";
import { getBase64FromUrl, objectsDiff, validURL } from "../../utils/helpers";
import { getRequest, patchRequest } from "../../utils/http";
import { useDispatch, useSelector } from "react-redux";
import { informationSetState } from "./store/slices/InformationSlice";
import { useEditMode } from "../../ContextsComponent/EditMode";
import { ERROR_MESSAGE_1, SAVED_MESSGAE, SAVING_MESSGAE } from "../../language";

const Context = createContext();
export const useController = () => useContext(Context);

export default function Controller({ children }) {
  const [remoteInformation, setRemoteInformation] = useState({});
  const { setStatusMessage } = useEditMode();

  const { setLoading } = useLoading();
  const { token } = useAuth();
  const dispatch = useDispatch();
  const localInformation = useSelector((state) => state.information);
  useEffect(() => {
    getRequest(domain + "settings/restaurant/1/", setLoading, token).then(
      ([data, status]) => {
        if (status === 200) {
          setLoading(true);
          formatRecievedData(data).then(() => {
            setLoading(false);
            dispatch(informationSetState({ ...data }));
            setRemoteInformation({ ...data });
          });
        }
      }
    );
    // eslint-disable-next-line
  }, []);

  const saveChanges = () => {
    const updatedValues = objectsDiff(remoteInformation, localInformation);
    const requestBody = { ...updatedValues };
    if (requestBody.logo) {
      requestBody.logo = requestBody.logo.split(",").pop();
    }
    if (requestBody.covers) {
      requestBody.covers = requestBody.covers.length
        ? requestBody.covers.map((cover) => ({
            image: validURL(cover) ? host + cover : cover.split(",").pop(),
          }))
        : [];
    }

    setStatusMessage(SAVING_MESSGAE);
    patchRequest(
      domain + "settings/restaurant/1/",
      requestBody,
      setLoading,
      token
    ).then((res) => {
      const [, status] = res;
      if (status !== 200) {
        setStatusMessage(ERROR_MESSAGE_1);
      } else {
        setStatusMessage(SAVED_MESSGAE);
        setRemoteInformation({ ...remoteInformation, ...updatedValues });
      }
    });
  };

  return (
    <Context.Provider value={{ saveChanges }}>{children}</Context.Provider>
  );
}

// helpers
const formatRecievedData = async (recievedData) => {
  // convert the covers from array of objects to an array of strings
  recievedData.covers = await Promise.all(
    recievedData.covers.map(async (cover) => {
      return await getBase64FromUrl(host + cover.image);
    })
  );
  if (recievedData.logo !== null) {
    recievedData.logo = await getBase64FromUrl(host + recievedData.logo);
  }
};
