import React from "react";
import TopBar from "../../partials/TopBar";
import RestaurantInformation from "./components/RestaurantInformation";
import RestaurantCuisine from "./components/RestaurantCuisine";
import WebsiteSettings from "./components/WebsiteSettings";
import AccordionList from "../../partials/AccordionList";
import { BASIC_INFROMATION } from "../../language";
import EditMode, { useEditMode } from "../../ContextsComponent/EditMode";
import store from "./store/store";
import Controller, { useController } from "./Controller";
import { Provider } from "react-redux";
import SocialLinks from "./components/SocialLinks";

function Index() {
  const { editMode, setEditMode, statusMessage } = useEditMode();
  const { saveChanges } = useController();

  return (
    <>
      <TopBar
        title={BASIC_INFROMATION}
        editMode={editMode}
        setEditMode={setEditMode}
        saveChanges={saveChanges}
        message={statusMessage}
      />
      <div className="basics animate__animated animate__fadeIn">
        <AccordionList>
          <RestaurantInformation />
          <RestaurantCuisine />
          <WebsiteSettings />
          <SocialLinks  />
        </AccordionList>
      </div>
    </>
  );
}

export default function Basics({ children }) {
  return (
    <Provider store={store}>
      <EditMode>
        <Controller>
          <Index />
        </Controller>
      </EditMode>
    </Provider>
  );
}
