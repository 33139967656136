import { configureStore } from "@reduxjs/toolkit";
import addonGroupsSlice from "./slices/addonGroupsSlice";
import addonsSlice from "./slices/addonsSlice";
import categoriesSlice from "./slices/categoriesSlice";
import mealsSlice from "./slices/mealsSlice";
import sizesSlice from "./slices/sizesSlice";

export default configureStore({
  reducer: {
    categories: categoriesSlice,
    meals: mealsSlice,
    sizes: sizesSlice,
    addons: addonsSlice,
    addonGroups: addonGroupsSlice,
  },
});
