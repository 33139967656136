import React from "react";
import { ACCEPT, DELIVERY, DISABLED, ENABLED } from "../../../language";
import Accordion from "../../../partials/Accordion";
import Button from "../../../partials/Button";
import { useDelivery, useEditMode } from "../context";
import Zones from "./Zones";

export default function Delivery() {
  const { delivery, setDelivery } = useDelivery();
  const { editMode } = useEditMode();
  return (
    <div className="mgb-1">
      <Accordion opened={false} title={DELIVERY}>
        <div className="__delivery">
          <div className="__toggle">
            <p>{ACCEPT + " " + DELIVERY + "? "}</p>
            <Button disabled={!editMode} onClick={() => setDelivery(!delivery)}>
              {delivery ? ENABLED : DISABLED}
            </Button>
          </div>

          {delivery && <Zones />}
        </div>
      </Accordion>
    </div>
  );
}
