import React from "react";
import {
  FIRST_ORDER_DISCOUNT,
  OTHER_OFFERS,
  PERCENTAGE,
  POINTS_CALC,
} from "../../../../language";
import Accordion from "../../../../partials/Accordion";
import TextInput from "../../../../partials/TextInput";
import { useController } from "../../Controller";

export default function OtherOffers() {
  const {
    firstOrderDiscount,
    setFirstOrderDiscount,
    percentageToPoints,
    setPercentageToPoints,
  } = useController();
  return (
    <div className="__first_order_discount">
      <Accordion title={OTHER_OFFERS} opened={false}>
        <TextInput
          name={FIRST_ORDER_DISCOUNT + " " + PERCENTAGE}
          setContextValue={setFirstOrderDiscount}
          initialValue={firstOrderDiscount}
          type="number"
        />
        <TextInput
          name={POINTS_CALC + " " + PERCENTAGE}
          setContextValue={setPercentageToPoints}
          initialValue={percentageToPoints}
          type="number"
        />
      </Accordion>
    </div>
  );
}
