import {
  addNewElements,
  deleteElements,
  formatRequest,
  getChanges,
  newServerIds,
  updateElements,
} from "../../../utils/helpers";
import { ENDPOINTS } from "../constants";

export const saveResources = async ({
  token,
  setLoading,
  categories,
  meals,
  sizes,
  addons,
  addonGroups,
  remoteCategories,
  remoteMeals,
  remoteSizes,
  remoteAddons,
  remoteAddonGroups,
  setStatusMessage,
  serverIds,
  setServerIds,
}) => {
  // get the changes
  const categoriesChanges = await getChanges(categories, remoteCategories);
  const mealsChanges = await getChanges(meals, remoteMeals);
  const sizesChanges = await getChanges(sizes, remoteSizes);
  const addonsChanges = await getChanges(addons, remoteAddons);
  const addonsGroupsChanges = await getChanges(addonGroups, remoteAddonGroups);

  // ================= Add ==============================
  // post added elements -> get the server ids -> update affected elements -> post affeted elements .
  // categories -> meals -> sizes -> addonsGroups -> addons
  const addedCategoriesResponse = await addNewElements(
    ENDPOINTS.CATEGORY,
    formatRequest(categoriesChanges.added, serverIds),
    setLoading,
    token
  );

  if (addedCategoriesResponse[1] === 201) {
    setServerIds((prev) =>
      newServerIds(categoriesChanges.added, addedCategoriesResponse[0], prev)
    );
    const addedMealsResponse = await addNewElements(
      ENDPOINTS.MEAL,
      formatRequest(mealsChanges.added, serverIds),
      setLoading,
      token
    );
    if (addedMealsResponse[1] === 201) {
      setServerIds((prev) =>
        newServerIds(mealsChanges.added, addedMealsResponse[0], prev)
      );
      const addedSizesResponse = await addNewElements(
        ENDPOINTS.SIZE,
        formatRequest(sizesChanges.added, serverIds),
        setLoading,
        token
      );
      if (addedSizesResponse[1] === 201) {
        setServerIds((prev) =>
          newServerIds(sizesChanges.added, addedSizesResponse[0], prev)
        );
        const groupsResponse = await addNewElements(
          ENDPOINTS.ADDONGROUP,
          addonsGroupsChanges.added,
          setLoading,
          token
        );
        addonsGroupsChanges.updated.length &&
          (await updateElements(
            ENDPOINTS.ADDONGROUP,
            formatRequest(addonsGroupsChanges.updated, serverIds),
            setLoading,
            token
          ));

        if (groupsResponse[1] === 201) {
          setServerIds((prev) =>
            newServerIds(addonsGroupsChanges.added, groupsResponse[0], prev)
          );
          const addonResponse = await addNewElements(
            ENDPOINTS.ADDON,
            formatRequest(addonsChanges.added, serverIds),
            setLoading,
            token
          );
          if (addonResponse[1] === 201) {
            setServerIds((prev) =>
              newServerIds(addonsChanges.added, addonResponse[0], prev)
            );
          }
        }
      }
    }
  }

  //=============== update ==========================

  categoriesChanges.updated.length &&
    (await updateElements(
      ENDPOINTS.CATEGORY,
      formatRequest(categoriesChanges.updated, serverIds),
      setLoading,
      token
    ));
  mealsChanges.updated.length &&
    (await updateElements(
      ENDPOINTS.MEAL,
      formatRequest(mealsChanges.updated, serverIds),
      setLoading,
      token
    ));
  sizesChanges.updated.length &&
    (await updateElements(
      ENDPOINTS.SIZE,
      formatRequest(sizesChanges.updated, serverIds),
      setLoading,
      token
    ));
  addonsChanges.updated.length &&
    (await updateElements(
      ENDPOINTS.ADDON,
      formatRequest(addonsChanges.updated, serverIds),
      setLoading,
      token
    ));

  // =============== Deleted ========================

  categoriesChanges.removed.length &&
    deleteElements(
      ENDPOINTS.CATEGORY,
      formatRequest(categoriesChanges.removed, serverIds),
      setLoading,
      token
    );
  mealsChanges.removed.length &&
    deleteElements(
      ENDPOINTS.MEAL,
      formatRequest(mealsChanges.removed, serverIds),
      setLoading,
      token
    );
  sizesChanges.removed.length &&
    deleteElements(
      ENDPOINTS.SIZE,
      formatRequest(sizesChanges.removed, serverIds),

      setLoading,
      token
    );
  addonsGroupsChanges.removed.length &&
    deleteElements(
      ENDPOINTS.ADDONGROUP,
      formatRequest(addonsGroupsChanges.removed, serverIds),
      setLoading,
      token
    );
  addonsChanges.removed.length &&
    deleteElements(
      ENDPOINTS.ADDON,
      formatRequest(addonsChanges.removed, serverIds),
      setLoading,
      token
    );
};
