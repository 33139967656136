import Button from "../../../partials/Button";
import React, { useState } from "react";
import {
  DELIVERY_STARS,
  ERROR_MESSAGE_1,
  FOOD_STARS,
  HIDE_REVIEW,
  NAME,
  ONLINE_STARS,
  REVIEW_BODY,
  SHOW_REVIEW,
} from "../../../language";
import { useReviews } from "../Context";

export default function Review({ review }) {
  const [showReview, setShowReview] = useState(!review.hidden);
  const [error, setError] = useState(null);

  const { toggleShowReview } = useReviews();
  const onClick = () => {
    toggleShowReview(review.id, showReview).then(([res, status]) => {
      if (status === 200) {
        setShowReview(prev => !prev);
        setError(null);
      } else {
        setError(ERROR_MESSAGE_1);
      }
    });
  };
  return (
    <table className="__review">
      <tbody>
        <tr>
          <td>{NAME}</td>
          <td>{!review.is_anon ? review.user_fullname : "____"}</td>
        </tr>
        <tr>
          <td>{REVIEW_BODY}</td>
          <td>{review.body}</td>
        </tr>
        <tr>
          <td>{FOOD_STARS}</td>
          <td>{review.food_stars}</td>
        </tr>
        <tr>
          <td>{DELIVERY_STARS}</td>
          <td>{review.delivery_stars}</td>
        </tr>
        <tr>
          <td>{ONLINE_STARS}</td>
          <td>{review.online_stars}</td>
        </tr>
      </tbody>
      {error}
      <Button onClick={onClick}>
        {showReview ? HIDE_REVIEW : SHOW_REVIEW}
      </Button>
    </table>
  );
}
