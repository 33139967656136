import { domain } from "../../../constants";
import {
  addNewElements,
  deleteElements,
  getChanges,
  setServerIds,
  updateAffectedIds,
  updateElements,
} from "../../../utils/helpers";
import { patchRequest } from "../../../utils/http";
import { ENDPOINTS } from "../constants";

export const callAPI = async (
  token,
  setLoading,
  zones,
  oldZones,
  sections,
  oldSections,
  pickup,
  delivery,
  preorder,
  maxPreorderDays,
  paypal,
  paypalApiKey,
  paypalSecret,
  allowAnon,
  dineIn,
  cash,
  allowDineInCash,
  allowDineInPaypal,
  tableReservations,
  covid_policy,
  maxDaysTable,
  maxNumTable,
  allowUsersCreation,
  maintenanceMode,
  maintenanceMSG,
  allowReviews,
allowJobs,
) => {
  const zonesChanges = await getChanges(zones, oldZones);
  const sectionsChanges = await getChanges(sections, oldSections);

  await patchRequest(
    domain + "settings/restaurant/1/",
    {
      pickup,
      delivery,
      enable_preorder: preorder,
      max_preorder_days: maxPreorderDays,
      paypal,
      paypal_clientid: paypalApiKey,
      paypal_secret: paypalSecret,
      allow_anon: allowAnon || !allowUsersCreation,
      dine_in: dineIn,
      cash,
      allow_dinein_cash: allowDineInCash,
      allow_dinein_paypal: allowDineInPaypal,
      table_reservation: tableReservations,
      covid_policy,
      max_days_table: maxDaysTable,
      max_num_table: maxNumTable,
      allow_users: allowUsersCreation,
      allow_jobs: allowJobs &&  allowUsersCreation,
      allow_user_reviews: allowReviews && allowUsersCreation,
      maintenance_mode: maintenanceMode,
      maintenance_msg: maintenanceMSG,
    },
    setLoading,
    token
  );
  const serverIds = {};

  const addedZonesResponse = await addNewElements(
    ENDPOINTS["ZONE"],
    zonesChanges.added,
    setLoading,
    token
  );
  if (addedZonesResponse[1] === 201) {
    setServerIds(zonesChanges.added, addedZonesResponse[0], serverIds);
    updateAffectedIds(sectionsChanges.added, "zone", serverIds);
    await addNewElements(
      ENDPOINTS["SECTION"],
      sectionsChanges.added,
      setLoading,
      token
    );
  }

  zonesChanges.updated &&
    (await updateElements(
      ENDPOINTS["ZONE"],
      zonesChanges.updated,
      setLoading,
      token
    ));

  zonesChanges.removed &&
    (await deleteElements(
      ENDPOINTS["ZONE"],
      zonesChanges.removed,
      setLoading,
      token
    ));
  sectionsChanges.removed &&
    (await deleteElements(
      ENDPOINTS["SECTION"],
      sectionsChanges.removed,
      setLoading,
      token
    ));
};
