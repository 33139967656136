import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth, useLoading } from "../../../Contexts";
import { getSchedule, getSettings, saveSchedule } from "./utils";

const Context = createContext();
export const useController = () => useContext(Context);

export default function Controller({ children }) {
  const [extendedTimes, setExtendedTimes] = useState(false);
  const [twoSchedules, setTwoSchedules] = useState(false);
  const [superClosed, setSuperClosed] = useState(false);
  const [superClosedMessage, setSuperClosedMessage] = useState("");
  const { setLoading } = useLoading();
  const { token } = useAuth();

  const dispatch = useDispatch();
  const schedule = useSelector((state) => state.schedule);

  useEffect(() => {
    getSettings(
      setLoading,
      setExtendedTimes,
      setTwoSchedules,
      setSuperClosed,
      setSuperClosedMessage
    );
    getSchedule(setLoading, dispatch);
    // eslint-disable-next-line
  }, []);

  const saveChanges = () => {
    saveSchedule(
      setLoading,
      token,
      schedule,
      extendedTimes,
      twoSchedules,
      superClosed,
      superClosedMessage
    ).then(() => {
      getSchedule(setLoading, dispatch);
    });
  };

  return (
    <Context.Provider
      value={{
        extendedTimes,
        setExtendedTimes,
        twoSchedules,
        setTwoSchedules,
        saveChanges,
        superClosed,
        setSuperClosed,
        superClosedMessage,
        setSuperClosedMessage,
      }}
    >
      {children}
    </Context.Provider>
  );
}
