import Textarea from "partials/Textarea";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  ADD,
  ADDTIONAL_INFO,
  CANCEL,
  DESCRIPTION,
  MEAL,
  NAME,
  PRICE,
  SAVE,
} from "../../../../../language";
import Button from "../../../../../partials/Button";
import InputGroup from "../../../../../partials/InputGroup";
import TextInput from "../../../../../partials/TextInput";
import { addMeal, updateMeal } from "../../../store/slices/mealsSlice";

const NewMeal = React.memo(function ({
  categoryId,
  update = false,
  meal = {},
  setEditing = () => {},
}) {
  const dispatch = useDispatch();

  const [adding, setAdding] = useState(update);
  const [name, setName] = useState(meal.name || "");
  const [description, setDescription] = useState(meal.description || "");
  const [addtionalInfo, setAddtionalInfo] = useState(
    meal.additional_info || ""
  );
  const [price, setPrice] = useState(meal.price || 0);

  const handleAdd = () => {
    !update
      ? dispatch(
          addMeal({
            meal: {
              name,
              price,
              description,
              category: categoryId,
              additional_info: addtionalInfo,
            },
          })
        )
      : dispatch(
          updateMeal({
            id: meal.id,
            name,
            price,
            description,
            additional_info: addtionalInfo,
          })
        );
    setAdding(false);
    setEditing(false);
  };

  const handleCancle = () => {
    setAdding(false);
    setEditing(false);
  };

  return (
    <>
      {adding && (
        <form onSubmit={handleAdd} className="new-meal">
          <fieldset>
            <InputGroup>
              <TextInput
                name={NAME}
                placeholder={NAME}
                setContextValue={(value) => setName(value)}
                size={5}
                initialValue={name}
              />
              <TextInput
                size={5}
                name={PRICE}
                placeholder={PRICE}
                setContextValue={(value) => setPrice(value)}
                type="number"
                initialValue={price}
              />
            </InputGroup>
            <TextInput
              name={DESCRIPTION}
              placeholder={DESCRIPTION}
              setContextValue={(value) => setDescription(value)}
              initialValue={description}
              required={false}
            />
            <Textarea
              name={ADDTIONAL_INFO}
              placeholder={ADDTIONAL_INFO}
              setContextValue={(value) => setAddtionalInfo(value)}
              initialValue={addtionalInfo}
              required={false}
            />
            <div className="__btns">
              <Button type="submit">{SAVE}</Button>
              <Button onClick={handleCancle}>{CANCEL}</Button>
            </div>
          </fieldset>
        </form>
      )}
      {!update && (
        <Button onClick={() => setAdding(true)} disabled={adding}>
          {ADD + " " + MEAL}
        </Button>
      )}
    </>
  );
});
export default NewMeal;
