import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEditMode } from "../../../ContextsComponent/EditMode";
import {
  ADD,
  COVER,
  GOOGLE_ANALYTICS_SCRIPT,
  GOOGLE_ANALYTICS_SCRIPT_SRC,
  IMAGE,
  IMAGES,
  IMPRINT,
  LOGO,
  PRIVACY_POLICY,
  PROMO_VIDEO,
  RETURN_POLICY,
  SEO,
  SETTINGS,
  TAX_MESSAGE,
  USER_AGREEMENT,
  WEBSITE,
} from "../../../language";

import Accordion from "../../../partials/Accordion";
import ImageInput from "../../../partials/ImageInput";
import Textarea from "../../../partials/Textarea";
import { informationSetAttrib } from "../store/slices/InformationSlice";

export default function WebsiteSettings() {
  const { editMode } = useEditMode();

  const information = useSelector((state) => state.information);
  const dispatch = useDispatch();

  return (
    <Accordion opened={false} title={WEBSITE + " " + SETTINGS}>
      <form className="form">
        <fieldset className="__group" disabled={!editMode}>
          <ImageInput
            title={ADD + " " + LOGO + " " + IMAGE}
            setContextValue={(value) => {
              dispatch(informationSetAttrib({ key: "logo", value }));
            }}
            initial={information.logo ? [information.logo] : ""}
            multiple={false}
          />
          <ImageInput
            title={ADD + " " + COVER + " " + IMAGES}
            setContextValue={(value) => {
              dispatch(informationSetAttrib({ key: "covers", value }));
            }}
            initial={information.covers}
          />
        </fieldset>
        <Textarea
          initialValue={information.gscript_srs}
          setContextValue={(value) => {
            dispatch(informationSetAttrib({ key: "gscript_srs", value }));
          }}
          name={GOOGLE_ANALYTICS_SCRIPT_SRC}
          disabled={!editMode}
        />
        <Textarea
          initialValue={information.gscript}
          setContextValue={(value) => {
            dispatch(informationSetAttrib({ key: "gscript", value }));
          }}
          name={GOOGLE_ANALYTICS_SCRIPT}
          disabled={!editMode}
        />
        <Textarea
          initialValue={information.promo_video}
          setContextValue={(value) => {
            dispatch(informationSetAttrib({ key: "promo_video", value }));
          }}
          name={PROMO_VIDEO}
          disabled={!editMode}
        />
        <Textarea
          initialValue={information.tax_seal}
          setContextValue={(value) => {
            dispatch(informationSetAttrib({ key: "tax_seal", value }));
          }}
          name={TAX_MESSAGE}
          disabled={!editMode}
        />
        <Textarea
          initialValue={information.privacy_policy}
          setContextValue={(value) => {
            dispatch(informationSetAttrib({ key: "privacy_policy", value }));
          }}
          name={PRIVACY_POLICY}
          disabled={!editMode}
        />
        <Textarea
          initialValue={information.user_agreement}
          setContextValue={(value) => {
            dispatch(informationSetAttrib({ key: "user_agreement", value }));
          }}
          name={USER_AGREEMENT}
          disabled={!editMode}
        />
        <Textarea
          initialValue={information.imprint}
          setContextValue={(value) => {
            dispatch(informationSetAttrib({ key: "imprint", value }));
          }}
          name={IMPRINT}
          disabled={!editMode}
        />
        <Textarea
          initialValue={information.return_policy}
          setContextValue={(value) => {
            dispatch(informationSetAttrib({ key: "return_policy", value }));
          }}
          name={RETURN_POLICY}
          disabled={!editMode}
        />
        <Textarea
          initialValue={information.seo}
          setContextValue={(value) => {
            dispatch(informationSetAttrib({ key: "seo", value }));
          }}
          name={SEO}
          disabled={!editMode}
        />
      </form>
    </Accordion>
  );
}
