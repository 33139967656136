import React from "react";
import { ADD, EDIT, HIDE, OFFER, REMOVE } from "../language";

export default function Controls({ edit, remove, hide, add, isOffer }) {
  return (
    <>
      <div className="__controls">
        {isOffer && (
          <img
            src={require("../assets/gift.svg").default}
            onClick={isOffer}
            title={OFFER}
            alt={OFFER}
          />
        )}

        {edit && (
          <img
            onClick={edit}
            alt={EDIT}
            title={EDIT}
            src={require("../assets/pen.svg").default}
          />
        )}
        {remove && (
          <img
            onClick={remove}
            alt={REMOVE}
            title={REMOVE}
            src={require("../assets/times.svg").default}
          />
        )}
        {hide && (
          <img
            onClick={hide}
            alt={HIDE}
            title={HIDE}
            src={require("../assets/eye-slash.svg").default}
          />
        )}

        {add && (
          <img
            onClick={add}
            alt="add"
            title={ADD}
            src={require("../assets/plus.svg").default}
          />
        )}
      </div>
    </>
  );
}
