import { CASH_TOTAL, PAYPAL_TOTAL, TOTAL_PRICE } from "language";
import React from "react";

export default function PricesSumBox({ cashSum, paypalSum }) {
  return (
    <div className="price-box">
      <div className="__row">
        <span className="__col">{CASH_TOTAL}</span>
        <span className="__col">{"€" + parseFloat(cashSum).toFixed(2)}</span>
      </div>
      <div className="__row">
        <span className="__col">{PAYPAL_TOTAL}</span>
        <span className="__col">{"€" + parseFloat(paypalSum).toFixed(2)}</span>
      </div>

      <div className="__row --total">
        <span className="__col">{TOTAL_PRICE}</span>
        <span className="__col">
          {"€" + parseFloat(paypalSum + cashSum).toFixed(2)}
        </span>
      </div>
    </div>
  );
}
