import React from "react";
import { useSelector } from "react-redux";
import NewSize from "../Sizes/NewSize";
import Size from "../Sizes/Size";
import MealHeader from "./MealHeader";

const Meal = React.memo(function ({ meal }) {
  const sizes = useSelector(state => state.sizes.filter(size => size.meal === meal.id))
  return (
    <li className="__meal">
      <MealHeader meal={meal} />
      <ul className="__sizes">
        {sizes.map((size) => (
          <Size key={size.id} size={size} />
        ))}
      </ul>
      <NewSize mealId={meal.id} />
    </li>
  );
});

export default Meal;
