import React, { useState } from "react";
import {
  ADD,
  ARE_YOU_SURE,
  EMAIL,
  PASSWORD,
  REMOVE,
  STAFF,
  USERNAME,
} from "../../../language";
import Accordion from "../../../partials/Accordion";
import Button from "../../../partials/Button";
import TextInput from "../../../partials/TextInput";
import { useStaff } from "../Context";

export default function Staffs() {
  const { staff, addStaff, removeStaff } = useStaff();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const onAdd = (e) => {
    e.preventDefault();
    addStaff({
      username,
      password,
      email,
    });
    setUsername("");
    setPassword("");
    setEmail("");
  };
  const onRemove = (id) => {
    if (window.confirm(ARE_YOU_SURE)) {
      removeStaff(id);
    }
  };

  return (
    <div className="mgb-1">
      <Accordion title={STAFF}>
        <div className="__staff">
          <form onSubmit={onAdd} className="__add">
            <TextInput
              setContextValue={(value) => setUsername(value)}
              name={USERNAME}
              initialValue={username}
              placeholder={USERNAME}
            />
            <TextInput
              setContextValue={(value) => setEmail(value)}
              initialValue={email}
              name={EMAIL}
              placeholder={EMAIL}
            />
            <TextInput
              setContextValue={(value) => setPassword(value)}
              initialValue={password}
              name={PASSWORD}
              placeholder={PASSWORD}
              type="password"
            />
            <Button>{ADD + " " + STAFF}</Button>
          </form>
          {staff.length > 0 && (
            <table>
              <thead>
                <th>{USERNAME}</th>
                <th>{EMAIL}</th>
                <th></th>
              </thead>
              <tbody>
                {staff.map((staff) => (
                  <tr key={staff.id}>
                    <td>{staff.username}</td>
                    <td>{staff.email}</td>

                    <td className="__remove">
                      <Button onClick={() => onRemove(staff.id)}>
                        {REMOVE}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </Accordion>
    </div>
  );
}
