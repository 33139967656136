import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth, useLoading } from "../../../Contexts";
import { useEditMode } from "../../../ContextsComponent/EditMode";
import { initilizeResources } from "./initResources";
import { saveResources } from "./saveResources";
import { DragDropContext } from "react-beautiful-dnd";
import { reorderCategory } from "../store/slices/categoriesSlice";

const Context = createContext();
export const useController = () => useContext(Context);

export default function Controller({ children }) {
  const [remoteCategories, setRemoteCategories] = useState([]);
  const categories = useSelector((state) => state.categories);

  const [remoteMeals, setRemoteMeals] = useState([]);
  const meals = useSelector((state) => state.meals);

  const [remoteSizes, setRemoteSizes] = useState([]);
  const sizes = useSelector((state) => state.sizes);

  const [remoteAddons, setRemoteAddons] = useState([]);
  const addons = useSelector((state) => state.addons);

  const [remoteAddonGroups, setRemoteAddonGroups] = useState([]);
  const addonGroups = useSelector((state) => state.addonGroups);

  const dispatch = useDispatch();
  const { setLoading } = useLoading();
  const { token } = useAuth();
  const { setStatusMessage } = useEditMode();

  const [serverIds, setServerIds] = useState({});

  useEffect(() => {
    initilizeResources({
      token,
      setLoading,
      dispatch,
      setRemoteCategories,
      setRemoteMeals,
      setRemoteSizes,
      setRemoteAddonGroups,
      setRemoteAddons,
    });
    // eslint-disable-next-line
  }, []);

  const saveChanges = () => {
    saveResources({
      token,
      setLoading,
      categories,
      meals,
      sizes,
      addons,
      addonGroups,
      remoteCategories,
      remoteMeals,
      remoteSizes,
      remoteAddons,
      remoteAddonGroups,
      setStatusMessage,
      serverIds,
      setServerIds,
    }).then(() => {
      setRemoteCategories([...categories]);
      setRemoteMeals([...meals]);
      setRemoteSizes([...sizes]);
      setRemoteAddonGroups([...addonGroups]);
      setRemoteAddons([...addons]);
    });
  };

  const onDragEnd = (result) => {
    const { destination, draggableId, source } = result;
    if (!destination || !source || !draggableId) {
      return;
    }
    if (destination.droppableId === source.droppableId) {
      dispatch(
        reorderCategory({
          source: source.index,
          destination: destination.index,
        })
      );
    }
  };



  return (
    <Context.Provider value={{ saveChanges }}>
      <DragDropContext onDragEnd={onDragEnd}>{children}</DragDropContext>
    </Context.Provider>
  );
}
