import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

export const sizesSlice = createSlice({
  name: "sizes",
  initialState: [],
  reducers: {
    setSizes: (state, { payload }) => {
      return [...payload];
    },
    addSize: (state, { payload }) => {
      state.push({
        ...payload.size,
        hidden: false,
        id: uuidv4(),
        priority: state.length,
      });
    },
    removeSize: (state, { payload }) => {
      return state.filter((size) => payload.id !== size.id);
    },
    updateSize: (state, { payload }) => {
      const size = state.find((_size) => _size.id === payload.id);
      size.name = payload.name;
      size.price = payload.price;
    },
  },
});

export default sizesSlice.reducer;

export const { setSizes, addSize, removeSize, updateSize } = sizesSlice.actions;
