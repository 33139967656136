import React, { useState } from "react";
import Button from "../../../partials/Button";
import TextInput from "../../../partials/TextInput";
import { ACTION_ADD, ACTION_UPDATE } from "../constants";
import { useEditMode, useZones } from "../context";
import InputGroup from "../../../partials/InputGroup";
import { CANCEL, FREE_MIN_AMOUNT, MIN_ORDER_PRICE, NAME, PRICE, SAVE } from "../../../language";

export default function NewZone({ setEditing, zone = {}, update = false }) {
  const [name, setName] = useState(zone["name"] || "");
  const [price, setPrice] = useState(zone["price"] || 0);
  const [freeAmount, setFreeAmount] = useState(zone["min_free_amount"] || 0);
  const [min_order_price, setMin_order_price] = useState(zone["min_order_price"] || 0);
  const { setZones } = useZones();
  const { editMode, forceRerender } = useEditMode();
  const addZone = (e) => {
    e.preventDefault();
    update
      ? setZones({
          type: ACTION_UPDATE,
          name,
          id: zone["id"],
          price,
          min_free_amount: freeAmount,
          min_order_price: min_order_price,
        })
      : setZones({
          type: ACTION_ADD,
          zone: {
            name,
            price,
            min_order_price: min_order_price,
          },
        });
    forceRerender();
    setEditing(false);
  };

  return (
    <form onSubmit={addZone} className="__add-form">
      <fieldset disabled={!editMode}>
        <TextInput
          name={NAME}
          placeholder={NAME}
          setContextValue={(value) => setName(value)}
          initialValue={name}
        />
        <InputGroup>
          <TextInput
            size={3}
            name={PRICE}
            placeholder={PRICE}
            type="number"
            min={0}
            setContextValue={(value) => setPrice(value)}
            initialValue={price}
          />
            <TextInput
              size={3}
              name={MIN_ORDER_PRICE}
              placeholder={MIN_ORDER_PRICE}
              type="number"
              min={0}
              setContextValue={(value) => setMin_order_price(value)}
              initialValue={min_order_price}
            />
          <TextInput
            size={4}
            name={FREE_MIN_AMOUNT}
            placeholder={FREE_MIN_AMOUNT}
            type="number"
            min={0}
            setContextValue={(value) => setFreeAmount(value)}
            initialValue={freeAmount}
          />
        </InputGroup>
        <div className="__btns">
          <Button type="submit">{SAVE}</Button>
          <Button onClick={() => setEditing(false)}>{CANCEL}</Button>
        </div>
      </fieldset>
    </form>
  );
}
