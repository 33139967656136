import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { useEditMode } from "../../../../../ContextsComponent/EditMode";
import Accordion from "../../../../../partials/Accordion";
import Meal from "../Meals/Meal";
import NewMeal from "../Meals/NewMeal";
import CategoryHeader from "./CategoryHeader";

const Category = React.memo(function ({ category, index }) {
  const { name, id } = category;
  const meals = useSelector((state) =>
    state.meals.filter((meal) => meal.category === id)
  );
  const {editMode} = useEditMode()
  return (
    <Draggable isDragDisabled={!editMode} draggableId={"" + id} index={index}>
      {(provided) => (
        <div
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <Accordion opened={false} title={name}>
            <div className="__category">
              <CategoryHeader category={category} />
              <ul className="__meals">
                {meals &&
                  meals.map((meal) => <Meal key={meal.id} meal={meal} />)}
              </ul>
              <NewMeal categoryId={id} />
            </div>
          </Accordion>
        </div>
      )}
    </Draggable>
  );
});

export default Category;
