import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  ADD,
  CANCEL,
  DESCRIPTION,
  IMAGE,
  MIN_ORDER_PRICE,
  SAVE,
  TITLE,
  VALUE,
} from "../../../../language";
import Button from "../../../../partials/Button";
import ImageInput from "../../../../partials/ImageInput";
import Textarea from "../../../../partials/Textarea";
import TextInput from "../../../../partials/TextInput";
import {
  addOrderTotalOffer,
  updateOrderTotalOffer,
} from "../../store/slices/OrderTotalOfferSlice";

export default function NewOrderTotalOffer({
  setEditing = () => {},
  update = false,
  offer = {},
}) {
  const [adding, setAdding] = useState(update);
  const [description, setDescription] = useState(offer.description || "");
  const [title, setTitle] = useState(offer.title || "");
  const [image, setImage] = useState(offer.image || "");
  const [value, setValue] = useState(+offer.value || "");
  const [min_price, setMinPrice] = useState(+offer.min_price || "");
  const dispatch = useDispatch();

  const handleCancle = () => {
    setEditing(false);
    setAdding(false);
  };

  const handleAdd = (e) => {
    e.preventDefault();

    const orderTotalOfferBody = {
      value,
      description,
      title,
      image,
      min_price,
    };

    !update
      ? dispatch(
          addOrderTotalOffer({
            orderTotalOffer: orderTotalOfferBody,
          })
        )
      : dispatch(
          updateOrderTotalOffer({
            id: offer.id,
            ...orderTotalOfferBody,
          })
        );

    setAdding(false);
    setEditing(false);
  };

  return (
    <>
      {adding && (
        <form onSubmit={handleAdd} className="__new form">
          <TextInput
            name={MIN_ORDER_PRICE}
            type="number"
            placeholder={MIN_ORDER_PRICE}
            setContextValue={(value) => {
              setMinPrice(+value);
            }}
            initialValue={min_price}
            required={true}
          />
          <TextInput
            name={VALUE}
            type="number"
            placeholder={VALUE}
            setContextValue={(value) => {
              setValue(+value);
            }}
            initialValue={value}
            required={true}
            step={1}
          />
          <Textarea
            name={TITLE}
            initialValue={title}
            setContextValue={(value) => {
              setTitle(value);
            }}
            required={true}
          ></Textarea>
          <Textarea
            name={DESCRIPTION}
            initialValue={description}
            setContextValue={(value) => {
              setDescription(value);
            }}
            required={true}
          ></Textarea>
          <ImageInput
            title={IMAGE}
            setContextValue={(value) => {
              setImage(value);
            }}
            initial={[image]}
            multiple={false}
            required={true}
          />

          <div className="__btns">
            <Button type="submit">{SAVE}</Button>
            <Button onClick={handleCancle}>{CANCEL}</Button>
          </div>
        </form>
      )}
      {!update && (
        <Button onClick={() => setAdding(true)} disabled={adding}>
          {ADD}
        </Button>
      )}
    </>
  );
}
