import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { CANCEL, NAME, PRICE, SAVE } from "../../../../language";
import Button from "../../../../partials/Button";
import InputGroup from "../../../../partials/InputGroup";
import TextInput from "../../../../partials/TextInput";

import { addAddon, updateAddon } from "../../store/slices/addonsSlice";

const NewAddon = React.memo(function ({
  addon = {},
  groupId,
  update = false,
  setAdding = () => {},
}) {
  const [name, setName] = useState(addon.name || "");
  const [price, setPrice] = useState(addon.price || 0);

  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    !update
      ? dispatch(
          addAddon({
            addon: {
              name,
              price,
              group: groupId,
            },
          })
        )
      : dispatch(
          updateAddon({
            name,
            price,
            id: addon.id,
          })
        );
    setAdding(false);
  };

  return (
    <form onSubmit={handleSubmit} className="__add">
      <fieldset className="__form">
        <InputGroup>
          <TextInput
            size={6}
            name={NAME}
            placeholder={NAME}
            setContextValue={(value) => setName(value)}
            initialValue={name}
          ></TextInput>
          <TextInput
            size={4}
            name={PRICE}
            placeholder={PRICE}
            setContextValue={(value) => setPrice(value)}
            type="number"
            min="0"
            initialValue={price}
          ></TextInput>
        </InputGroup>
        <div className="__btns">
          <Button type="submit">{SAVE}</Button>
          <Button
            onClick={() => {
              setAdding(false);
            }}
          >
            {CANCEL}
          </Button>
        </div>
      </fieldset>
    </form>
  );
});
export default NewAddon;
