import { COUPONS } from "language";
import Accordion from "partials/Accordion";
import React from "react";
import { useSelector } from "react-redux";
import Coupon from "./Coupon";
import NewCoupon from "./NewCoupon";

export default function Coupons() {
  const coupons = useSelector((state) => state.coupons);
  return (
    <div className="__coupons">
      <Accordion title={COUPONS}>
        <ul className="__items">
          {coupons.map((coupon) => (
            <li key={coupon.id} className="__item">
              <Coupon  coupon={coupon} />
            </li>
          ))}
        </ul>
        <NewCoupon />
      </Accordion>
    </div>
  );
}
