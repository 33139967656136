import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import "antd/dist/antd.css";
import "./styles/style.scss";
import "animate.css";
import SideBar from "./layout/SideBar";
import Basics from "./routes/Basics";
import FoodMenu from "./routes/FoodMenu";
import LogIn from "./routes/LogIn";
import ProtectedRoute from "./partials/ProtectedRoute";
import { useAuth, useLoading } from "./Contexts";
import Services from "./routes/Services";
import Account from "./routes/Account";
import Orders from "./routes/Orders";
import Loading from "./partials/Loading";
import Offers from "./routes/Offers";
import Emails from "./routes/Emails";
import Users from "./routes/Users";
import Staff from "./routes/Staff";
import Tables from "./routes/Tables";
import Schedule from "./routes/Schedule";
import Reviews from "./routes/Reviews";
import ThemeColors from "routes/Theme";
import Reservations from "routes/Reservations";
import Jobs from "routes/Jobs";
import SEOPages from "routes/SEOPages";

function App() {
  const { isLoggedIn } = useAuth();
  const { loading } = useLoading();

  return (
    <div className={`${loading ? "loading" : ""}`}>
      <Router>
        {isLoggedIn && <SideBar />}
        <main className={` ${isLoggedIn ? "main-container" : ""} `}>
          {loading && <Loading abs={true} />}
          <Switch>
            <Route exact path="/login" component={LogIn} />
            <ProtectedRoute exact path="/" component={<Basics />} />
            <ProtectedRoute exact path="/basics" component={<Basics />} />
            <ProtectedRoute exact path="/menu" component={<FoodMenu />} />
            <ProtectedRoute exact path="/services" component={<Services />} />
            <ProtectedRoute exact path="/hours" component={<Schedule />} />
            <ProtectedRoute exact path="/account" component={<Account />} />
            <ProtectedRoute exact path="/orders" component={<Orders />} />
            <ProtectedRoute exact path="/offers" component={<Offers />} />
            <ProtectedRoute exact path="/emails" component={<Emails />} />
            <ProtectedRoute exact path="/users" component={<Users />} />
            <ProtectedRoute exact path="/staff" component={<Staff />} />
            <ProtectedRoute exact path="/tables" component={<Tables />} />
            <ProtectedRoute exact path="/reviews" component={<Reviews />} />
            <ProtectedRoute exact path="/theme" component={<ThemeColors />} />
            <ProtectedRoute
              exact
              path="/reservations"
              component={<Reservations />}
            />
            <ProtectedRoute exact path="/jobs" component={<Jobs />} />
            <ProtectedRoute exact path="/seo" component={<SEOPages />} />
          </Switch>
        </main>
      </Router>
    </div>
  );
}

export default App;
