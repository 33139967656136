import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

export const orderGiftsSlice = createSlice({
  name: "orderGifts",
  initialState: [],
  reducers: {
    setOrderGifts: (state, { payload }) => {
      return [...payload];
    },
    addOrderGift: (state, { payload }) => {
      state.push({
        ...payload.orderGift,
        id: uuidv4(),
      });
    },
    removeOrderGift: (state, { payload }) => {
      return state.filter((orderGift) => payload.id !== orderGift.id);
    },
    updateOrderGift: (state, { payload }) => {
      const orderGift = state.find(
        (_orderGift) => _orderGift.id === payload.id
      );
      orderGift.description = payload.description ?? orderGift.description;
      orderGift.min_price = payload.min_price ?? orderGift.min_price;
      orderGift.max_price = payload.max_price ?? orderGift.max_price;
      orderGift.image = payload.image ?? orderGift.image;
    },
  },
});

export default orderGiftsSlice.reducer;

export const { setOrderGifts, addOrderGift, removeOrderGift, updateOrderGift } =
  orderGiftsSlice.actions;
