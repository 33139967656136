import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEditMode } from "../../../ContextsComponent/EditMode";
import {
  APP_STORE,
  FACEBOOK,
  GOOGLE_PLAY,
  INSTAGRAM,
  SOCIAL_LINKS,
  TIKTOK,
  YOUTUBE,
} from "../../../language";
import Accordion from "../../../partials/Accordion";
import TextInput from "../../../partials/TextInput";
import { informationSetAttrib } from "../store/slices/InformationSlice";

export default function SocialLinks() {
  const { editMode } = useEditMode();
  const socialLinks = useSelector((state) => state.information.social_links);
  const [facebook, setFacebook] = useState("");
  const [youtube, setYoutube] = useState("");
  const [tiktok, setTiktok] = useState("");
  const [instagram, setInstagram] = useState("");
  const [googlePlay, setGooglePlay] = useState("");
  const [appStore, setAppStore] = useState("");

  const dispatch = useDispatch();

  const getNetworkUrl = useCallback(
    (network) => {
      const _network = socialLinks.find((link) => link.network === network);
      return _network ? _network.url : "";
    },
    [socialLinks]
  );

  useEffect(() => {
    setFacebook(() => getNetworkUrl(FACEBOOK));
    setYoutube(() => getNetworkUrl(YOUTUBE));
    setTiktok(() => getNetworkUrl(TIKTOK));
    setInstagram(() => getNetworkUrl(INSTAGRAM));
    setGooglePlay(() => getNetworkUrl(GOOGLE_PLAY));
    setAppStore(() => getNetworkUrl(APP_STORE));
  }, [socialLinks , getNetworkUrl]);

  const setSocialLink = (network, url) => {
    const link = socialLinks.find((_link) => _link.network === network);

    let newSocialLinks = [...socialLinks];

    if (link) {
      newSocialLinks = newSocialLinks.map((_link) => {
        if (_link.network === network) {
          return {
            network,
            url,
            username: "",
          };
        }
        return _link;
      });
    } else {
      newSocialLinks.push({
        network,
        username: "",
        url,
      });
    }

    dispatch(
      informationSetAttrib({
        key: "social_links",
        value: newSocialLinks,
      })
    );
  };


  return (
    <Accordion opened={false} title={SOCIAL_LINKS}>
      <form className="form">
        <fieldset disabled={!editMode}>
          <TextInput
            setContextValue={(url) => setSocialLink(FACEBOOK, url)}
            initialValue={facebook}
            name={FACEBOOK}
          />
          <TextInput
            setContextValue={(url) => setSocialLink(YOUTUBE, url)}
            initialValue={youtube}
            name={YOUTUBE}
          />
          <TextInput
            setContextValue={(url) => setSocialLink(TIKTOK, url)}
            initialValue={tiktok}
            name={TIKTOK}
          />
          <TextInput
            setContextValue={(url) => setSocialLink(INSTAGRAM, url)}
            initialValue={instagram}
            name={INSTAGRAM}
          />
          <TextInput
            setContextValue={(url) => setSocialLink(GOOGLE_PLAY, url)}
            initialValue={googlePlay}
            name={GOOGLE_PLAY}
          />
          <TextInput
            setContextValue={(url) => setSocialLink(APP_STORE, url)}
            initialValue={appStore}
            name={APP_STORE}
          />
        </fieldset>
      </form>
    </Accordion>
  );
}
