import { SEO_PAGES } from "language";
import TopBar from "partials/TopBar";
import React from "react";
import SEOPagesList from "./components/SEOPagesList";
import Context from "./Context";

function Index() {
  return (
    <>
      <TopBar title={SEO_PAGES} />
      <div className="seo  animate__animated animate__fadeIn">
        <SEOPagesList />
      </div>
    </>
  );
}

export default function SEOPages() {
  return (
    <Context>
      <Index />
    </Context>
  );
}
