import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { domain } from "../../constants";
import { useAuth, useLoading } from "../../Contexts";
import { deleteRequest, getRequest, postRequest } from "../../utils/http";

const SEOContext = createContext();

export default function Context({ children }) {
  const [seoPages, setSeoPages] = useState([]);

  const { token } = useAuth();
  const { setLoading } = useLoading();

  const addPage = (page) => {
    postRequest(domain + "settings/seo_page/", page, setLoading, token).then(
      ([data, status]) => {
        if (status === 201) {
          setSeoPages((prev) => [...prev, data]);
        }
      }
    );
  };
  const removePage = (id) => {
    deleteRequest(
      domain + "settings/seo_page/" + id + "/",
      setLoading,
      token
    ).then(() => {
      setSeoPages((prev) => prev.filter((page) => page.id !== id));
    });
  };

  useEffect(() => {
    getRequest(domain + "settings/seo_page/", setLoading, token).then(
      ([data, status]) => {
        if (status === 200) {
          setSeoPages(data);
        }
      }
    );
  }, [setLoading, token]);

  return (
    <SEOContext.Provider
      value={{
        seoPages,
        addPage,
        removePage,
      }}
    >
      {children}
    </SEOContext.Provider>
  );
}

export const useSEOPages = () => useContext(SEOContext);
