import React from "react";
import { Provider } from "react-redux";
import EditMode, { useEditMode } from "../../ContextsComponent/EditMode";
import { WORK_HOURS } from "../../language";
import TopBar from "../../partials/TopBar";
import Controller from "./Controller";
import { useController } from "./Controller";
import store from "./store/store";
import WorkHours from "./components/WorkHours";

function Index() {
  const { editMode, setEditMode, statusMessage } = useEditMode();
  const { saveChanges } = useController();
  return (
    <>
      <TopBar
        title={WORK_HOURS}
        editMode={editMode}
        setEditMode={setEditMode}
        saveChanges={saveChanges}
        message={statusMessage}
      ></TopBar>
      <div className="work-hours  animate__animated animate__fadeIn">
        <WorkHours />
      </div>
    </>
  );
}

export default function Schedule() {
  return (
    <Provider store={store}>
      <EditMode>
        <Controller>
          <Index />
        </Controller>
      </EditMode>
    </Provider>
  );
}
