import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ARE_YOU_SURE, CURRENCY } from "../../../../../language";
import DropArea from "../../../../../partials/DropArea";
import { linkAddonWithGroup } from "../../../store/slices/addonGroupsSlice";
import { removeSize } from "../../../store/slices/sizesSlice";
import { DND_LINK_ADDON, FIELD_MEALS_SIZES } from "../../../constants";
import NewSize from "./NewSize";
import AddonsTablets from "../Partials/AddonsTablets";
import Controls from "../../../../../partials/Controls";

const Size = React.memo(function ({ size }) {
  const dispatch = useDispatch();
  const [editing, setEditing] = useState(false);

  const remove = () => {
    if (!window.confirm(ARE_YOU_SURE)) return;
    dispatch(
      removeSize({
        id: size.id,
      })
    );
  };
  const linkAddonGroup = ({ itemData }) => {
    dispatch(
      linkAddonWithGroup({
        source: FIELD_MEALS_SIZES,
        sourceId: size.id,
        groupId: itemData.id,
      })
    );
  };
  return (
    <fieldset>
      <DropArea
        accept={DND_LINK_ADDON}
        onDrop={linkAddonGroup}
        effect={true}
        className="__size"
      >
        {!editing && <Controls remove={remove} edit={() => setEditing(true)} />}
        {editing ? (
          <NewSize size={size} update={true} setEditing={setEditing} />
        ) : (
          <div className="__view">
            <div className="__info">
              <p className="__text">{size.name}</p>
              <span className="__id">
                {typeof size.id === "string" ? "" : "#" + size.id}
              </span>

              <span className="price">{`${size.price} ${CURRENCY}`}</span>
            </div>
            <AddonsTablets item={size} itemType={FIELD_MEALS_SIZES} />
          </div>
        )}
      </DropArea>
    </fieldset>
  );
});
export default Size;
