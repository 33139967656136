import React from "react";
import { ACCEPT, DISABLED, ENABLED, PICKUP } from "../../../language";
import Accordion from "../../../partials/Accordion";
import Button from "../../../partials/Button";
import { useEditMode, usePickup } from "../context";

export default function Pickup() {
  const { pickup, setPickup } = usePickup();
  const { editMode } = useEditMode();

  return (
    <div className="mgb-1">
      <Accordion title={PICKUP} opened={false}>
        <div className="__pickup">
          <label>{ACCEPT + " " + PICKUP + "? "} </label>
          <Button disabled={!editMode} onClick={() => setPickup(!pickup)}>
            {pickup ? ENABLED : DISABLED}
          </Button>
        </div>
      </Accordion>
    </div>
  );
}
