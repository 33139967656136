import { ADD, ARE_YOU_SURE, AVAILBLE_JOBS, JOB_TITLE, REMOVE } from "language";
import Accordion from "partials/Accordion";
import Button from "partials/Button";
import TextInput from "partials/TextInput";
import React, { useState } from "react";
import { useJobs } from "../Context";

export default function AvailableJobs() {
  const [jobTitle, setJobTitle] = useState("");

  const { addAvailableJob, availableJobs, removeAvailableJob } = useJobs();

  const onAdd = (e) => {
    e.preventDefault();
    if (!window.confirm(ARE_YOU_SURE)) return;

    const job = { job_title: jobTitle };
    addAvailableJob(job);
    setJobTitle("");
  };
  const onRemove = (id) => {
    if (!window.confirm(ARE_YOU_SURE)) return;

    removeAvailableJob(id);
  };

  return (
    <div className="mgb-1">
      <Accordion title={AVAILBLE_JOBS} opened={true}>
        <div className="__available">
          <form onSubmit={onAdd} className="__add">
            <TextInput
              setContextValue={(value) => setJobTitle(value)}
              initialValue={jobTitle}
              name={JOB_TITLE}
              placeholder={JOB_TITLE}
            />

            <Button>{ADD}</Button>
          </form>

          <ul>
            <h2>{AVAILBLE_JOBS}</h2>
            {availableJobs.map((job,index) => (
              <li key={index} className="__job">
                <div className="__info">
                  <p className="__key">{JOB_TITLE}</p>
                  <p className="__value">{job.job_title}</p>
                </div>
                <div className="__btns">
                  <Button onClick={() => onRemove(job.id)}>{REMOVE}</Button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Accordion>
    </div>
  );
}
