import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

export const mealsSlice = createSlice({
  name: "meals",
  initialState: [],
  reducers: {
    setMeals: (state, { payload }) => {
      return [...payload];
    },
    addMeal: (state, { payload }) => {
      state.push({
        ...payload.meal,
        hidden: false,
        id: uuidv4(),
        priority: state.length,
      });
    },
    removeMeal: (state, { payload }) => {
      return state.filter((meal) => payload.id !== meal.id);
    },
    updateMeal: (state, { payload }) => {
      const meal = state.find((_meal) => _meal.id === payload.id);
      meal.name = payload.name;
      meal.description = payload.description;
      meal.price = payload.price;
      meal.additional_info = payload.additional_info;
    },

    toggleMealHide: (state, { payload }) => {
      const meal = state.find((_meal) => _meal.id === payload.id);
      meal.hidden =  !meal.hidden ;
    },
    toggleMealIsOffer: (state, { payload }) => {
      const meal = state.find((_meal) => _meal.id === payload.id);
      meal.is_offer =  !meal.is_offer ;
    },
    addMealImage: (state, { payload }) => {
      const meal = state.find((_meal) => _meal.id === payload.id);
      meal.image = payload.image;
    },
  },
});

export default mealsSlice.reducer;

export const {
  setMeals,
  addMeal,
  removeMeal,
  updateMeal,
  toggleMealHide,
  toggleMealIsOffer,
  addMealImage,
} = mealsSlice.actions;
