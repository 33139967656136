import React from "react";
import DraggableItem from "../../../partials/DraggableItem";
export default React.memo(function Cuisine({
  cuisine,
  handleClick,
  type,
  selectedCuisines,
  toggleCuisine,
  animate,
}) {
  return (
    <DraggableItem
      onDropEnd={toggleCuisine}
      type={type}
      itemData={cuisine}
      title={cuisine}
      className={`__item ${
        animate ? "animate__animated animate__pulse anim" : ""
      }`}
      onClick={({ target }) => handleClick(target)}
    >
      <span
        title={cuisine}
        className={`__text${
          selectedCuisines.includes(cuisine) && type !== "cuisineSelected"
            ? " --selected"
            : ""
        }`}
      >
        {cuisine}
      </span>
    </DraggableItem>
  );
});
