import React from "react";
import { useSelector } from "react-redux";
import { BANNER_OFFERS } from "../../../../language";
import Accordion from "../../../../partials/Accordion";
import BannerOffer from "./BannerOffer";
import NewBannerOffer from "./NewBannerOffer";

export default function BannerOffers() {
  const bannerOffers = useSelector((state) => state.bannerOffers);

  return (
    <div className="__banner">
      <Accordion title={BANNER_OFFERS}>
        <ul className="__list">
          {bannerOffers &&
            bannerOffers.map((offer) => (
              <li key={offer.id} className="__item">
                <BannerOffer offer={offer} />
              </li>
            ))}
        </ul>
        <NewBannerOffer />
      </Accordion>
    </div>
  );
}
