import { createSlice } from "@reduxjs/toolkit";
import { WEEKDAYS } from "../../../language";

export const DEFAULT_FROM_TIME = "00:00:00";
export const DEFAULT_TO_TIME = "20:00:00";
export const TYPE_OPEN = 1;
export const TYPE_DELIVERY = 2;
export const TYPE_PICKUP = 3;
export const TYPE_CLOSED = 4;

export const scheduleSlice = createSlice({
  name: "schedule",
  initialState: {
    one: {
      raw: [],
      formatted: [],
    },
    two: {
      raw: [],
      formatted: [],
    },
  },
  reducers: {
    setScheduleOne: (state, { payload }) => {
      state.one = {
        old: [...payload],
        raw: buildIfFirstTime(payload),
        formatted: buildFullSchedule(payload),
      };
    },
    setScheduleTwo: (state, { payload }) => {
      state.two = {
        old: [...payload],
        raw: buildIfFirstTime(payload),
        formatted: buildFullSchedule(payload),
      };
    },
    updateSchedule: (state, { payload }) => {
      const { weekday, type, from, to, scheduleNumber } = payload;

      const rawDay = state[scheduleNumber].raw.find(
        (_day) => +_day.weekday === +weekday && _day.opening_type === type
      );
      rawDay.from_time = from;
      rawDay.to_time = to;
      const formattedDay = state[scheduleNumber].formatted.find(
        (_day) => +_day.weekday === +weekday
      );
      if (type === TYPE_OPEN) {
        formattedDay.from_time = from;
        formattedDay.to_time = to;
      } else if (type === TYPE_PICKUP) {
        formattedDay.pickup.from_time = from;
        formattedDay.pickup.to_time = to;
      } else {
        formattedDay.delivery.from_time = from;
        formattedDay.delivery.to_time = to;
      }
    },
    toggleClose: (state, { payload }) => {
      const { weekday, scheduleNumber, isClosed } = payload;
      const rawDay = state[scheduleNumber].raw.filter((_day) => {
        return (
          _day.weekday === +weekday &&
          _day.opening_type === (isClosed ? TYPE_CLOSED : TYPE_OPEN)
        );
      })[0];
      rawDay.opening_type = isClosed ? TYPE_OPEN : TYPE_CLOSED;
      const formattedDay = state[scheduleNumber].formatted.find(
        (_day) => +_day.weekday === +weekday
      );
      formattedDay.closed = !formattedDay.closed;
    },
  },
});

export default scheduleSlice.reducer;

export const { setScheduleOne, setScheduleTwo, updateSchedule, toggleClose } =
  scheduleSlice.actions;

const buildFullSchedule = (schedule) => {
  const fullSchedule = [];

  Object.keys(WEEKDAYS).forEach((weekday) => {
    const closed =
      schedule.filter(
        (day) => day.weekday === +weekday && day.opening_type === 4
      ).length > 0;

    const opening =
      schedule.filter(
        (day) => day.weekday === +weekday && day.opening_type === 1
      )[0] || {};
    const pickup =
      schedule.filter(
        (day) => day.weekday === +weekday && day.opening_type === 3
      )[0] || {};
    const delivery =
      schedule.filter(
        (day) => day.weekday === +weekday && day.opening_type === 2
      )[0] || {};

    const day = {
      weekday,
      closed,
      from_time: opening.from_time ?? DEFAULT_FROM_TIME,
      to_time: opening.to_time ?? DEFAULT_TO_TIME,
      pickup: {
        from_time: pickup.from_time ?? DEFAULT_FROM_TIME,
        to_time: pickup.to_time ?? DEFAULT_TO_TIME,
      },
      delivery: {
        from_time: delivery.from_time ?? DEFAULT_FROM_TIME,
        to_time: delivery.to_time ?? DEFAULT_TO_TIME,
      },
    };

    fullSchedule.push(day);
  });

  return fullSchedule;
};

const buildIfFirstTime = (payload) => {
  if (payload.length > 0) return payload;
  const raw = [];
  for (let weekday = 1; weekday < 8; weekday++) {
    raw.push({
      weekday,
      opening_type: 1,
      from_time: DEFAULT_FROM_TIME,
      to_time: DEFAULT_TO_TIME,
    });
    raw.push({
      weekday,
      opening_type: 2,
      from_time: DEFAULT_FROM_TIME,
      to_time: DEFAULT_TO_TIME,
    });
    raw.push({
      weekday,
      opening_type: 3,
      from_time: DEFAULT_FROM_TIME,
      to_time: DEFAULT_TO_TIME,
    });
  }
  return raw;
};
