import {
  ADD,
  ARE_YOU_SURE,
  PARAGRAPH,
  REMOVE,
  SEO_PAGES,
  TITLE,
} from "language";
import Accordion from "partials/Accordion";
import Button from "partials/Button";
import Textarea from "partials/Textarea";
import TextInput from "partials/TextInput";
import React, { useState } from "react";
import { useSEOPages } from "../Context";

export default function SEOPagesList() {
  const { seoPages, addPage, removePage } = useSEOPages();

  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");

  const onAdd = (e) => {
    e.preventDefault();
    if (!window.confirm(ARE_YOU_SURE)) return;
    addPage({ title, body });
    setTitle("");
    setBody("");
  };

  const onRemove = (id) => {
    if (!window.confirm(ARE_YOU_SURE)) return;
    removePage(id);
  };

  return (
    <div className="mgb-1 ">
      <Accordion title={SEO_PAGES} opened={true}>
        <div className="__body">
          <form onSubmit={onAdd} className="__add">
            <TextInput
              setContextValue={(value) => setTitle(value)}
              initialValue={title}
              name={TITLE}
              placeholder={TITLE}
            />
            <Textarea
              setContextValue={(value) => setBody(value)}
              initialValue={body}
              name={PARAGRAPH}
              placeholder={PARAGRAPH}
            />

            <Button>{ADD}</Button>
          </form>

          <ul className="__pages">
            {seoPages.map((page) => (
              <li key={page.id}>
                <table className="__page">
                  <tbody>
                    <tr>
                      <td>{TITLE}</td>
                      <td>{page.title}</td>
                    </tr>
                    <tr>
                      <td>{PARAGRAPH}</td>
                      <td>{page.body}</td>
                    </tr>

                    <Button onClick={() => onRemove(page.id)}> {REMOVE}</Button>
                  </tbody>
                </table>
              </li>
            ))}
          </ul>
        </div>
      </Accordion>
    </div>
  );
}
