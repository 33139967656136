import React, { useEffect, useState } from "react";
import { useDrop } from "react-dnd";

export default function DropArea({
  accept,
  className = "",
  children,
  effect = false,
  onDrop = () => {},
  fixNotRerenderingIssue = 0,
  ...props
}) {
  const [{ canDrop }, drop] = useDrop(() => ({
    accept: accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const [hoverEffect, setHoverEffect] = useState("");

  useEffect(() => {
    if (canDrop && effect ) {
      setHoverEffect(" droppable-area");
    } else {
      setHoverEffect("");
    }
  }, [canDrop, effect]);

  const [, setWatchDog] = useState(fixNotRerenderingIssue);
  useEffect(() => {
    setWatchDog(fixNotRerenderingIssue);
  }, [fixNotRerenderingIssue]);

  return (
    <div {...props} className={className + hoverEffect} ref={drop}>
      {children}
    </div>
  );
}
