export const getRequest = async (url, setLoading = () => {}, token = "") => {
  setLoading(true);
  try {
    const requestHeaders = {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (token) {
      requestHeaders.headers["Authorization"] = `Bearer ${token}`;
    }

    const response = await fetch(url, requestHeaders);
    const status = response.status;
    const data = await response.json();
    setLoading(false);
    return [data ? data : [], status ? status : 0];
  } catch (error) {
    setLoading(false);
  }
};
export const postRequest = async (
  url,
  body,
  setLoading = () => {},
  token = ""
) => {
  if (Array.isArray(body) && body.length < 1) {
    return [[], 201];
  }
  setLoading(true);
  try {
    const headers = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    };
    const response = await fetch(url, headers);
    const status = response.status;
    let data = await response.text();
    if (data.length) data = JSON.parse(data);

    setLoading(false);
    return [data ? data : [], status ? status : 0];
  } catch (err) {
    console.log(err);
    setLoading(false);
    return [[], 0];
  }
};
export const patchRequest = async (
  url,
  body,
  setLoading = () => {},
  token = ""
) => {
  setLoading(true);
  try {
    const headers = {
      method: "PATCH",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    };
    const response = await fetch(url, headers);
    const status = response.status;
    const data = await response.json();
    setLoading(false);
    return [data ? data : [], status ? status : 0];
  } catch (err) {
    setLoading(false);
  }
};
export const deleteRequest = async (url, setLoading = () => {}, token = "") => {
  setLoading(true);
  try {
    const headers = {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, headers);
    const status = response.status;
    const data = await response.json();
    setLoading(false);
    return [data ? data : [], status ? status : 0];
  } catch (err) {
    console.log(err);
    setLoading(false);
  }
};
