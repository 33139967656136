import Textarea from "partials/Textarea";
import React from "react";
import {
  ALLOW_ANON,
  ALLOW_CREATING_USERS,
  ALLOW_JOBS,
  ALLOW_REVIEWS,
  DISABLED,
  ENABLED,
  MAINTENANCE_MODE,
  MAINTENANCE_MODE_MSG,
  OTHERS,
} from "../../../language";
import Accordion from "../../../partials/Accordion";
import Button from "../../../partials/Button";
import { useEditMode, useOthers } from "../context";

export default function Others() {
  const {
    allowAnon,
    setAllowAnon,
    allowUsersCreation,
    setAllowUsersCreation,
    maintenanceMode,
    setMaintenanceMode,
    maintenanceMSG,
    setMaintenanceMsg,
    allowReviews,
    setAllowReviews,
    allowJobs,
    setAllowJobs,
  } = useOthers();
  const { editMode } = useEditMode();

  return (
    <div className="mgb-1">
      <Accordion title={OTHERS} opened={false}>
        <div className="__others">
          <label>{ALLOW_CREATING_USERS} </label>
          <Button
            disabled={!editMode}
            onClick={() => setAllowUsersCreation(!allowUsersCreation)}
          >
            {allowUsersCreation ? ENABLED : DISABLED}
          </Button>
        </div>
        {allowUsersCreation && (
          <>
            <div className="__others">
              <label>{ALLOW_REVIEWS} </label>
              <Button
                disabled={!editMode}
                onClick={() => setAllowReviews(!allowReviews)}
              >
                {allowReviews ? ENABLED : DISABLED}
              </Button>
            </div>
            <div className="__others">
              <label>{ALLOW_JOBS} </label>
              <Button
                disabled={!editMode}
                onClick={() => setAllowJobs(!allowJobs)}
              >
                {allowJobs ? ENABLED : DISABLED}
              </Button>
            </div>
            <div className="__others">
              <label>{ALLOW_ANON} </label>
              <Button
                disabled={!editMode}
                onClick={() => setAllowAnon(!allowAnon)}
              >
                {allowAnon ? ENABLED : DISABLED}
              </Button>
            </div>
          </>
        )}
        <div className="__others">
          <label>{MAINTENANCE_MODE} </label>
          <Button
            disabled={!editMode}
            onClick={() => setMaintenanceMode(!maintenanceMode)}
          >
            {maintenanceMode ? ENABLED : DISABLED}
          </Button>

          {maintenanceMode && (
            <Textarea
              name={MAINTENANCE_MODE_MSG}
              setContextValue={setMaintenanceMsg}
              initialValue={maintenanceMSG}
              disabled={!editMode}
              type="text"
            />
          )}
        </div>
      </Accordion>
    </div>
  );
}
