import React from "react";
import { EMAIL_CONFIG } from "../../language";

import TopBar from "../../partials/TopBar";
import AppNotifications from "./components/AppNotifications";
import EmailConfig from "./components/EmailConfig";
import EmailNotifications from "./components/EmailNotifications";
import Context, { useEditMode } from "./context";

function Index() {
  const { editMode, setEditMode, saveChanges, statusMessage } = useEditMode();

  return (
    <div className="email-config">
      <TopBar
        title={EMAIL_CONFIG}
        editMode={editMode}
        setEditMode={setEditMode}
        saveChanges={saveChanges}
        message={statusMessage}
      ></TopBar>
      <div className="mgb-1">
        <EmailConfig editMode={editMode} />
        <br></br>
        <EmailNotifications editMode={editMode} />
        <br></br>
        <AppNotifications />
        
      </div>
    </div>
  );
}

export default function Emails() {
  return (
    <Context>
      <Index />
    </Context>
  );
}
