import React from "react";
import { useDrag } from "react-dnd";

const DraggableItem = React.memo(function ({
  type,
  itemData,
  onDropEnd = () => {},
  children,
  ...props
}) {
  const [{ isDragging }, drag] = useDrag(() => ({
    item: {
      type: type,
      itemData,
    },
    type: type,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        onDropEnd(item);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));
  return (
    <div style={isDragging ? { opacity: "0.5" } : {}} {...props} ref={drag}>
      {children}
    </div>
  );
});
export default DraggableItem;
