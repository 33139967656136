import {
  ACCEPTED,
  CANCELLED,
  CASH,
  DELIVERED,
  DELIVERING,
  DELIVERY,
  DINE_IN,
  DONE,
  PAYPAL,
  PENDING,
  PENDING_DELIVERING,
  PENDING_PICKUP,
  PICKUP,
  PREORDER,
  REJECTED,
} from "../../language";

export function formatData(data, sortByPrice) {
  function formatType(type) {
    switch (type) {
      case 1:
        return DELIVERY;
      case 2:
        return PICKUP;
      case 3:
        return DINE_IN;
      default:
        break;
    }
  }
  function formatName(fname, lname) {
    return fname + " " + lname;
  }
  function formatPrice(price) {
    return "€" + price;
  }
  function formatTime(time) {
    if (!time) return "N/A";
    return new Date(time).toLocaleString();
  }
  function formatPayment(payment) {
    if (payment.type === 1) return CASH;
    return PAYPAL;
  }
  function formatPaypal(payment) {
    if (payment.type === 1) return "";
    return payment.order_id;
  }
  function formatStatus(status, type) {
    switch (status) {
      case 1:
        return PENDING;
      case 2:
        return REJECTED;
      case 3:
        return ACCEPTED;
      case 4:
        if (type === 1) {
          return PENDING_DELIVERING;
        }
        return PENDING_PICKUP;
      case 5:
        return DELIVERING;
      case 6:
        return DELIVERED;
      case 7:
        return DONE;
      case 8:
        return CANCELLED;

      default:
        break;
    }
  }

  let formatted = data.map((element) => ({
    ...element,
    type:
      (element.is_preorder ? PREORDER : "") + " " + formatType(element.type),
    fullName: formatName(element.first_name, element.last_name),
    created_at: formatTime(element.created_at),
    accepted_at: formatTime(element.accepted_at),
    payment: formatPayment(element.payment),
    paypal_order_id: formatPaypal(element.payment),
    status: formatStatus(element.status , element.type),
  }));

  if (sortByPrice === "asc") {
    formatted = formatted.sort(function (a, b) {
      return +a.total_price - +b.total_price;
    });
  }
  if (sortByPrice === "desc") {
    formatted = formatted.sort(function (a, b) {
      return +b.total_price - +a.total_price;
    });
  }

  formatted = formatted.map((element) => ({
    ...element,
    total_price: formatPrice(element.total_price),
  }));

  return formatted;
}

export const formatFitlers = (filterModel) => {
  let filters = "";

  if (filterModel.created_at) {
    const from = filterModel.created_at.dateFrom
      ? `created_at__gte=${filterModel.created_at.dateFrom}`
      : "";
    const to = filterModel.created_at.dateTo
      ? `created_at__lte=${
          filterModel.created_at.dateTo.split(" ")[0] + " 23:59:59"
        }`
      : "";
    filters += from + "&" + to;
  }

  if (filterModel.id) {
    const idFilter = `id=${filterModel.id.filter}`;
    filters += filters === "" ? idFilter : "&" + idFilter;
  }
  if (filterModel.slug) {
    const _filter = `slug=${filterModel.slug.filter}`;
    filters += filters === "" ? _filter : "&" + _filter;
  }
  if (filterModel.first_name) {
    const _filter = `first_name__icontains=${filterModel.first_name.filter}`;
    filters += filters === "" ? _filter : "&" + _filter;
  }
  if (filterModel.last_name) {
    const _filter = `last_name__icontains=${filterModel.last_name.filter}`;
    filters += filters === "" ? _filter : "&" + _filter;
  }
  if (filterModel.email) {
    const _filter = `email__icontains=${filterModel.email.filter}`;
    filters += filters === "" ? _filter : "&" + _filter;
  }
  if (filterModel.paypal_order_id) {
    const _filter = `payment__order_id=${filterModel.paypal_order_id.filter}`;
    filters += filters === "" ? _filter : "&" + _filter;
  }

  return filters;
};

export const formatSorting = (sortModel) => {
  let sorting = "";
  let sortByPrice = "";
  sortModel.forEach((element) => {
    if (element.colId === "total_price") {
      sortByPrice = element.sort;
    } else {
      sorting = (element.sort === "desc" ? "-" : "") + element.colId + ",";
    }
  });
  sorting = sorting ? "&ordering=" + sorting : "";
  return { sorting, sortByPrice };
};
