import React from "react";
import { SERVICES } from "../../language";
import TopBar from "../../partials/TopBar";
import Delivery from "./components/Delivery";
import DineIn from "./components/DineIn";
import Others from "./components/Others";
import Payment from "./components/Payment";
import Pickup from "./components/Pickup";
import Preorder from "./components/Preorder";
import Reservations from "./components/Reservations";
import Context, { useEditMode } from "./context";

function Index() {
  const { editMode, setEditMode, saveChanges, statusMessage } = useEditMode();
  return (
    <>
      <TopBar
        title={SERVICES}
        editMode={editMode}
        setEditMode={setEditMode}
        saveChanges={saveChanges}
        message={statusMessage}
      ></TopBar>
      <div className="services animate__animated animate__fadeIn  ">
        <Payment />
        <Preorder />
        <DineIn />
        <Pickup />
        <Delivery />
        <Reservations />
        <Others />
      </div>
    </>
  );
}

export default function Services() {
  return (
    <Context>
      <Index />
    </Context>
  );
}
