import React from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export default function Loading({ abs = false }) {
  return (
    <div className={`loading-spinner  ${abs ? "--abs" : ""}`}>
      <Loader type="ThreeDots" color="#9a0404" height={50} width={50} />
    </div>
  );
}
