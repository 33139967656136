import { Button } from "antd";
import React, { useEffect, useState } from "react";
import {
  CANCELLED_ORDERS,
  ORDERS,
  SHOW_PAYPAL_PENDING_ORDERS,
} from "../../language";
import TopBar from "../../partials/TopBar";
import DeletedOrdersTable from "./components/DeletedOrdersTable";
import OrderDetails from "./components/OrderDetails";
import OrdersTable from "./components/OrdersTable";
import PendingOrdersTable from "./components/PendingOrdersTable";

function Index() {
  const [clickedOrder, setClickedOrder] = useState(null);
  const [refreshData, setRefreshData] = useState(0);
  const [autoReload, setAutoReload] = useState(false);

  const [activeTable, setActiveTable] = useState(0);

  const goBack = (hard) => {
    if (hard) {
      setRefreshData((prev) => prev + 1);
    }
    setClickedOrder(null);
  };

  useEffect(() => {
    let poller;
    if (autoReload) {
      poller = setInterval(() => {
        setRefreshData((prev) => prev + 1);
      }, 10000);
    } else {
      clearInterval(poller);
    }
    return () => {
      clearInterval(poller);
    };
    // eslint-disable-next-line
  }, [autoReload]);

  return (
    <>
      <TopBar title={ORDERS}></TopBar>
      <div className="orders  animate__animated animate__fadeIn">
        <div style={{ display: clickedOrder ? "none" : "block" }}>
          <Button onClick={() => setActiveTable(0)}>
            {ORDERS}
          </Button>
          <Button onClick={() => setActiveTable(1)}>
            {SHOW_PAYPAL_PENDING_ORDERS}
          </Button>
          <Button onClick={() => setActiveTable(2)}>
            {CANCELLED_ORDERS}
          </Button>
          {activeTable === 0 && (
            <OrdersTable
              setAutoReload={setAutoReload}
              autoReload={autoReload}
              refreshData={refreshData}
              setClickedOrder={setClickedOrder}
              setRefreshData={setRefreshData}
            />
          )}
          {activeTable === 1 && (
            <PendingOrdersTable
              setAutoReload={setAutoReload}
              autoReload={autoReload}
              refreshData={refreshData}
              setClickedOrder={setClickedOrder}
              setRefreshData={setRefreshData}
            />
          )}
          {activeTable === 2 && (
            <DeletedOrdersTable
              setAutoReload={setAutoReload}
              autoReload={autoReload}
              refreshData={refreshData}
              setClickedOrder={setClickedOrder}
              setRefreshData={setRefreshData}
            />
          )}
        </div>
        {clickedOrder && (
          <OrderDetails
            isPaypalPending={activeTable === 1}
            isDeleted={activeTable === 2}
            goBack={goBack}
            order={clickedOrder}
          />
        )}
      </div>
    </>
  );
}

export default function Orders() {
  return <Index />;
}
