import React, { useEffect, useState } from "react";

import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from "../../../utils/http";
import { useAuth } from "../../../Contexts";
import {
  ADDRESS,
  ARE_YOU_SURE,
  BLOCK,
  DEDUCT_POINTS,
  EMAIL,
  FIRST_NAME,
  HOW_MUCH_POINTS,
  LAST_NAME,
  LOADING,
  PHONE,
  POINTS,
  REMOVE,
  UNBLOCK,
  USERNAME,
  USER_POINTS,
} from "../../../language";
import { domain } from "../../../constants";
import { IoIosRefresh } from "react-icons/io";
import { Button } from "antd";
import {
  fixedEncodeURIComponent,
  formatFitlers,
  formatSorting,
} from "../helpers";
const PER_PAGE = 10;

export default function UsersTable({ refreshData, setRefreshData }) {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const { token } = useAuth();

  const toggleBlock = (user) => {
    if (!window.confirm(ARE_YOU_SURE)) return;
    patchRequest(
      domain + "users/block/" + user.id + "/",
      {
        blocked: !user.blocked,
      },
      () => {},
      token
    ).then(() => {
      setRefreshData((prev) => prev + 1);
    });
  };
  const deleteUser = (user) => {
    if (!window.confirm(ARE_YOU_SURE)) return;
    deleteRequest(
      domain + "users/users/" + user.id + "/mydestroy/",
      () => {},
      token
    ).then(() => {
      setRefreshData((prev) => prev + 1);
    });
  };
  const deductPoints = (user) => {
    const value = +window.prompt(
      HOW_MUCH_POINTS + " (" + USER_POINTS + " " + user.points + " )"
    );
    if (value && value > 0) {
      postRequest(
        domain + `users/users/${user.id}/detuct_points/`,
        { points: value },
        () => {},
        token
      ).then(() => {
        setRefreshData((prev) => prev + 1);
      });
    }
  };

  useEffect(() => {
    if (gridApi) {
      gridApi.showLoadingOverlay();
      const dataSource = {
        getRows: (params) => {
          const { endRow, filterModel, sortModel } = params;
          const page = endRow / PER_PAGE;
          const sorting = formatSorting(sortModel);

          const filters = formatFitlers(filterModel);

          const filtering = `&filters=${fixedEncodeURIComponent(
            `(${filters}&role=1)`
          )}`;
          getRequest(
            domain + `users/users/?page=${page}${filtering}${sorting}`,
            () => {},
            token
          ).then(([data, code]) => {
            if (code === 200) {
              gridApi.hideOverlay();
              params.successCallback(formatData(data.results), data.count);
            }
          });
        },
      };

      gridApi.setDatasource(dataSource);
    }
    // eslint-disable-next-line
  }, [gridApi, gridColumnApi, token, refreshData]);
  return (
    <>
      <div className="__auto-reload">
        <IoIosRefresh
          className="__icon"
          onClick={() => setRefreshData((prev) => prev + 1)}
        />
      </div>
      <div className="ag-theme-alpine ag-style __table">
        <AgGridReact
          overlayLoadingTemplate={LOADING}
          pagination={true}
          rowModelType={"infinite"}
          paginationPageSize={PER_PAGE}
          cacheBlockSize={PER_PAGE}
          onGridReady={onGridReady}
          rowHeight={50}
        >
          <AgGridColumn
            filter
            filterParams={{
              filterOptions: ["Equals"],
              suppressAndOrCondition: true,
            }}
            width={80}
            sortable
            field="id"
            headerName={"id"}
            resizable={true}
          />
          <AgGridColumn
            width={140}
            filter
            filterParams={{
              filterOptions: ["Equals"],
              suppressAndOrCondition: true,
            }}
            field="first_name"
            headerName={FIRST_NAME}
            resizable={true}
          />
          <AgGridColumn
            width={140}
            filter
            filterParams={{
              filterOptions: ["Equals"],
              suppressAndOrCondition: true,
            }}
            field="last_name"
            headerName={LAST_NAME}
            resizable={true}
          />

          <AgGridColumn
            width={250}
            filter
            filterParams={{
              filterOptions: ["Equals"],
              suppressAndOrCondition: true,
            }}
            sortable
            field="email"
            headerName={EMAIL}
            resizable={true}
          />
          <AgGridColumn
            width={150}
            filter
            filterParams={{
              filterOptions: ["Equals"],
              suppressAndOrCondition: true,
            }}
            sortable
            field="username"
            headerName={USERNAME}
            resizable={true}
          />
          <AgGridColumn
            filter
            filterParams={{
              filterOptions: ["Equals"],
              suppressAndOrCondition: true,
            }}
            sortable
            field="phone"
            headerName={PHONE}
            resizable={true}
          />
          <AgGridColumn
            sortable
            field="address"
            headerName={ADDRESS}
            resizable={true}
          />
          <AgGridColumn
            width={100}
            sortable
            field="points"
            headerName={POINTS}
            resizable={true}
          />
          <AgGridColumn
            cellRendererFramework={({ data }) => {
              return (
                <Button onClick={() => deductPoints(data)}>
                  {DEDUCT_POINTS}
                </Button>
              );
            }}
            headerName={DEDUCT_POINTS}
            resizable={true}
          />
          <AgGridColumn
            width={100}
            cellRendererFramework={({ data }) => {
              return (
                <Button onClick={() => toggleBlock(data)}>
                  {data && (data.blocked ? UNBLOCK : BLOCK)}
                </Button>
              );
            }}
            headerName={BLOCK}
            resizable={true}
          />
          <AgGridColumn
            cellRendererFramework={({ data }) => {
              return <Button onClick={() => deleteUser(data)}>{REMOVE}</Button>;
            }}
            headerName={REMOVE}
            resizable={true}
          />
        </AgGridReact>
      </div>
    </>
  );
}

const formatData = (data) => {
  let formatted = data.map((user) => ({
    ...user,

    email: user.email,
    username: user.username,
    phone: user.phone_number,
    address: user.address + " - " + user.building_number,
  }));
  return formatted;
};
