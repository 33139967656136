export const formatFitlers = (filterModel) => {
  let filters = "";

  if (filterModel.id) {
    const filter = `id=${filterModel.id.filter}`;
    filters += filters === "" ? filter : "&" + filter;
  }

  if (filterModel.email) {
    const filter = `email__icontains=${filterModel.email.filter}`;
    filters += filters === "" ? filter : "&" + filter;
  }
  if (filterModel.first_name) {
    const filter = `first_name__icontains=${filterModel.first_name.filter}`;
    filters += filters === "" ? filter : "&" + filter;
  }
  if (filterModel.last_name) {
    const filter = `last_name__icontains=${filterModel.last_name.filter}`;
    filters += filters === "" ? filter : "&" + filter;
  }
  if (filterModel.phone) {
    const filter = `phone_number__icontains=${filterModel.phone.filter}`;
    filters += filters === "" ? filter : "&" + filter;
  }
  if (filterModel.username) {
    const filter = `username__icontains=${filterModel.username.filter}`;
    filters += filters === "" ? filter : "&" + filter;
  }

  return filters;
};

export function fixedEncodeURIComponent(str) {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return "%" + c.charCodeAt(0).toString(16);
  });
}


export const formatSorting = (sortModel) => {
  let sorting = "";
  sortModel.forEach((element) => {
    sorting = (element.sort === "desc" ? "-" : "") + element.colId + ",";
  });
  sorting = sorting ? "&ordering=" + sorting : "";
  return  sorting ;
};
