import React, { useEffect, useState } from "react";
import { TimePicker } from "antd";

import moment from "moment";
import { FROM, TO } from "../../../language";
import { useEditMode } from "../../../ContextsComponent/EditMode";
export default React.memo(function TimeSelector({
  changeTimeCallback,
  day,
  colName = null,
  type,
  scheduleNumber = "one",
}) {
  const { editMode } = useEditMode();

  const [defaultFromTime, setDefaultFromTime] = useState(
    moment("00:00:00", "HH:mm:ss")
  );
  const [defaultToTime, setDefaultToTime] = useState(
    moment("00:00:00", "HH:mm:ss")
  );
  useEffect(() => {
    if (day.to_time) {
      setDefaultFromTime(
        moment(
          colName ? day[colName].from_time : day.from_time,
          "HH:mm:ss"
        )
      );
      setDefaultToTime(
        moment(colName ? day[colName].to_time : day.to_time, "HH:mm:ss")
      );
    }
  }, [day, colName]);

  return (
    <>
      <div className="__time-selector">
        <span>{FROM} :</span>
        <TimePicker
          allowClear={false}
          onChange={(value) =>
            changeTimeCallback(
              day.weekday,
              value.format("HH:mm:ss"),
              colName ? day[colName].to_time : day.to_time,
              type,
              scheduleNumber
            )
          }
          format="HH:mm"
          value={defaultFromTime}
          disabled={!editMode || day.closed}
        />
      </div>
      <div className="__time-selector">
        <span>{TO} :</span>
        <TimePicker
          allowClear={false}
          onChange={(value) => {
            changeTimeCallback(
              day.weekday,
              colName ? day[colName].from_time : day.from_time,
              value.format("HH:mm:ss"),
              type,
              scheduleNumber
            );
          }}
          value={defaultToTime}
          format="HH:mm"
          disabled={!editMode || day.closed}
        />
      </div>
    </>
  );
});
