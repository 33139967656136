import React from "react";
import { useSelector } from "react-redux";
import { DAILY_OFFERS } from "../../../../language";
import Accordion from "../../../../partials/Accordion";
import DailyOffer from "./DailyOffer";
import NewDailyOffer from "./NewDailyOffer";

export default function DailyOffers() {
  const dailyOffers = useSelector((state) => state.dailyOffers);

  return (
    <div className="__daily">
      <Accordion title={DAILY_OFFERS}>
        <ul className="__list">
          {dailyOffers &&
            dailyOffers.map((offer) => (
              <li key={offer.id} className="__item">
                <DailyOffer offer={offer} />
              </li>
            ))}
        </ul>
        <NewDailyOffer />
      </Accordion>
    </div>
  );
}
