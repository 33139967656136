import React, { useState } from "react";
import Controls from "../../../../partials/Controls";
import { ARE_YOU_SURE, MANDATORY, OPTIONAL } from "../../../../language";
import { removeAddonGroup } from "../../store/slices/addonGroupsSlice";
import NewAddon from "./NewAddon";
import NewAddonGroup from "./NewAddonGroup";
import { useDispatch } from "react-redux";

const AddonGroupHeader = React.memo(function ({ group, index }) {
  const [editing, setEditing] = useState(false);
  const [addingAddon, setAddingAddon] = useState(false);

  const dispatch = useDispatch();

  const remove = () => {
    if (!window.confirm(ARE_YOU_SURE)) return;
    dispatch(
      removeAddonGroup({
        id: group.id,
      })
    );
  };

  return (
    <div className="__header">
      <span className="__title">
        {group.min_quantity > 0 ? MANDATORY : OPTIONAL}
      </span>
      {!editing && !addingAddon && (
        <Controls
          remove={remove}
          edit={() => setEditing(true)}
          add={() => setAddingAddon(true)}
        />
      )}
      {editing && (
        <NewAddonGroup group={group} setEditing={setEditing} update={true} />
      )}
      {!editing && addingAddon && (
        <NewAddon groupId={group.id} setAdding={setAddingAddon} />
      )}
    </div>
  );
});

export default AddonGroupHeader;
