import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useAuth, useLoading } from "../../../Contexts";
import { SAVED_MESSGAE, SAVING_MESSGAE } from "../../../language";
import { callAPI } from "./save";
import { reducers, getFullZones, initializeData } from "./utils";

const EditModeContext = createContext();
const DeliveryContext = createContext();
const ZonesContext = createContext();
const SectionContext = createContext();
const FullZonesContext = createContext();
const PickupContext = createContext();
const PreorderContext = createContext();
const PaymentContext = createContext();
const OthersContext = createContext();
const DineInContext = createContext();

export default function Context({ children }) {
  const [editMode, setEditMode] = useState(false);
  const [delivery, setDelivery] = useState(false);
  const { zonesReducer, sectionsReducer } = reducers();
  const [watchDog, setWatchDog] = useState(0);

  const [oldZones, setOldZones] = useState([]);
  const [oldSections, setOldSections] = useState([]);

  const [dineIn, setDineIn] = useState(false);

  const [zones, setZones] = useReducer(zonesReducer, []);
  const [sections, setSections] = useReducer(sectionsReducer, []);
  const [pickup, setPickup] = useState(false);
  const [preorder, setPreorder] = useState(false);
  const [maxPreorderDays, setMaxPreorderDays] = useState(7);
  const [fullZones, setFullZones] = useState([]);
  const [statusMessage, setStatusMessage] = useState("");

  const [tableReservations, setTableReservations] = useState(false);
  const [paypal, setPaypal] = useState(false);
  const [paypalApiKey, setPaypalApiKey] = useState("");
  const [paypalSecret, setPaypalSecret] = useState("");

  const [allowAnon, setAllowAnon] = useState(true);
  const [cash, setCash] = useState(true);
  const [allowDineInCash, setAllowDineInCash] = useState(true);
  const [allowDineInPaypal, setAllowDinePaypal] = useState(true);

  const [maxNumTable, setMaxNumTable] = useState(1);
  const [maxDaysTable, setMaxDaysTable] = useState(1);

  const { setLoading } = useLoading();
  const { token } = useAuth();

  const [covid_policy, setCovid_policy] = useState(false);

  const [allowUsersCreation, setAllowUsersCreation] = useState(true);
  const [allowReviews, setAllowReviews] = useState(true);
  const [allowJobs, setAllowJobs] = useState(true);
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [maintenanceMSG, setMaintenanceMsg] = useState(false);

  const getDataFromAPI = useCallback(() => {
    initializeData(
      setMaxPreorderDays,
      setPreorder,
      setPickup,
      setDelivery,
      setZones,
      setSections,
      setOldSections,
      setOldZones,
      setPaypal,
      setPaypalApiKey,
      setPaypalSecret,
      setAllowAnon,
      setDineIn,
      setCash,
      setAllowDineInCash,
      setAllowDinePaypal,
      setTableReservations,
      setCovid_policy,
      setMaxDaysTable,
      setMaxNumTable,
      setAllowUsersCreation,
      setMaintenanceMode,
      setMaintenanceMsg,
      setAllowReviews,
      setAllowJobs,
      setLoading,
      token
    );
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    getDataFromAPI();
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    setFullZones(getFullZones(zones, sections));
  }, [sections, zones, watchDog]);

  const forceRerender = () => setWatchDog((prev) => prev + 1);

  const saveChanges = () => {
    setStatusMessage(SAVING_MESSGAE);
    callAPI(
      token,
      setLoading,
      zones,
      oldZones,
      sections,
      oldSections,
      pickup,
      delivery,
      preorder,
      maxPreorderDays,
      paypal,
      paypalApiKey,
      paypalSecret,
      allowAnon,
      dineIn,
      cash,
      allowDineInCash,
      allowDineInPaypal,
      tableReservations,
      covid_policy,
      maxDaysTable,
      maxNumTable,
      allowUsersCreation,
      maintenanceMode,
      maintenanceMSG,
      allowReviews,
      allowJobs
    ).then(() => {
      setTimeout(() => {
        getDataFromAPI();
        setStatusMessage(SAVED_MESSGAE);
      }, 200);
    });
  };

  return (
    <EditModeContext.Provider
      value={{
        forceRerender,
        editMode,
        setEditMode,
        saveChanges,
        statusMessage,
        setStatusMessage,
      }}
    >
      <DeliveryContext.Provider value={{ delivery, setDelivery }}>
        <ZonesContext.Provider value={{ setZones }}>
          <SectionContext.Provider value={{ setSections }}>
            <FullZonesContext.Provider value={fullZones}>
              <PickupContext.Provider value={{ pickup, setPickup }}>
                <PreorderContext.Provider
                  value={{
                    maxPreorderDays,
                    setMaxPreorderDays,
                    preorder,
                    setPreorder,
                  }}
                >
                  <PaymentContext.Provider
                    value={{
                      cash,
                      setCash,
                      paypal,
                      setPaypal,
                      paypalApiKey,
                      setPaypalApiKey,
                      paypalSecret,
                      setPaypalSecret,
                    }}
                  >
                    <OthersContext.Provider
                      value={{
                        allowAnon,
                        setAllowAnon,
                        tableReservations,
                        setTableReservations,
                        maxDaysTable,
                        maxNumTable,
                        setMaxDaysTable,
                        setMaxNumTable,
                        allowUsersCreation,
                        setAllowUsersCreation,
                        maintenanceMode,
                        setMaintenanceMode,
                        maintenanceMSG,
                        setMaintenanceMsg,
                        allowReviews,
                        setAllowReviews,
                        allowJobs,
                        setAllowJobs,
                      }}
                    >
                      <DineInContext.Provider
                        value={{
                          dineIn,
                          setDineIn,
                          allowDineInCash,
                          setAllowDineInCash,
                          allowDineInPaypal,
                          setAllowDinePaypal,
                          covid_policy,
                          setCovid_policy,
                        }}
                      >
                        {children}
                      </DineInContext.Provider>
                    </OthersContext.Provider>
                  </PaymentContext.Provider>
                </PreorderContext.Provider>
              </PickupContext.Provider>
            </FullZonesContext.Provider>
          </SectionContext.Provider>
        </ZonesContext.Provider>
      </DeliveryContext.Provider>
    </EditModeContext.Provider>
  );
}

export const useEditMode = () => {
  return useContext(EditModeContext);
};
export const useDelivery = () => {
  return useContext(DeliveryContext);
};
export const useZones = () => {
  return useContext(ZonesContext);
};
export const useSections = () => {
  return useContext(SectionContext);
};
export const useFullZones = () => {
  return useContext(FullZonesContext);
};
export const usePickup = () => {
  return useContext(PickupContext);
};
export const usePreorder = () => {
  return useContext(PreorderContext);
};
export const usePayment = () => {
  return useContext(PaymentContext);
};
export const useDineIn = () => {
  return useContext(DineInContext);
};
export const useOthers = () => {
  return useContext(OthersContext);
};
