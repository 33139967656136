import React from "react";
import { TABLES } from "../../language";
import TopBar from "../../partials/TopBar";
import DineTables from "./components/DineTables";
import Context from "./Context";

export default function Tables() {
  return (
    <Context>
      <TopBar title={TABLES} />
      <div className="dine-tables  animate__animated animate__fadeIn">
          <DineTables />
      </div>
    </Context>
  );
}
