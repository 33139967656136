import React, { useEffect, useState } from "react";
import Accordion from "../../../partials/Accordion";
import DropArea from "../../../partials/DropArea";
import { DND_ADD_CUISINE, DND_REMOVE_CUISINE } from "../constants";

import Cuisine from "./Cuisine";
import CUISINES from "../cuisines.json";
import {
  RESTAURANT,
  CUISINES as _CUISINES,
  CUISINES_MESSAGE,
  SELECTED,
} from "../../../language";
import { useEditMode } from "../../../ContextsComponent/EditMode";
import { useDispatch, useSelector } from "react-redux";
import { informationSetAttrib } from "../store/slices/InformationSlice";

export default function RestaurantCuisine() {
  const { editMode } = useEditMode();
  const information = useSelector((state) => state.information);
  const dispatch = useDispatch();

  const [allCuisines, setAllCuisines] = useState(CUISINES);

  const [selectedCuisines, setSelectedCuisines] = useState([]);
  const [lastActiveCuisine, setLastActiveCuisine] = useState({});

  const toggleCuisine = (item) => {
    const cuisine = item.itemData || item.title;
    setLastActiveCuisine({ cuisine });
  };

  useEffect(() => {
    if (lastActiveCuisine.cuisine) {
      if (selectedCuisines.includes(lastActiveCuisine.cuisine)) {
        setSelectedCuisines((prev) =>
          prev.filter(
            (selectedCuisine) => selectedCuisine !== lastActiveCuisine.cuisine
          )
        );
        setAllCuisines((prev) => {
          return [...prev, lastActiveCuisine.cuisine];
        });
      } else {
        setAllCuisines((prev) =>
          prev.filter(
            (selectedCuisine) => selectedCuisine !== lastActiveCuisine.cuisine
          )
        );
        setSelectedCuisines((prev) => {
          return [...prev, lastActiveCuisine.cuisine];
        });
      }
    }
    // eslint-disable-next-line
  }, [lastActiveCuisine]);

  useEffect(() => {
    dispatch(informationSetAttrib({ key: "cuisine", value: selectedCuisines }));
    // eslint-disable-next-line
  }, [selectedCuisines]);

  useEffect(() => {
    if (information["cuisine"]) {
      const fetchedCuisines = information.cuisine.split(",");
      setSelectedCuisines(fetchedCuisines);
      setAllCuisines((prev) =>
        prev.filter((cuisine) => !fetchedCuisines.includes(cuisine))
      );
    }
  }, [information]);

  return (
    <Accordion opened={false} title={RESTAURANT + " " + _CUISINES}>
      <form className="form">
        <fieldset disabled={!editMode} className="__group">
          <h2 className="__title">{SELECTED + " " + _CUISINES}</h2>
          <DropArea
            accept={DND_ADD_CUISINE}
            className="cuisines --selected-box"
          >
            {selectedCuisines.length ? (
              selectedCuisines.map((cuisine) => (
                <Cuisine
                  key={cuisine}
                  cuisine={cuisine}
                  selectedCuisines={selectedCuisines}
                  handleClick={toggleCuisine}
                  type={DND_REMOVE_CUISINE}
                  toggleCuisine={toggleCuisine}
                  animate={cuisine === lastActiveCuisine.cuisine}
                ></Cuisine>
              ))
            ) : (
              <p className="__placeholder">{CUISINES_MESSAGE}</p>
            )}
          </DropArea>
        </fieldset>
        <fieldset disabled={!editMode} className="__group">
          <DropArea accept={DND_REMOVE_CUISINE} className="cuisines --select">
            {allCuisines.length ? (
              allCuisines.map((cuisine) => (
                <Cuisine
                  key={cuisine}
                  cuisine={cuisine}
                  selectedCuisines={selectedCuisines}
                  handleClick={toggleCuisine}
                  type={DND_ADD_CUISINE}
                  toggleCuisine={toggleCuisine}
                  animate={cuisine === lastActiveCuisine.cuisine}
                ></Cuisine>
              ))
            ) : (
              <p className="__placeholder">You have added all cuisines.</p>
            )}
          </DropArea>
        </fieldset>
      </form>
    </Accordion>
  );
}
