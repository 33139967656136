import { domain } from "../../../constants";
import { handleOffersChanges } from "../../../utils/helpers";
import { getRequest, patchRequest } from "../../../utils/http";
import { setBannerOffers } from "../store/slices/BannerOffersSlice";
import { setCoupons } from "../store/slices/CouponsSlice";
import { setDailyOffers } from "../store/slices/DailyOfferSlice";
import { setOrderGifts } from "../store/slices/OrderGiftsSlice";
import { setOrderTotalOffers } from "../store/slices/OrderTotalOfferSlice";

export const getOthers = (
  setFirstOrderDiscount,
  setPercentageToPoints,
  setMaxNumberOfGifts,
  setLoading,
  token
) => {
  getRequest(domain + "settings/restaurant/1/", setLoading, token).then(
    ([data, status]) => {
      if (status === 200) {
        setFirstOrderDiscount(data["first_order_discount"]);
        setPercentageToPoints(data["percentage_to_points"]);
        setMaxNumberOfGifts(data["max_num_of_gifts"]);
      }
    }
  );
};

export const getMenuItems = ({
  setCategories,
  setMeals,
  setSizes,
  setLoading,
  token,
}) => {
  getRequest(domain + "menu/meal_category/", setLoading, token).then((res) => {
    const [data, status] = res;
    if (status === 200) {
      setCategories([...data]);
    }
  });
  getRequest(domain + "menu/meal/", setLoading, token).then((res) => {
    const [data, status] = res;
    if (status === 200) {
      setMeals([...data]);
    }
  });
  getRequest(domain + "menu/meal_size/", setLoading, token).then((res) => {
    const [data, status] = res;
    if (status === 200) {
      setSizes([...data]);
    }
  });
};

export const getDailyOffers = ({
  dispatch,
  setRemoteDailyOffers,
  token,
  setLoading,
}) => {
  getRequest(domain + "menu/daily_offer/", setLoading, token).then((res) => {
    const [data, status] = res;
    if (status === 200) {
      dispatch(setDailyOffers([...data]));
      setRemoteDailyOffers([...data]);
    }
  });
};
export const getOrderTotalOffers = ({
  dispatch,
  setRemoteOrderTotalOffers,
  token,
  setLoading,
}) => {
  getRequest(domain + "menu/order_total_offer/", setLoading, token).then(
    (res) => {
      const [data, status] = res;
      if (status === 200) {
        dispatch(setOrderTotalOffers([...data]));
        setRemoteOrderTotalOffers([...data]);
      }
    }
  );
};
export const getBannerOffers = ({
  dispatch,
  setRemoteBannerOffers,
  token,
  setLoading,
}) => {
  getRequest(domain + "settings/banner_offer/", setLoading, token).then(
    (res) => {
      const [data, status] = res;
      if (status === 200) {
        dispatch(setBannerOffers([...data]));
        setRemoteBannerOffers([...data]);
      }
    }
  );
};
export const getOrderGifts = ({
  dispatch,
  setRemoteOrderGifts,
  token,
  setLoading,
}) => {
  getRequest(domain + "settings/order_gift/", setLoading, token).then((res) => {
    const [data, status] = res;
    if (status === 200) {
      dispatch(setOrderGifts([...data]));
      setRemoteOrderGifts([...data]);
    }
  });
};
export const getCoupons = ({
  dispatch,
  setRemoteCoupons,
  token,
  setLoading,
}) => {
  getRequest(domain + "menu/coupon/", setLoading, token).then((res) => {
    const [data, status] = res;
    if (status === 200) {
      dispatch(setCoupons([...data]));
      setRemoteCoupons([...data]);
    }
  });
};

export const saveResources = async ({
  dailyOffers,
  remoteDailyOffers,
  orderTotalOffers,
  remoteOrderTotalOffers,
  bannerOffers,
  orderGifts,
  remoteOrderGifts,
  remoteBannerOffers,
  firstOrderDiscount,
  percentageToPoints,
  maxNumberOfGifts,
  remoteCoupons,
  coupons,
  setLoading,
  token,
  setServerIds,
  serverIds,
}) => {
  await patchRequest(
    domain + "settings/restaurant/1/",
    {
      first_order_discount: firstOrderDiscount,
      percentage_to_points: percentageToPoints,
      max_num_of_gifts: maxNumberOfGifts,
    },
    setLoading,
    token
  );
  handleOffersChanges(
    dailyOffers,
    remoteDailyOffers,
    "menu/daily_offer/",
    token,
    setLoading,
    serverIds,
    setServerIds
  );
  handleOffersChanges(
    orderTotalOffers,
    remoteOrderTotalOffers,
    "menu/order_total_offer/",
    token,
    setLoading,
    serverIds,
    setServerIds
  );
  handleOffersChanges(
    bannerOffers,
    remoteBannerOffers,
    "settings/banner_offer/",
    token,
    setLoading,
    serverIds,
    setServerIds
  );
  handleOffersChanges(
    orderGifts,
    remoteOrderGifts,
    "settings/order_gift/",
    token,
    setLoading,
    serverIds,
    setServerIds
  );
  handleOffersChanges(
    coupons,
    remoteCoupons,
    "menu/coupon/",
    token,
    setLoading,
    serverIds,
    setServerIds
  );
};
