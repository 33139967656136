export const ACTION_SET = "SET";
export const ACTION_ADD = "ADD";
export const ACTION_REMOVE = "REMOVE";
export const ACTION_REORDER = "REORDER";
export const ACTION_UPDATE = "UPDATE";
export const ACTION_CLEAR = "CLEAR";
export const ACTION_LINK = "LINK";
export const ACTION_UNLINK = "UNLINK";
export const ACTION_TOGGLE_HIDE = "TOGGLE_HIDE";
export const ACTION_ADD_IMAGE = "ADD_IMAGE";
export const DND_LINK_ADDON = "LINK_ADDON";
export const DND_UNLINK_ADDON = "UNLINK_ADDON";

export const FIELD_MEALS = "meal";
export const FIELD_MEALS_CATEGORIES = "meal_categories";
export const FIELD_MEALS_SIZES = "meal_sizes";

export const ENDPOINTS = {
  CATEGORY: "menu/meal_category/",
  MEAL: "menu/meal/",
  SIZE: "menu/meal_size/",
  ADDONGROUP: "menu/addon_group/",
  ADDON: "menu/addon/",
};
