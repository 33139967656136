import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import {
  FIELD_MEALS,
  FIELD_MEALS_CATEGORIES,
  FIELD_MEALS_SIZES,
} from "../../constants";

export const addonGroupsSlice = createSlice({
  name: "addonGroups",
  initialState: [],
  reducers: {
    setAddonGroups: (state, { payload }) => {
      return [...payload];
    },
    addAddonGroup: (state, { payload }) => {
      state.push({
        ...payload.group,
        id: uuidv4(),
        priority: state.length,
        [FIELD_MEALS]: [],
        [FIELD_MEALS_CATEGORIES]: [],
        [FIELD_MEALS_SIZES]: [],
      });
    },
    removeAddonGroup: (state, { payload }) => {
      return state.filter((group) => payload.id !== group.id);
    },
    updateAddonGroup: (state, { payload }) => {
      const group = state.find((_group) => _group.id === payload.id);
      group.name = payload.name;
      group.multiple = payload.multiple;
      group.min_quantity = payload.min_quantity;
      group.max_quantity = payload.max_quantity;
    },

    linkAddonWithGroup: (state, { payload }) => {
      const { groupId, source, sourceId } = payload;
      try {
        const group = state.find((_group) => groupId === _group.id);
        if (group[source].indexOf(sourceId) < 0) group[source].push(sourceId);
      } catch (err) {
        console.log(err);
        return { ...state };
      }
    },
    unlinkAddonWithGroup: (state, { payload }) => {
      const { groupId, source, sourceId } = payload;
      const group = state.find((_group) => groupId === _group.id);
      if (group[source])
        group[source] = group[source].filter((item) => item !== sourceId);
    },
  },
});

export default addonGroupsSlice.reducer;

export const {
  setAddonGroups,
  addAddonGroup,
  removeAddonGroup,
  updateAddonGroup,
  linkAddonWithGroup,
  unlinkAddonWithGroup,
} = addonGroupsSlice.actions;
