import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../Contexts";
import {
  LOGIN,
  LOGIN_TITLE,
  PASSWORD,
  REMEMBER_ME,
  USERNAME,
} from "../../language";
import Button from "../../partials/Button";
import TextInput from "../../partials/TextInput";
export default function LogIn() {
  const { LogIn, isLoggedIn, loginError } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const handleSubmit = (e) => {
    e.preventDefault();
    LogIn(username, password, rememberMe);
    history.push(location.state ? location.state.referrer : '/');
  };

  useEffect(() => {
    if (isLoggedIn) {
      history.push(location.state.referrer);
    }
  }, [isLoggedIn, history, location]);

  return (
    <form className="login-form" onSubmit={handleSubmit}>
      <h1>{LOGIN_TITLE}</h1>
      {loginError !== "" && <p className="error">{loginError}</p>}
      <TextInput
        name={USERNAME}
        placeholder={USERNAME}
        setContextValue={(value) => setUsername(value)}
      />
      <TextInput
        name={PASSWORD}
        placeholder={PASSWORD}
        type="password"
        setContextValue={(value) => setPassword(value)}
      />
      <label htmlFor="remember">{REMEMBER_ME}</label>
      <input
        type="checkbox"
        name="remember"
        checked={rememberMe}
        onChange={() => setRememberMe(!rememberMe)}
      />
      <Button>{LOGIN}</Button>
    </form>
  );
}
