import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ARE_YOU_SURE, REMOVE } from "../../../../../language";
import { addCategoryImage } from "../../../store/slices/categoriesSlice";
import { addMealImage } from "../../../store/slices/mealsSlice";

const PLACEHOLDER_IMAGE =
  require("../../../../../assets/food-placholder.png").default;
const FoodImage = React.memo(function ({
  itemType,
  itemId,
  initialValue = null,
}) {
  const [image, setImage] = useState(initialValue);

  const dispatch = useDispatch();

  const handleFiles = ({ target }) => {
    let reader = new FileReader();
    reader.readAsDataURL(target.files[0]);
    reader.onload = function () {
      setImage(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  useEffect(() => {
    itemType === "category"
      ? dispatch(
          addCategoryImage({
            image,
            id: itemId,
          })
        )
      : dispatch(
          addMealImage({
            image,
            id: itemId,
          })
        );

    // eslint-disable-next-line
  }, [image]);

  const handleRemove = () => {
    if (!window.confirm(ARE_YOU_SURE)) return;

    setImage(null);
  };

  return (
    <fieldset className="__food-image">
      <img
        className="__remove"
        onClick={handleRemove}
        alt="remove"
        title={REMOVE}
        src={require("../../../../../assets/times.svg").default}
      />
      <label className="__select">
        <img
          className="__preview"
          alt="edit"
          src={image || PLACEHOLDER_IMAGE}
        />
        <input
          accept="image/png, image/gif, image/jpeg"
          type="file"
          onChange={handleFiles}
        />
      </label>
    </fieldset>
  );
});

export default FoodImage;
