import React, { useContext } from "react";
import Auth, { AuthContext } from "./Auth";
import Loading, { LoadingContext } from "./Loading";

export const useAuth = () => {
  return useContext(AuthContext);
};
export const useLoading = () => {
  return useContext(LoadingContext);
};

export default function Contexts({ children }) {
  return (
    <Loading>
      <Auth>{children}</Auth>
    </Loading>
  );
}
