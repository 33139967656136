import React, { useEffect, useState } from "react";
import { IoIosCash, IoMdArrowRoundBack } from "react-icons/io";
import {
  IoBicycleSharp,
  IoCellularSharp,
  IoGiftSharp,
  IoHomeSharp,
  IoInformationCircle,
  IoInformationCircleSharp,
  IoLogoPaypal,
  IoMailOpenSharp,
  IoMapOutline,
  IoPersonSharp,
  IoTime,
  IoTimeOutline,
  IoTrashSharp,
} from "react-icons/io5";
import { domain } from "../../../constants";
import { useAuth } from "../../../Contexts";
import {
  ADDONS,
  ARE_YOU_SURE,
  CASH,
  CREATED_AT,
  DELIVERY,
  DISCOUNT_AMOUNT,
  EMAIL,
  MEAL,
  NAME,
  ORDER,
  PAYPAL,
  PAYPAL_AMOUNT,
  PAYPAL_NOT_PAYED,
  PHONE,
  PREORDER_DATE,
  PRICE,
  PRICE_BEFORE_DISCOUNTS,
  QUANTITY,
  SIZE,
  STATUS,
  TOTAL_PRICE,
} from "../../../language";
import Accordion from "../../../partials/Accordion";
import Loading from "../../../partials/Loading";
import { deleteRequest, getRequest } from "../../../utils/http";
import { ENDPOINTS } from "../../Services/constants";
export default function OrderDetails({
  order,
  goBack,
  isPaypalPending = false,
  isDeleted = false,
}) {
  const [zone, setZone] = useState({});
  const [section, setSection] = useState({});
  const [items, setItems] = useState([]);
  const [gifts, setGifts] = useState([]);
  const [beforePrice, setBeforePrice] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(null);

  const { token } = useAuth();

  const [paypalPaymentDetails, setPaypalPaymentDetails] = useState(null);

  useEffect(() => {
    getRequest(
      domain +
        `orders/order/${order.id}/${isPaypalPending ? "?pending=1" : ""}`,
      () => {},
      token
    ).then(([data, status]) => {
      if (status === 200) {
        setItems(data.items);
        setGifts(data.gift_choices);
        setBeforePrice(data.before_price);
        setDiscountPrice(
          data.before_price - data.total_price + +data.delivery_price
        );
        setPaypalPaymentDetails(data.payment);
      }
    });
    if (order.delivery) {
      getRequest(
        domain + ENDPOINTS.ZONE + order.delivery.zone + "/",
        () => {},
        ""
      ).then((res) => {
        const [data, status] = res;
        if (status === 200) {
          setZone(data);
        }
      });
      getRequest(
        domain + ENDPOINTS.SECTION + order.delivery.section + "/",
        () => {},
        ""
      ).then((res) => {
        const [data, status] = res;
        if (status === 200) {
          setSection(data);
        }
      });
    }
  }, [order, token, isPaypalPending]);

  const removeOrder = () => {
    if (!window.confirm(ARE_YOU_SURE)) return;
    deleteRequest(
      domain +
        `orders/order/${order.id}/${isPaypalPending ? "?pending=1" : ""}`,
      () => {},
      token
    ).then(() => {
      goBack(true);
    });
  };

  return (
    <div className="__details">
      <div className="__header">
        <IoMdArrowRoundBack onClick={() => goBack()} className="__icon" />
        {!isDeleted && (
          <IoTrashSharp onClick={removeOrder} className="__icon"></IoTrashSharp>
        )}
      </div>
      <div className="__body">
        <Accordion
          pinned={true}
          title={ORDER + " #" + order.id + " - " + order.slug}
        >
          <ul className="__info">
            <li>
              <IoTimeOutline className="__icon" />
              <span>{CREATED_AT + " : " + order.created_at}</span>
            </li>
            {order.serve_time && (
              <li>
                <IoTime className="__icon" />
                <span>{PREORDER_DATE + " : " + order.serve_time}</span>
              </li>
            )}
            {order.phone && (
              <>
                <li>
                  <IoPersonSharp className="__icon" />
                  <span>
                    {NAME + " : " + order.first_name + " " + order.last_name}
                  </span>
                </li>
                <li>
                  <IoCellularSharp className="__icon" />
                  <span>{PHONE + " : " + order.phone}</span>
                </li>
                <li>
                  <IoMailOpenSharp className="__icon" />
                  <span>{EMAIL + " : " + order.email}</span>
                </li>
              </>
            )}
            <li>
              <IoInformationCircleSharp className="__icon" />
              <span>{STATUS + " : " + order.status}</span>
            </li>

            {order.payment === PAYPAL && (
              <li>
                <IoLogoPaypal className="__icon" />
                <span>{PAYPAL + " : " + order.paypal_order_id}</span>
              </li>
            )}
            {order.payment === CASH && (
              <li>
                <IoIosCash className="__icon" />
                <span>{CASH}</span>
              </li>
            )}
            <li>
              <IoBicycleSharp className="__icon" />
              <span>{order.type}</span>
            </li>
            {order.delivery && (
              <>
                <li>
                  <IoMapOutline className="__icon" />
                  <span>
                    {zone.name +
                      ", " +
                      section.name +
                      " (€" +
                      order.delivery_price +
                      ")"}
                  </span>
                </li>
                <li>
                  <IoHomeSharp className="__icon" />
                  <span>
                    {order.delivery.address + " #" + order.delivery.building_no}
                  </span>
                </li>
              </>
            )}
            {order.notes && (
              <li>
                <IoInformationCircle className="__icon" />
                <span>{order.notes}</span>
              </li>
            )}
            {gifts.length > 0 && (
              <li>
                <IoGiftSharp className="__icon" />
                <ul className="__gifts">
                  {gifts.map((gift, index) => (
                    <li key={index}>{gift.description}</li>
                  ))}
                </ul>
              </li>
            )}
          </ul>

          <ul className="__invoice">
            {beforePrice > 0 && (
              <>
                <li>
                  <span>{PRICE_BEFORE_DISCOUNTS}</span>
                  <span>{"€" + parseFloat(beforePrice).toFixed(2)}</span>
                </li>
                <li>
                  <span>{DISCOUNT_AMOUNT}</span>
                  <span>{"€" + parseFloat(-discountPrice).toFixed(2)}</span>
                </li>
              </>
            )}

            {+order.delivery_price > 0 && (
              <li>
                <span>{DELIVERY + " " + PRICE}</span>
                <span>{"€" + parseFloat(order.delivery_price).toFixed(2)}</span>
              </li>
            )}
            <li className="__total">
              <span>{TOTAL_PRICE}</span>
              <span>{order.total_price}</span>
            </li>
            {order.payment === PAYPAL && paypalPaymentDetails && (
              <li className="__payed">
                <span>{PAYPAL_AMOUNT}</span>
                <span>
                  {paypalPaymentDetails.payed
                    ? "€" + paypalPaymentDetails.amount_payed
                    : PAYPAL_NOT_PAYED}
                </span>
              </li>
            )}
          </ul>

          <ul className="__items">
            {items.length === 0 && <Loading />}
            {items.map((item, index) => (
              <li key={index} className="__item">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <span className="bold">{PRICE} </span>
                      </td>
                      <td>
                        <span className="price">{"€" + item.total_price}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="bold ">{QUANTITY} </span>
                      </td>
                      <td>
                        <span>{item.quantity}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="bold">{MEAL} </span>
                      </td>
                      <td>
                        <span>
                          {item.meal_object.name} {`(€${item.meal_price})`}{" "}
                        </span>
                      </td>
                    </tr>
                    {item.size && (
                      <tr>
                        <td>
                          <span className="bold">{SIZE}</span>
                        </td>
                        <td>
                          <span>
                            {item.size_object.name} {`(€${item.size_price})`}
                          </span>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        <span className="bold">{ADDONS} </span>
                      </td>
                      <td>
                        <ul className="__addons">
                          {item.addons.map((addon, index) => (
                            <li key={index}>
                              {addon.addon_object.name}{" "}
                              {`(€${addon.total_price})`}
                            </li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </li>
            ))}
          </ul>
        </Accordion>
      </div>
    </div>
  );
}
