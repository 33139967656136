import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  ADD,
  CANCEL,
  GIFT,
  IMAGE,
  MAX_ORDER_PRICE,
  MAX_ORDER_PRICE_ERROR,
  MIN_ORDER_PRICE,
  SAVE,
  ZERO_MEANS_UNLIMITED,
} from "../../../../language";
import Button from "../../../../partials/Button";
import ImageInput from "../../../../partials/ImageInput";
import Textarea from "../../../../partials/Textarea";
import TextInput from "../../../../partials/TextInput";
import {
  addOrderGift,
  updateOrderGift,
} from "../../store/slices/OrderGiftsSlice";

export default function NewOrderGift({
  setEditing = () => {},
  update = false,
  gift = {},
}) {
  const [adding, setAdding] = useState(update);
  const [description, setDescription] = useState(gift.description || "");
  const [minPrice, setMinPrice] = useState(gift.min_price || "0");
  const [maxPrice, setMaxPrice] = useState(gift.max_price || "0");
  const [image, setImage] = useState(gift.image || null);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const handleCancle = () => {
    setEditing(false);
    setAdding(false);
  };

  const handleAdd = (e) => {
    e.preventDefault();

    if (+maxPrice !== 0 && +maxPrice < +minPrice) {
      setError(MAX_ORDER_PRICE_ERROR);
      return;
    }

    const orderGiftBody = {
      description,
      min_price: +minPrice,
      max_price: +maxPrice,
      image,
    };

    !update
      ? dispatch(
          addOrderGift({
            orderGift: orderGiftBody,
          })
        )
      : dispatch(
          updateOrderGift({
            id: gift.id,
            ...orderGiftBody,
          })
        );

    setAdding(false);
    setEditing(false);
  };

  return (
    <>
      {adding && (
        <form onSubmit={handleAdd} className="__new form">
          <Textarea
            name={GIFT}
            initialValue={description}
            setContextValue={(value) => {
              setDescription(value);
            }}
            required={true}
          ></Textarea>
          <TextInput
            type="number"
            name={MIN_ORDER_PRICE}
            initialValue={minPrice}
            setContextValue={setMinPrice}
            required={true}
          />
          <TextInput
            type="number"
            name={MAX_ORDER_PRICE + " " + ZERO_MEANS_UNLIMITED}
            initialValue={maxPrice}
            setContextValue={setMaxPrice}
            required={true}
          />
          <ImageInput
            title={IMAGE}
            setContextValue={(value) => {
              setImage(value);
            }}
            initial={[image]}
            multiple={false}
            required={true}
          />

          {error && <div>{error}</div>}

          <div className="__btns">
            <Button type="submit">{SAVE}</Button>
            <Button onClick={handleCancle}>{CANCEL}</Button>
          </div>
        </form>
      )}
      {!update && (
        <Button onClick={() => setAdding(true)} disabled={adding}>
          {ADD}
        </Button>
      )}
    </>
  );
}
