import React, { useState } from "react";
import TextInput from "../../../partials/TextInput";
import InputGroup from "../../../partials/InputGroup";
import Button from "../../../partials/Button";
import { useEditMode, useSections } from "../context";
import { ACTION_ADD } from "../constants";
import { CANCEL, NAME, SAVE, ZIP_CODE } from "../../../language";

export default function NewSection({ setAdding, zoneId }) {
  const [name, setName] = useState("");
  const [zipcode, setZipcode] = useState("");
  const { editMode, forceRerender } = useEditMode();
  const { setSections } = useSections();

  const addSection = (e) => {
    e.preventDefault();
    setSections({
      type: ACTION_ADD,
      section: { name, zip_code: zipcode, zone: zoneId },
    });
    setAdding(false);
    forceRerender();
  };

  return (
    <form onSubmit={addSection} className="__add-form">
      <fieldset disabled={!editMode}>
        <InputGroup>
          <TextInput
            setContextValue={(value) => setName(value)}
            name={NAME}
            placeholder={NAME}
            size={6}
          />
          <TextInput
            setContextValue={(value) => setZipcode(value)}
            name={ZIP_CODE}
            placeholder={ZIP_CODE}
            size={4}
          />
        </InputGroup>
        <div className="__btns">
          <Button type="submit">{SAVE}</Button>
          <Button onClick={() => setAdding(false)}>{CANCEL}</Button>
        </div>
      </fieldset>
    </form>
  );
}
