import React from "react";
import { useSelector } from "react-redux";
import { ORDER_TOTAL_OFFERS } from "../../../../language";
import Accordion from "../../../../partials/Accordion";
import NewOrderTotalOffer from "./NewOrderTotalOffer";
import OrderTotalOffer from "./OrderTotalOffer";

export default function OrderTotalOffers() {
  const orderTotalOffers = useSelector((state) => state.orderTotalOffers);
  return (
    <div className="__order-total">
      <Accordion title={ORDER_TOTAL_OFFERS}>
        <ul className="__list">
          {orderTotalOffers &&
            orderTotalOffers.map((offer) => (
              <li key={offer.id} className="__item">
                <OrderTotalOffer offer={offer} />
              </li>
            ))}
        </ul>
        <NewOrderTotalOffer />
      </Accordion>
    </div>
  );
}
