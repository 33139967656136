import React, { useEffect, useState } from "react";

export default function Textarea({
  name,
  initialValue,
  setContextValue,
  disabled,
  ...props
}) {
  const [state, setState] = useState("");
  useEffect(() => {
    setState(initialValue);
  }, [initialValue]);
  return (
    <div>
      <fieldset className="__textarea">
        <label htmlFor="privacy_policy">{name}</label>
        <textarea
          value={state}
          placeholder={name + ' ...'}
          disabled={disabled}
          onChange={({ target }) => {
            setContextValue(target.value);
            setState(target.value);
          }}
          {...props}
        ></textarea>
      </fieldset>
    </div>
  );
}
