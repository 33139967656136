import React, { useState } from "react";

import TopBar from "partials/TopBar";
import { TABLE_RESERVATIONS } from "language";
import ResTable from "./components/ResTable";

export default function Reservations() {
  const [refreshData, setRefreshData] = useState(0);
  return (
    <div className="reservations">
      <TopBar title={TABLE_RESERVATIONS} />
      <ResTable refreshData={refreshData} setRefreshData={setRefreshData} />
    </div>
  );
}
