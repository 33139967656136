import { Button } from "antd";
import { useAuth, useLoading } from "Contexts";
import { useEditMode } from "ContextsComponent/EditMode";
import {
  EXPORT,
  IMPORT,
  IMPORT_FAILED,
  IMPORT_INVALID,
  IMPORT_PLACEHOLDER,
  IMPORT_SUCCESS,
  IMPORT_WARNING,
} from "language";
import Textarea from "partials/Textarea";
import React, { useState } from "react";
import { getRequest, postRequest } from "utils/http";
import { domain } from "../../../../constants";

export default function ImportExport() {
  const { setLoading } = useLoading();
  const { token } = useAuth();

  const [showImportTextArea, setShowImportTextArea] = useState(false);
  const [importData, setImportData] = useState("");
  const { setStatusMessage } = useEditMode();
  const exportMenu = () => {
    getRequest(domain + "menu/export/", setLoading, token).then((res) => {
      const [data, status] = res;
      if (status === 200) {
        setLoading(true);
        const menu = JSON.stringify(data);
        let element = document.createElement("a");
        const filename = "menu_" + new Date().toLocaleString() + ".json";
        element.setAttribute(
          "href",
          "data:text/plain;charset=utf-8," + encodeURIComponent(menu)
        );
        element.setAttribute("download", filename);

        element.style.display = "none";
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
        setLoading(false);
      }
    });
  };
  const importMenu = () => {
    if (!importData) return;
    if (window.confirm(IMPORT_WARNING))
      try {
        postRequest(
          domain + "menu/import/",
          JSON.parse(importData),
          setLoading,
          token
        ).then((res) => {
          if (res[1] === 201) {
            setImportData("");
            setShowImportTextArea(false);
            setStatusMessage(IMPORT_SUCCESS);
          } else {
            setStatusMessage(IMPORT_FAILED);
          }
        });
      } catch (error) {
        setStatusMessage(IMPORT_INVALID);
      }
  };

  return (
    <div className="import-export">
      <Button style={{ cursor: "pointer" }} onClick={() => exportMenu()}>
        {EXPORT}
      </Button>
      <Button onClick={() => setShowImportTextArea(!showImportTextArea)}>
        {IMPORT}
      </Button>
      {showImportTextArea && (
        <>
          <Textarea
            placeholder={IMPORT_PLACEHOLDER}
            setContextValue={setImportData}
          ></Textarea>
          <Button onClick={() => importMenu()}> {"OK"} </Button>
        </>
      )}
    </div>
  );
}
