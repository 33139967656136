import {
  ACTION_ADD,
  ACTION_REMOVE,
  ACTION_SET,
  ACTION_UPDATE,
  ENDPOINTS,
} from "../constants";
import { v4 as uuidv4 } from "uuid";
import { getRequest } from "../../../utils/http";
import _ from "lodash";
import { domain } from "../../../constants";

export const reducers = () => {
  const zonesReducer = (state, action) => {
    switch (action.type) {
      case ACTION_SET: {
        return action.state;
      }
      case ACTION_ADD:
        return [...state, { ...action.zone, id: uuidv4() }];
      case ACTION_REMOVE:
        return state.filter((zone) => zone["id"] !== action.id);
      case ACTION_UPDATE: {
        const _zone = state.filter((zone) => zone["id"] === action.id)[0];
        _zone["name"] = action.name;
        _zone["price"] = action.price;
        _zone["min_free_amount"] = action.min_free_amount;
        _zone["min_order_price"] = action.min_order_price;
        return state;
      }
      default:
        throw new Error();
    }
  };

  const sectionsReducer = (state, action) => {
    switch (action.type) {
      case ACTION_SET: {
        return action.state;
      }
      case ACTION_ADD:
        return [...state, { ...action.section, id: uuidv4() }];
      case ACTION_REMOVE:
        return state.filter((section) => section["id"] !== action.id);
      case ACTION_UPDATE: {
        const _section = state.filter(
          (section) => section["id"] === action.id
        )[0];
        _section["name"] = action.name;
        _section["zip_code"] = action.zipcode;
        return state;
      }
      default:
        throw new Error();
    }
  };

  return { sectionsReducer, zonesReducer };
};

export const getFullZones = (zones, sections) => {
  const getSections = (id) => {
    return sections.filter((section) => section["zone"] === id);
  };

  const _fullZones = [];
  zones.forEach((zone) => {
    _fullZones.push({ ...zone, sections: getSections(zone["id"]) });
  });

  return _fullZones;
};

export const initializeData = (
  setMaxPreorderDays,
  setPreorder,
  setPickup,
  setDelivery,
  setZones,
  setSections,
  setOldSections,
  setOldZones,
  setPaypal,
  setPaypalApiKey,
  setPaypalSecret,
  setAllowAnon,
  setDineIn,
  setCash,
  setAllowDineInCash,
  setAllowDinePaypal,
  setTableReservations,
  setCovid_policy,
  setMaxDaysTable,
  setMaxNumTable,
  setAllowUsersCreation,
  setMaintenanceMode,
  setMaintenanceMsg,
  setAllowReviews,
  setAllowJobs,
  setLoading,
  token
) => {
  getRequest(domain + "settings/restaurant/1/", setLoading, token).then(
    ([data, status]) => {
      if (status === 200) {
        setPreorder(data["enable_preorder"]);
        setMaxPreorderDays(data["max_preorder_days"]);
        setPickup(data["pickup"]);
        setDelivery(data["delivery"]);
        setPaypal(data["paypal"]);
        setPaypalApiKey(data["paypal_clientid"]);
        setPaypalSecret(data["paypal_secret"]);
        setAllowAnon(data["allow_anon"]);
        setDineIn(data["dine_in"]);
        setCash(data["cash"]);
        setAllowDineInCash(data["allow_dinein_cash"]);
        setAllowDinePaypal(data["allow_dinein_paypal"]);
        setTableReservations(data["table_reservation"]);
        setCovid_policy(data["covid_policy"]);
        setMaxDaysTable(data["max_days_table"]);
        setMaxNumTable(data["max_num_table"]);
        setAllowUsersCreation(data["allow_users"]);
        setMaintenanceMode(data["maintenance_mode"]);
        setMaintenanceMsg(data["maintenance_msg"]);
        setAllowReviews(data["allow_user_reviews"]);
        setAllowJobs(data["allow_jobs"]);
      }
    }
  );
  getRequest(domain + ENDPOINTS["ZONE"], setLoading).then(([data, status]) => {
    if (status === 200) {
      setOldZones(_.cloneDeep(data));
      setZones({ type: ACTION_SET, state: data });
    }
  });
  getRequest(domain + ENDPOINTS["SECTION"], setLoading).then(
    ([data, status]) => {
      if (status === 200) {
        setOldSections(_.cloneDeep(data));
        setSections({ type: ACTION_SET, state: data });
      }
    }
  );
};
