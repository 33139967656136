import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  ADD,
  CANCEL,
  DESCRIPTION,
  IMAGE,
  SAVE,
  SHOW_BANNER_OFFER_CHECKOUT,
  SHOW_BANNER_OFFER_HOME,
  SHOW_BANNER_OFFER_MENU,
  TITLE,
} from "../../../../language";
import Button from "../../../../partials/Button";
import ImageInput from "../../../../partials/ImageInput";
import Textarea from "../../../../partials/Textarea";
import {
  addBannerOffer,
  updateBannerOffer,
} from "../../store/slices/BannerOffersSlice";

export default function NewBannerOffer({
  setEditing = () => {},
  update = false,
  offer = {},
}) {
  const [adding, setAdding] = useState(update);
  const [description, setDescription] = useState(offer.description || "");
  const [title, setTitle] = useState(offer.title || "");
  const [image, setImage] = useState(offer.image || "");
  const [showHome, setShowHome] = useState(offer.show_home_screen || false);
  const [showMenu, setShowMenu] = useState(offer.show_menu || false);
  const [showCheckout, setShowCheckout] = useState(
    offer.show_checkout || false
  );
  const dispatch = useDispatch();

  const handleCancle = () => {
    setEditing(false);
    setAdding(false);
  };

  const handleAdd = (e) => {
    e.preventDefault();

    const bannerOfferBody = {
      description,
      title,
      image,
      show_menu: showMenu,
      show_home_screen: showHome,
      show_checkout: showCheckout,
    };

    !update
      ? dispatch(
          addBannerOffer({
            bannerOffer: bannerOfferBody,
          })
        )
      : dispatch(
          updateBannerOffer({
            id: offer.id,
            ...bannerOfferBody,
          })
        );

    setAdding(false);
    setEditing(false);
  };

  return (
    <>
      {adding && (
        <form onSubmit={handleAdd} className="__new form">
          <Textarea
            name={TITLE}
            initialValue={title}
            setContextValue={(value) => {
              setTitle(value);
            }}
            required={true}
          ></Textarea>
          <Textarea
            name={DESCRIPTION}
            initialValue={description}
            setContextValue={(value) => {
              setDescription(value);
            }}
            required={true}
          ></Textarea>
          <ImageInput
            title={IMAGE}
            setContextValue={(value) => {
              setImage(value);
            }}
            initial={[image]}
            multiple={false}
            required={true}
          />

          <div className="__checkboxes">
            <div>
              <label htmlFor="allowanon">{SHOW_BANNER_OFFER_HOME + " "}</label>
              <input
                id="showhome"
                checked={showHome}
                onChange={() => setShowHome(!showHome)}
                type="checkbox"
              ></input>
            </div>
            <div>
              <label htmlFor="allowanon">{SHOW_BANNER_OFFER_MENU + " "}</label>
              <input
                id="showmenu"
                checked={showMenu}
                onChange={() => setShowMenu(!showMenu)}
                type="checkbox"
              ></input>
            </div>
            <div>
              <label htmlFor="allowanon">
                {SHOW_BANNER_OFFER_CHECKOUT + " "}
              </label>
              <input
                id="showcheckout"
                checked={showCheckout}
                onChange={() => setShowCheckout(!showCheckout)}
                type="checkbox"
              ></input>
            </div>
          </div>

          <div className="__btns">
            <Button type="submit">{SAVE}</Button>
            <Button onClick={handleCancle}>{CANCEL}</Button>
          </div>
        </form>
      )}
      {!update && (
        <Button onClick={() => setAdding(true)} disabled={adding}>
          {ADD}
        </Button>
      )}
    </>
  );
}
