import React, { useState } from "react";
import {
  ARE_YOU_SURE,
  DOWNLOAD_QR_CODE,
  HIDE_QR_CODE,
  PASSCODE,
  REMOVE,
  SHOW_QR_CODE,
  TABLE_NAME,
  TABLE_NUMBER,
} from "../../../language";
import Button from "../../../partials/Button";
import QRCode from "qrcode.react";
import { useTables } from "../Context";
import { Typography } from "antd";
export default function DineTable({ table }) {
  const [showQr, setShowQr] = useState(false);

  const { removeTable, updatePasscode } = useTables();

  const onRemove = () => {
    if (window.confirm(ARE_YOU_SURE)) {
      removeTable(table.id);
    }
  };

  const downloadQRCode = () => {
    const qrCodeURL = document
      .getElementById(`${table.name}-${table.number}-${table.id}`)
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = `${table.name}-${table.number}.png`;
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  return (
    <li className="__table">
      <div className="__info">
        <p className="__key">{TABLE_NAME}</p>
        <p className="__value">{table.name}</p>

        <p className="__key">{TABLE_NUMBER}</p>
        <p className="__value">{table.number}</p>
        <p className="__key">{PASSCODE}</p>
        <Typography.Text
          editable={{
            tooltip: false,
            onChange: (value) => {
              updatePasscode(table.id, value);
            },
          }}
          className="__value"
        >
          {table.passcode}
        </Typography.Text>
      </div>
      <div className="__btns">
        <Button onClick={onRemove}>{REMOVE}</Button>

        <Button onClick={() => setShowQr((prev) => !prev)}>
          {showQr ? HIDE_QR_CODE : SHOW_QR_CODE}
        </Button>
      </div>

      {showQr && (
        <div className="__qr">
          <QRCode
            id={`${table.name}-${table.number}-${table.id}`}
            value={table.passcode}
            size={300}
          />
          {showQr && (
            <Button onClick={downloadQRCode}>{DOWNLOAD_QR_CODE}</Button>
          )}
        </div>
      )}
    </li>
  );
}
