import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ADD, CANCEL, NAME, PRICE, SAVE, SIZE } from "../../../../../language";
import Button from "../../../../../partials/Button";
import InputGroup from "../../../../../partials/InputGroup";
import TextInput from "../../../../../partials/TextInput";
import { addSize, updateSize } from "../../../store/slices/sizesSlice";

const NewSize = React.memo(function ({
  mealId,
  update = false,
  size = {},
  setEditing = () => {},
}) {
  const dispatch = useDispatch();

  const [name, setName] = useState(size.name || "");
  const [price, setPrice] = useState(size.price || 0);
  const [adding, setAdding] = useState(update);

  const handleAdd = () => {
    !update
      ? dispatch(
          addSize({
            size: { name, price, meal: mealId },
          })
        )
      : dispatch(
          updateSize({
            name,
            price,
            id: size.id,
          })
        );
    setAdding(false);
    setEditing(false);
  };

  const handleCancle = () => {
    setAdding(false);
    setEditing(false);
  };

  return (
    <>
      {adding && (
        <form onSubmit={handleAdd} className="__meal --new">
          <fieldset>
            <InputGroup>
              <TextInput
                name={NAME}
                placeholder={NAME}
                setContextValue={(value) => setName(value)}
                size={5}
                initialValue={name}
              />
              <TextInput
                size={5}
                name={PRICE}
                placeholder={PRICE}
                setContextValue={(value) => setPrice(value)}
                type="number"
                initialValue={price}
              />
            </InputGroup>

            <div className="__btns">
              <Button type="Submit">{SAVE}</Button>
              <Button onClick={handleCancle}>{CANCEL}</Button>
            </div>
          </fieldset>
        </form>
      )}
      {!update && (
        <Button disabled={adding} onClick={() => setAdding(true)}>
          {ADD + " " + SIZE}
        </Button>
      )}
    </>
  );
});
export default NewSize;
